import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { StorageOutCertificate } from 'server/certificates/interfaces';
import { BaseInstructor, StorageOutStudent, StorageOutWaitlist } from 'server/users/interfaces';
import { StorageOutLesson, StorageOutLessonStudentDetails, StorageOutLessonReview, StorageOutLessonDrivingSkillsReport, StorageOutLessonDrivingSkillRating, StorageOutSharedLesson } from 'server/lessons/interfaces';
import { StorageOutAvailability } from 'server/availabilities/interfaces';
import { StorageOutTransientSlot } from 'server/transients/interfaces';
import { StorageOutInstructorTimeLog, StorageOutInstructorZone, StorageOutInstructorsCoverage } from 'server/instructors/interfaces';
import { StorageOutInstructorSchedule } from 'server/instructorSchedules/interfaces';
import { StorageOutProduct, StorageOutProductLink } from 'server/products/interfaces';
import { StorageOutPriceScheme, StorageOutPriceSchemeProductLink, StorageOutPriceSchemeFragment, StorageOutProductPrice } from 'server/priceSchemes/interfaces';
import { StorageOutRegionScheme, StorageOutRegion } from 'server/regionSchemes/interfaces';
import { StorageOutSuperRegion } from 'server/superRegions/interfaces';
import { StorageOutStateConfig } from 'server/states/interfaces';
import { StorageOutCart } from 'server/cart/interfaces';
import { StorageOutCoupon, StorageOutCourseCoupon, StorageOutLessonCoupon } from 'server/coupons/interfaces';
import { StorageOutLedgerLog, StorageOutLedgerLogCharge, StorageOutLedgerLogVoidPurchase, StorageOutLedgerLogVoidRefund, StorageOutLedgerLogRefund, StorageOutLedgerLogRemoveStudentFromLesson, StorageOutLedgerLogScheduleLesson, StorageOutLedgerLogExpireProducts, StorageOutLedgerLogVoidExpiration } from 'server/ledgerLogs/interfaces';
import { StorageOutSyncCheck, StorageOutSyncConflict, StorageOutSyncConflictsPaginatedList } from 'server/syncChecks/interfaces';
import { StorageOutRedeemVoucherResult, StorageOutVoucher } from 'server/vouchers/interfaces';
import { StorageOutCourseContent, StorageOutLecture } from 'server/onlineCourses/interfaces';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: number;
  JSONObject: any;
};

export type GQLAddMakeUpSessionInformationInput = {
  cohortSessionId: Scalars['ID'];
  makeUpCohortId: Scalars['ID'];
  makeUpSessionId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLAddedPurchaseItem = GQLAddedPurchasePackage | GQLAddedPurchaseProduct;

export type GQLAddedPurchasePackage = {
  __typename?: 'AddedPurchasePackage';
  itemId: Scalars['ID'];
  items?: Maybe<Array<GQLAddedPurchaseItem>>;
  name: Scalars['String'];
  priceDollars: Scalars['Float'];
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
  type: GQLProductType;
};

export type GQLAddedPurchaseProduct = {
  __typename?: 'AddedPurchaseProduct';
  basePriceDollars: Scalars['Float'];
  itemId: Scalars['ID'];
  name: Scalars['String'];
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
  type: GQLProductType;
};

export type GQLAddress = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  secondaryPhone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type GQLAddressInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  secondaryPhone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type GQLAnvilPdfIds = {
  __typename?: 'AnvilPdfIds';
  admin?: Maybe<Scalars['ID']>;
  insurance?: Maybe<Scalars['ID']>;
  student?: Maybe<Scalars['ID']>;
};

export enum GQLAttendance {
  Absent = 'Absent',
  MadeUp = 'Made_Up',
  NotSet = 'Not_Set',
  Present = 'Present'
}

export type GQLAuditLog = {
  __typename?: 'AuditLog';
  action: GQLAuditLogActions;
  changes: Array<GQLLogChange>;
  collection: GQLAuditLogCollection;
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  updatedBy: GQLUpdatedBy;
};

export enum GQLAuditLogActions {
  Create = 'Create',
  Delete = 'Delete',
  Unknown = 'Unknown',
  Update = 'Update'
}

export type GQLAuditLogCollection = {
  __typename?: 'AuditLogCollection';
  name: Scalars['String'];
  path: Scalars['String'];
};

export type GQLAuditLogEdge = {
  __typename?: 'AuditLogEdge';
  cursor: Scalars['ID'];
  node: GQLAuditLog;
};

export enum GQLAuditLogUserRoles {
  Admin = 'Admin',
  Instructor = 'Instructor',
  System = 'System',
  User = 'User'
}

export type GQLAuditLogsPaginatedList = {
  __typename?: 'AuditLogsPaginatedList';
  edges: Array<GQLAuditLogEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLAuditLogsQuery = {
  action?: InputMaybe<GQLAuditLogActions>;
  collection?: InputMaybe<GQLCollections>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  path?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<GQLAuditLogUserRoles>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type GQLAvailabilitiesQuery = {
  endTime: Scalars['DateTime'];
  instructorIds: Array<Scalars['ID']>;
  startTime: Scalars['DateTime'];
};

export type GQLAvailability = {
  __typename?: 'Availability';
  center: GQLCenter;
  date: Scalars['String'];
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  instructor: GQLInstructor;
  instructorId: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
  maxLessons: Scalars['Int'];
  minLeadHours: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  vehicle: GQLVehicle;
};

export type GQLAvailabilityAddFailure = {
  __typename?: 'AvailabilityAddFailure';
  date: Scalars['DateTime'];
  error: Scalars['String'];
  weekDay: Scalars['String'];
};

export type GQLAvailabilityCreateInput = {
  center: GQLCenterInput;
  date: Scalars['String'];
  endTime: Scalars['DateTime'];
  instructor: GQLAvailabilityInstructorInput;
  isPrivate: Scalars['Boolean'];
  maxLessons: Scalars['Int'];
  minLeadHours: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  vehicle: GQLAvailabilityVehicleInput;
};

export type GQLAvailabilityInstructorInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  timeZone: Scalars['String'];
};

export type GQLAvailabilityQuery = {
  id: Scalars['ID'];
  instructorId: Scalars['ID'];
};

export type GQLAvailabilitySlot = {
  __typename?: 'AvailabilitySlot';
  endTime: Scalars['DateTime'];
  instructorId: Scalars['ID'];
  instructorName: Scalars['String'];
  instructorTimezone: Scalars['String'];
  isPrivate: Scalars['Boolean'];
  location: GQLLocation;
  startTime: Scalars['DateTime'];
};

export type GQLAvailabilitySlotPerDay = {
  __typename?: 'AvailabilitySlotPerDay';
  date: Scalars['String'];
  timeSlots: Array<GQLAvailabilitySlot>;
};

export type GQLAvailabilityUpdateFailure = {
  __typename?: 'AvailabilityUpdateFailure';
  availabilityId: Scalars['ID'];
  error: Scalars['String'];
};

export type GQLAvailabilityUpdateInput = {
  center?: InputMaybe<GQLCenterInput>;
  date: Scalars['String'];
  endTime?: InputMaybe<Scalars['DateTime']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  maxLessons?: InputMaybe<Scalars['Int']>;
  minLeadHours?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  vehicle?: InputMaybe<GQLAvailabilityVehicleInput>;
};

export type GQLAvailabilityVehicleInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLBalancedQuantity = {
  availableQuantity: Scalars['Int'];
  expiredQuantity: Scalars['Int'];
  purchasedQuantity: Scalars['Int'];
  receivedQuantity: Scalars['Int'];
  refundedQuantity: Scalars['Int'];
  sentQuantity: Scalars['Int'];
  usedQuantity: Scalars['Int'];
  voidedQuantity: Scalars['Int'];
};

export type GQLBaseLedgerLog = {
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLBaseLedgerLogSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLBatchAddAvailsResult = {
  __typename?: 'BatchAddAvailsResult';
  added: Array<GQLAvailability>;
  failed: Array<GQLAvailabilityAddFailure>;
};

export type GQLBatchDeleteAvailsQuery = {
  availabilityIds: Array<Scalars['ID']>;
  instructorId: Scalars['ID'];
};

export type GQLBatchDeleteAvailsResult = {
  __typename?: 'BatchDeleteAvailsResult';
  deleted: Array<Scalars['ID']>;
  failed: Array<GQLAvailabilityUpdateFailure>;
};

export type GQLBatchEditAvailsQuery = {
  ids: Array<Scalars['ID']>;
  instructorId: Scalars['ID'];
};

export type GQLBatchEditAvailsResult = {
  __typename?: 'BatchEditAvailsResult';
  failed: Array<GQLAvailabilityUpdateFailure>;
  updated: Array<GQLAvailability>;
};

export type GQLBatchEditAvailsUpdate = {
  endTimeOfDay?: InputMaybe<Scalars['String']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  maxLessons?: InputMaybe<Scalars['Int']>;
  minLeadHours?: InputMaybe<Scalars['Int']>;
  startTimeOfDay?: InputMaybe<Scalars['String']>;
  vehicleId?: InputMaybe<Scalars['ID']>;
  vehicleLocation?: InputMaybe<Scalars['String']>;
};

export type GQLBookingAlert = {
  __typename?: 'BookingAlert';
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy: GQLPerson;
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  studentId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type GQLBookingAlertEdge = {
  __typename?: 'BookingAlertEdge';
  cursor: Scalars['ID'];
  node: GQLBookingAlert;
};

export type GQLBookingAlertInput = {
  id?: InputMaybe<Scalars['ID']>;
  studentId: Scalars['ID'];
  text: Scalars['String'];
};

export type GQLBookingAlertsPaginatedList = {
  __typename?: 'BookingAlertsPaginatedList';
  edges: Array<GQLBookingAlertEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLBookingAlertsQuery = {
  studentId: Scalars['ID'];
};

export type GQLCart = {
  __typename?: 'Cart';
  bookedSlotIds?: Maybe<Array<Scalars['ID']>>;
  coupon?: Maybe<GQLCoupon>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isOrderComplete: Scalars['Boolean'];
  items: Array<GQLPackageWithPrices>;
  paymentStatus?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  usState?: Maybe<GQLUsStateId>;
};

export type GQLCartCreateInput = {
  item?: InputMaybe<GQLPackageWithPricesInput>;
};

export type GQLCartInput = {
  id: Scalars['ID'];
};

export type GQLCenter = {
  __typename?: 'Center';
  location: Scalars['String'];
};

export type GQLCenterInput = {
  location: Scalars['String'];
};

export type GQLCertificate = {
  __typename?: 'Certificate';
  certNumber: Scalars['Int'];
  certType: GQLCertificateType;
  certTypeId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: GQLPerson;
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  issuedAt: Scalars['DateTime'];
  issuedTo?: Maybe<GQLPerson>;
  issuedToId?: Maybe<Scalars['ID']>;
  usState?: Maybe<GQLUsState>;
  usStateId?: Maybe<Scalars['ID']>;
};

export type GQLCertificateEdge = {
  __typename?: 'CertificateEdge';
  cursor: Scalars['ID'];
  node: GQLCertificate;
};

export type GQLCertificateInput = {
  certNumber: Scalars['Int'];
  certTypeId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  issuedAt: Scalars['DateTime'];
  issuedToId?: InputMaybe<Scalars['ID']>;
  usStateId: Scalars['ID'];
};

export type GQLCertificateType = {
  __typename?: 'CertificateType';
  id: Scalars['ID'];
  label: Scalars['String'];
  prefix: Scalars['String'];
  usStateId: Scalars['ID'];
  valMax?: Maybe<Scalars['Int']>;
  valMin?: Maybe<Scalars['Int']>;
};

export type GQLCertificatesPaginatedList = {
  __typename?: 'CertificatesPaginatedList';
  edges: Array<GQLCertificateEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLCertificatesQuery = {
  studentId?: InputMaybe<Scalars['ID']>;
};

export type GQLChargeLedgerLog = {
  __typename?: 'ChargeLedgerLog';
  categoryId: GQLPaymentCategories;
  couponCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items: Array<GQLPurchaseItem>;
  purchaseId: Scalars['ID'];
  summary: Scalars['String'];
  voucherCode?: Maybe<Scalars['String']>;
};


export type GQLChargeLedgerLogSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLChargeLedgerLogQueryInput = {
  id: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLClaimInput = {
  key: Scalars['String'];
  value: Scalars['Boolean'];
};

export type GQLCohort = {
  __typename?: 'Cohort';
  courseId: Scalars['ID'];
  description: Scalars['String'];
  endDate: Scalars['String'];
  enrolledUserIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  id: Scalars['ID'];
  instructor: Scalars['String'];
  isEnrollmentOpen: Scalars['Boolean'];
  location: Scalars['String'];
  maxEnrollees: Scalars['Int'];
  name: Scalars['String'];
  numEnrolled: Scalars['Int'];
  sessions?: Maybe<Array<GQLCohortSession>>;
  startDate: Scalars['String'];
  state: GQLUsStateId;
  stateId: Scalars['String'];
  students?: Maybe<Array<GQLStudentObject>>;
  timeZone: Scalars['String'];
};

export type GQLCohortInput = {
  courseId: Scalars['ID'];
  description: Scalars['String'];
  endDate: Scalars['String'];
  enrolledUserIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  id?: InputMaybe<Scalars['ID']>;
  instructor: Scalars['String'];
  isEnrollmentOpen: Scalars['Boolean'];
  location: Scalars['String'];
  maxEnrollees: Scalars['Int'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  state: GQLUsStateId;
  stateId: Scalars['String'];
  timeZone: Scalars['String'];
};

export type GQLCohortSession = {
  __typename?: 'CohortSession';
  attendance?: Maybe<Array<GQLStudentSessionAttendance>>;
  cohortId: Scalars['ID'];
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type GQLCohortSessionInput = {
  cohortId: Scalars['ID'];
  endTime: Scalars['DateTime'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export enum GQLCollections {
  InstructorAvailsV2 = 'InstructorAvailsV2',
  InstructorReviewsV2 = 'InstructorReviewsV2',
  Instructors = 'Instructors',
  LessonSlotsV2 = 'LessonSlotsV2',
  Users = 'Users',
  Vehicles = 'Vehicles',
  Vouchers = 'Vouchers',
  Waitlist = 'Waitlist'
}

export type GQLCompleteOrderInput = {
  bookedSlotIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
};

export type GQLCoupon = GQLCourseCoupon | GQLLessonCoupon;

export type GQLCouponEdge = {
  __typename?: 'CouponEdge';
  cursor: Scalars['ID'];
  node: GQLCoupon;
};

export enum GQLCouponType {
  Course = 'course',
  Lesson = 'lesson'
}

export type GQLCouponsPaginatedList = {
  __typename?: 'CouponsPaginatedList';
  edges: Array<GQLCouponEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLCourseCoupon = {
  __typename?: 'CourseCoupon';
  code: Scalars['String'];
  courseId?: Maybe<Scalars['String']>;
  courseSlug?: Maybe<Scalars['String']>;
  discount: Scalars['Float'];
  expiration?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isDailyCoupon: Scalars['Boolean'];
  state: GQLUsStateId;
  title: Scalars['String'];
  type: GQLCouponType;
};

export type GQLCourseCouponInput = {
  code: Scalars['String'];
  courseId: Scalars['String'];
  discount: Scalars['Float'];
  expiration?: InputMaybe<Scalars['DateTime']>;
  isDailyCoupon: Scalars['Boolean'];
  state: GQLUsStateId;
  title: Scalars['String'];
};

export type GQLCourseLecture = {
  __typename?: 'CourseLecture';
  content: GQLCourseLectureContent;
  id: Scalars['ID'];
  includesAQuiz: Scalars['Boolean'];
  slug: Scalars['String'];
  /** Time the student spent on the lecture in milliseconds. */
  timeSpentByStudent: Scalars['Float'];
  title: Scalars['String'];
};


export type GQLCourseLectureContentArgs = {
  courseId: Scalars['ID'];
  seed?: InputMaybe<Scalars['String']>;
};


export type GQLCourseLectureTimeSpentByStudentArgs = {
  courseId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLCourseLectureContent = {
  __typename?: 'CourseLectureContent';
  minimumDuration: Scalars['Int'];
  parts: Array<GQLLecturePart>;
};

export type GQLCoursePrepTest = {
  __typename?: 'CoursePrepTest';
  chapters: Array<GQLCoursePrepTestChapter>;
  title: Scalars['String'];
};

export type GQLCoursePrepTestChapter = {
  __typename?: 'CoursePrepTestChapter';
  id: Scalars['ID'];
  questions: Array<GQLLectureQuizQuestion>;
  title: Scalars['String'];
};

export type GQLCoursePrepTestChapterScores = {
  __typename?: 'CoursePrepTestChapterScores';
  id: Scalars['ID'];
  questionScores: Array<GQLLectureQuizQuestionPrepTestScore>;
};

export type GQLCourseTest = {
  __typename?: 'CourseTest';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLCourseTestLecture = {
  __typename?: 'CourseTestLecture';
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type GQLCourseTopic = {
  __typename?: 'CourseTopic';
  id: Scalars['ID'];
  lectures: Array<GQLCourseLecture>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type GQLCoursesQuery = {
  isClassroomCourse?: InputMaybe<Scalars['Boolean']>;
  isEnrollmentOpen?: InputMaybe<Scalars['Boolean']>;
};

export type GQLCreateProductInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  products?: InputMaybe<Array<GQLProductLinkInput>>;
  type: GQLCreateProductTypeInput;
};

export enum GQLCreateProductTypeInput {
  Course = 'course',
  Fee = 'fee',
  Package = 'package'
}

export type GQLCreatePurchaseInput = {
  amountDollars: Scalars['Float'];
  categoryId: GQLPaymentCategories;
  contractId?: InputMaybe<Scalars['ID']>;
  couponCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  items: Array<GQLPurchaseItemInput>;
  note?: InputMaybe<Scalars['String']>;
  paymentCaptureDate?: InputMaybe<Scalars['DateTime']>;
  purchaseSessionId?: InputMaybe<Scalars['ID']>;
  stripeChargeId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripePaymentMethodId?: InputMaybe<Scalars['String']>;
  studentAddress?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ID'];
  usStateId: GQLUsStateId;
  voucherCode?: InputMaybe<Scalars['String']>;
};

export type GQLCreateSessionCookieInput = {
  idToken: Scalars['String'];
};

export type GQLCreateVoucherInput = {
  amountDollars: Scalars['Float'];
  amountHours: Scalars['Float'];
  name?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type GQLDeleteBookingAlertInput = {
  id: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLDrivingSkill = {
  __typename?: 'DrivingSkill';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export enum GQLDrivingSkillRating {
  Capable = 'Capable',
  Consistent = 'Consistent',
  NeedsAdditionalDevelopment = 'NeedsAdditionalDevelopment',
  NeedsImprovement = 'NeedsImprovement',
  Proficient = 'Proficient',
  Todo = 'Todo'
}

export type GQLEditPurchaseInput = {
  amountDollars?: InputMaybe<Scalars['Float']>;
  categoryId?: InputMaybe<GQLPaymentCategories>;
  description?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<GQLPurchaseItemInput>>;
  paymentCaptureDate?: InputMaybe<Scalars['DateTime']>;
  purchaseId: Scalars['ID'];
  stripeChargeId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  studentAddress?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ID'];
  usStateId?: InputMaybe<GQLUsStateId>;
  versionId: Scalars['ID'];
};

export type GQLEligibleStudent = {
  __typename?: 'EligibleStudent';
  displayName: Scalars['String'];
  distanceMeters: Scalars['Float'];
  dob: Scalars['Float'];
  drivingMinutesRemaining: Scalars['Int'];
  licenseExpirationDate?: Maybe<Scalars['Float']>;
  licenseIssueDate?: Maybe<Scalars['Float']>;
  licenseNumber?: Maybe<Scalars['String']>;
  observationMinutesRemaining: Scalars['Int'];
  userId: Scalars['ID'];
};

export type GQLEmailStudentReportInput = {
  comments?: InputMaybe<Scalars['String']>;
  emails: Array<Scalars['String']>;
  lessonId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLEnrollStudentToCohortInput = {
  cohortId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLExistingPurchaseItemUpdate = {
  __typename?: 'ExistingPurchaseItemUpdate';
  itemId: Scalars['ID'];
  priceUpdate: Scalars['Float'];
  quantityUpdate: Scalars['Float'];
};

export type GQLExpenditure = {
  __typename?: 'Expenditure';
  itemId: Scalars['ID'];
  productId: Scalars['ID'];
  purchaseId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type GQLExpiration = {
  __typename?: 'Expiration';
  createdById: Scalars['ID'];
  description: Scalars['String'];
  expirationType: GQLExpireProductsTypes;
  id: Scalars['ID'];
  isVoid: Scalars['Boolean'];
  itemsToExpire: Array<GQLItemToExpire>;
  purchaseIds: Array<Scalars['ID']>;
  studentId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  versionId: Scalars['ID'];
};

export type GQLExpirationQuery = {
  expirationId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLExpireProductsInput = {
  description: Scalars['String'];
  itemsToExpire: Array<GQLItemToExpireInput>;
  purchaseId: Scalars['ID'];
  studentId: Scalars['ID'];
  type: GQLExpireProductsTypes;
};

export enum GQLExpireProductsTypes {
  Expired = 'EXPIRED',
  NoShow = 'NO_SHOW',
  Other = 'OTHER'
}

export type GQLFirebaseCourseSessionTime = {
  __typename?: 'FirebaseCourseSessionTime';
  sessionEnd?: Maybe<Scalars['DateTime']>;
  sessionStart?: Maybe<Scalars['DateTime']>;
};

export type GQLFirebaseLessonReview = {
  __typename?: 'FirebaseLessonReview';
  date: Scalars['DateTime'];
  rating: Scalars['Int'];
  source: Scalars['String'];
  studentId: Scalars['ID'];
  studentName: Scalars['String'];
  text: Scalars['String'];
};

export type GQLGeoJsonFeature = {
  __typename?: 'GeoJsonFeature';
  geometry: GQLGeoJsonGeometry;
  id: Scalars['ID'];
  properties: GQLGeoJsonProperties;
  type: Scalars['String'];
};

export type GQLGeoJsonFeatureInput = {
  geometry: GQLGeoJsonGeometryInput;
  id: Scalars['ID'];
  properties: GQLGeoJsonPropertiesInput;
  type: Scalars['String'];
};

export type GQLGeoJsonGeometry = {
  __typename?: 'GeoJsonGeometry';
  coordinates: Array<Array<Array<Scalars['Float']>>>;
  type: Scalars['String'];
};

export type GQLGeoJsonGeometryInput = {
  coordinates: Array<Array<Array<Scalars['Float']>>>;
  type: Scalars['String'];
};

export type GQLGeoJsonProperties = {
  __typename?: 'GeoJsonProperties';
  label?: Maybe<Scalars['String']>;
};

export type GQLGeoJsonPropertiesInput = {
  label: Scalars['String'];
};

export type GQLGetLessonReviewInput = {
  instructorId: Scalars['ID'];
  lessonId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLIdLessonPackageInput = {
  id: Scalars['ID'];
  item: GQLPackageWithPricesInput;
};

export type GQLIdToken = {
  __typename?: 'IdToken';
  expiresIn: Scalars['Int'];
  idToken: Scalars['String'];
  refreshToken: Scalars['String'];
  userId: Scalars['ID'];
};

export type GQLImpersonateUserResponse = {
  __typename?: 'ImpersonateUserResponse';
  token: Scalars['String'];
};

export enum GQLInitiatedBy {
  Coastline = 'Coastline',
  ImmediateReschedule = 'Immediate_Reschedule',
  Instructor = 'Instructor',
  Student = 'Student'
}

export type GQLInstructor = {
  __typename?: 'Instructor';
  about?: Maybe<Scalars['String']>;
  adminInfo: GQLInstructorAdminInfo;
  id: Scalars['ID'];
  languages?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  portraitImage?: Maybe<Scalars['String']>;
  privateInfo: GQLInstructorPrivateInfo;
  region?: Maybe<Scalars['String']>;
  signInEmail?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  superRegion?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  usState?: Maybe<GQLUsState>;
  usStateId?: Maybe<Scalars['ID']>;
};

export type GQLInstructorAdminInfo = {
  __typename?: 'InstructorAdminInfo';
  badges?: Maybe<Array<GQLInstructorBadge>>;
  payrollFirstName?: Maybe<Scalars['String']>;
  payrollLastName?: Maybe<Scalars['String']>;
  payrollTitle?: Maybe<Scalars['String']>;
  regionalManager?: Maybe<GQLPerson>;
  regionalManagerId?: Maybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type GQLInstructorAlert = {
  __typename?: 'InstructorAlert';
  id: Scalars['ID'];
  message: Scalars['String'];
  readMoreLink?: Maybe<Scalars['String']>;
  severity: GQLInstructorAlertSeverity;
  timestamp: Scalars['DateTime'];
};

export type GQLInstructorAlertCreateInput = {
  message: Scalars['String'];
  readMoreLink?: InputMaybe<Scalars['String']>;
  severity: GQLInstructorAlertSeverity;
};

export type GQLInstructorAlertDeleteInput = {
  id: Scalars['ID'];
};

export type GQLInstructorAlertEdge = {
  __typename?: 'InstructorAlertEdge';
  cursor: Scalars['ID'];
  node: GQLInstructorAlert;
};

export type GQLInstructorAlertPaginatedList = {
  __typename?: 'InstructorAlertPaginatedList';
  edges: Array<GQLInstructorAlertEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export enum GQLInstructorAlertSeverity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

export type GQLInstructorAlertUpdateInput = {
  id: Scalars['ID'];
  message: Scalars['String'];
  readMoreLink?: InputMaybe<Scalars['String']>;
  severity: GQLInstructorAlertSeverity;
};

export type GQLInstructorBadge = {
  __typename?: 'InstructorBadge';
  icon: GQLInstructorBadgeIcon;
  title: Scalars['String'];
};

export enum GQLInstructorBadgeIcon {
  Lessons100 = 'lessons100',
  Lessons500 = 'lessons500',
  Lessons1000 = 'lessons1000',
  Lessons2000 = 'lessons2000',
  Manager = 'manager',
  Operator = 'operator',
  OtherLanguage = 'otherLanguage',
  TrainingSpecialist = 'trainingSpecialist'
}

export type GQLInstructorBadgeInput = {
  icon: GQLInstructorBadgeIcon;
  title: Scalars['String'];
};

export type GQLInstructorBadgeQuery = {
  instructorId: Scalars['ID'];
};

export type GQLInstructorEdge = {
  __typename?: 'InstructorEdge';
  cursor: Scalars['ID'];
  node: GQLInstructor;
};

export type GQLInstructorLessonUpdateInput = {
  actualDurationMinutes: Scalars['Int'];
  actualDurationNote: Scalars['String'];
};

export type GQLInstructorOnAvail = {
  __typename?: 'InstructorOnAvail';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  timeZone: Scalars['String'];
};

export type GQLInstructorPrivateInfo = {
  __typename?: 'InstructorPrivateInfo';
  baseAddress?: Maybe<Scalars['String']>;
  breakTimes: Array<Scalars['Int']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  licenseExpirationDate?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  maxBaseTravelMinutes?: Maybe<Scalars['Int']>;
  maxBetweenTravelMinutes?: Maybe<Scalars['Int']>;
  nonTeachingPay?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  slackUsername?: Maybe<Scalars['String']>;
  status: GQLInstructorStatus;
  teachingPay?: Maybe<Scalars['Int']>;
};

export type GQLInstructorSchedule = {
  __typename?: 'InstructorSchedule';
  createdAt: Scalars['DateTime'];
  dayTimesAvailable: Array<GQLScheduleDayTimes>;
  daysPerWeek: Scalars['Int'];
  endDate: Scalars['String'];
  id: Scalars['ID'];
  instructorId: Scalars['ID'];
  instructorName?: Maybe<Scalars['String']>;
  isApproved: Scalars['Boolean'];
  managerName?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  vacationRequests?: Maybe<Scalars['String']>;
};

export type GQLInstructorScheduleDayTimes = {
  dayOfTheWeek: Scalars['String'];
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type GQLInstructorScheduleInput = {
  dayTimesAvailable: Array<GQLInstructorScheduleDayTimes>;
  daysPerWeek: Scalars['Int'];
  endDate: Scalars['String'];
  instructorId: Scalars['ID'];
  startDate: Scalars['String'];
  vacationRequests?: InputMaybe<Scalars['String']>;
};

export type GQLInstructorScheduleMutateQuery = {
  instructorId: Scalars['ID'];
  scheduleId: Scalars['ID'];
};

export type GQLInstructorScheduleQuery = {
  instructorId: Scalars['ID'];
  scheduleId?: InputMaybe<Scalars['ID']>;
};

export type GQLInstructorScheduleUpdateInput = {
  dayTimesAvailable?: InputMaybe<Array<GQLInstructorScheduleDayTimes>>;
  daysPerWeek?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['String']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
  vacationRequests?: InputMaybe<Scalars['String']>;
};

export type GQLInstructorSchedulesQuery = {
  instructorId?: InputMaybe<Scalars['ID']>;
  isApproved?: InputMaybe<Scalars['Boolean']>;
  scheduleId?: InputMaybe<Scalars['ID']>;
};

export enum GQLInstructorStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Retired = 'Retired'
}

export type GQLInstructorTimeLog = {
  __typename?: 'InstructorTimeLog';
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  instructor: GQLInstructor;
  instructorId: Scalars['ID'];
  logType: GQLInstructorTimeLogType;
  rateType: GQLInstructorTimeLogRateType;
  startTime: Scalars['DateTime'];
};

export type GQLInstructorTimeLogCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  instructorId: Scalars['ID'];
  logType?: InputMaybe<GQLInstructorTimeLogType>;
  rateType: GQLInstructorTimeLogRateType;
  startTime: Scalars['DateTime'];
};

export type GQLInstructorTimeLogEdge = {
  __typename?: 'InstructorTimeLogEdge';
  cursor: Scalars['ID'];
  node: GQLInstructorTimeLog;
};

export type GQLInstructorTimeLogListQuery = {
  instructorId: Scalars['ID'];
};

export type GQLInstructorTimeLogPaginatedList = {
  __typename?: 'InstructorTimeLogPaginatedList';
  edges: Array<GQLInstructorTimeLogEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLInstructorTimeLogQuery = {
  id: Scalars['ID'];
  instructorId: Scalars['ID'];
};

export enum GQLInstructorTimeLogRateType {
  NonTeaching = 'NonTeaching',
  Teaching = 'Teaching'
}

export enum GQLInstructorTimeLogType {
  Drive = 'Drive',
  Other = 'Other'
}

export type GQLInstructorTimeLogUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  rateType?: InputMaybe<GQLInstructorTimeLogRateType>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type GQLInstructorWithSuperRegion = {
  __typename?: 'InstructorWithSuperRegion';
  about?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isInactive?: Maybe<Scalars['Boolean']>;
  isRetired?: Maybe<Scalars['Boolean']>;
  languages?: Maybe<Scalars['String']>;
  portraitImage?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  slackUsername?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  superRegion?: Maybe<Scalars['String']>;
  timeZone: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  usStateId?: Maybe<Scalars['ID']>;
  userId: Scalars['String'];
  wallpaperImage?: Maybe<Scalars['String']>;
};

export type GQLInstructorZone = {
  __typename?: 'InstructorZone';
  center: GQLLatLng;
  features: Array<GQLGeoJsonFeature>;
  instructorId: Scalars['ID'];
  instructorName: Scalars['String'];
  isStatic: Scalars['Boolean'];
};

export type GQLInstructorZoneQueryInput = {
  address: Scalars['String'];
  instructorId: Scalars['ID'];
  maxTravelMinutes: Scalars['Int'];
};

export type GQLInstructorsCoverage = {
  __typename?: 'InstructorsCoverage';
  center: GQLLatLng;
  instructorZones: Array<GQLInstructorZone>;
};

export type GQLInstructorsPaginatedList = {
  __typename?: 'InstructorsPaginatedList';
  edges: Array<GQLInstructorEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLInstructorsQuery = {
  id?: InputMaybe<Scalars['ID']>;
  region?: InputMaybe<Scalars['String']>;
  regionalManagerId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<GQLInstructorStatus>;
  term?: InputMaybe<Scalars['String']>;
};

export type GQLItemInput = {
  customAmount?: InputMaybe<Scalars['Float']>;
  itemId: Scalars['ID'];
  products?: InputMaybe<Array<GQLSubItemInput>>;
  quantity: Scalars['Int'];
};

export type GQLItemToExpire = {
  __typename?: 'ItemToExpire';
  expiredQuantity: Scalars['Int'];
  itemId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type GQLItemToExpireInput = {
  expiredQuantity: Scalars['Int'];
  itemId: Scalars['ID'];
};

export type GQLItemToRefund = {
  __typename?: 'ItemToRefund';
  itemId: Scalars['ID'];
  productId: Scalars['ID'];
  refundedQuantity: Scalars['Int'];
};

export type GQLItemToRefundInput = {
  itemId: Scalars['ID'];
  refundedQuantity: Scalars['Int'];
};

export type GQLItemToTransfer = {
  __typename?: 'ItemToTransfer';
  itemId: Scalars['ID'];
  productId: Scalars['ID'];
  transferredQuantity: Scalars['Int'];
};

export type GQLItemToTransferAndExpire = {
  __typename?: 'ItemToTransferAndExpire';
  itemId: Scalars['ID'];
  productId: Scalars['ID'];
  purchaseId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type GQLItemToTransferInput = {
  itemId: Scalars['ID'];
  transferredQuantity: Scalars['Int'];
};

export type GQLLastBookedInstructor = {
  __typename?: 'LastBookedInstructor';
  displayName: Scalars['String'];
  id: Scalars['ID'];
};

export type GQLLatLng = {
  __typename?: 'LatLng';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type GQLLectureBlockColumn = GQLLectureSingleImageBlock | GQLLectureTitleBlock;

export type GQLLectureBlockTextColumn = {
  __typename?: 'LectureBlockTextColumn';
  structuredText: Scalars['JSONObject'];
};

export type GQLLectureColumnImage = {
  __typename?: 'LectureColumnImage';
  image: GQLLectureContentImage;
};

export type GQLLectureContentBlock = GQLLectureSingleImageBlock | GQLLectureSingleVideoBlock | GQLLectureTitleBlock | GQLLectureTwoColumnBlock;

export enum GQLLectureContentBlockType {
  SingleImage = 'singleImage',
  SingleVideo = 'singleVideo',
  Title = 'title',
  TwoColumn = 'twoColumn'
}

export type GQLLectureContentImage = {
  __typename?: 'LectureContentImage';
  alt?: Maybe<Scalars['String']>;
  aspectRatio?: Maybe<Scalars['Float']>;
  base64?: Maybe<Scalars['String']>;
  bgColor?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  sizes?: Maybe<Scalars['String']>;
  src: Scalars['String'];
  srcSet?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  webpSrcSet?: Maybe<Scalars['String']>;
  width: Scalars['Int'];
};

export type GQLLectureContentPart = {
  __typename?: 'LectureContentPart';
  blocks: Array<GQLLectureContentBlock>;
  id: Scalars['ID'];
  structuredText?: Maybe<Scalars['JSONObject']>;
  type: GQLLecturePartType;
  video?: Maybe<GQLLectureContentVideo>;
};

export type GQLLectureContentVideo = {
  __typename?: 'LectureContentVideo';
  thumbnailUrl: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type GQLLecturePart = GQLLectureContentPart | GQLLectureQuizPart;

export enum GQLLecturePartType {
  Content = 'content',
  Quiz = 'quiz'
}

export type GQLLectureQuestionChoice = {
  __typename?: 'LectureQuestionChoice';
  id: Scalars['ID'];
  option: Scalars['String'];
};

export type GQLLectureQuizPart = {
  __typename?: 'LectureQuizPart';
  id: Scalars['ID'];
  questions: Array<GQLLectureQuizQuestion>;
  type: GQLLecturePartType;
};

export type GQLLectureQuizQuestion = {
  __typename?: 'LectureQuizQuestion';
  answer: Scalars['String'];
  choices?: Maybe<Array<GQLLectureQuestionChoice>>;
  doShuffle: Scalars['Boolean'];
  id: Scalars['ID'];
  image?: Maybe<GQLLectureContentImage>;
  question: Scalars['String'];
};

export type GQLLectureQuizQuestionPrepTestScore = {
  __typename?: 'LectureQuizQuestionPrepTestScore';
  id: Scalars['ID'];
  isCorrect: Scalars['Boolean'];
};

export type GQLLectureQuizQuestionPrepTestScoreInput = {
  id: Scalars['ID'];
  isCorrect: Scalars['Boolean'];
};

export type GQLLectureReport = {
  __typename?: 'LectureReport';
  id: Scalars['ID'];
  testScore?: Maybe<Scalars['Float']>;
  totalTimeSeconds?: Maybe<Scalars['Int']>;
};

export type GQLLectureSessionTime = {
  __typename?: 'LectureSessionTime';
  lectureId?: Maybe<Scalars['ID']>;
  session?: Maybe<GQLFirebaseCourseSessionTime>;
};

export type GQLLectureSingleImageBlock = {
  __typename?: 'LectureSingleImageBlock';
  id: Scalars['ID'];
  image: GQLLectureContentImage;
  type: GQLLectureContentBlockType;
};

export type GQLLectureSingleVideoBlock = {
  __typename?: 'LectureSingleVideoBlock';
  id: Scalars['ID'];
  type: GQLLectureContentBlockType;
  video?: Maybe<GQLLectureContentVideo>;
};

export type GQLLectureTimeDaily = {
  __typename?: 'LectureTimeDaily';
  date: Scalars['String'];
  totalTimeSeconds: Scalars['Int'];
};

export type GQLLectureTitleBlock = {
  __typename?: 'LectureTitleBlock';
  id: Scalars['ID'];
  title: Scalars['String'];
  type: GQLLectureContentBlockType;
};

export type GQLLectureTwoColumnBlock = {
  __typename?: 'LectureTwoColumnBlock';
  column1: Array<GQLLectureBlockColumn>;
  column2: GQLLectureBlockTextColumn;
  id: Scalars['ID'];
  type: GQLLectureContentBlockType;
};

export type GQLLedgerLog = GQLLedgerLogCancelLesson | GQLLedgerLogCharge | GQLLedgerLogEditPurchase | GQLLedgerLogExpireProducts | GQLLedgerLogRefund | GQLLedgerLogRemoveStudentFromLesson | GQLLedgerLogScheduleLesson | GQLLedgerLogScheduleMultipleLessons | GQLLedgerLogTransferExpiration | GQLLedgerLogTransferLesson | GQLLedgerLogTransferPurchase | GQLLedgerLogUpdateLesson | GQLLedgerLogVoidExpiration | GQLLedgerLogVoidPurchase | GQLLedgerLogVoidRefund;

export type GQLLedgerLogCancelLesson = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogCancelLesson';
  createdById: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lessonId: Scalars['ID'];
  purchaseIds: Array<Scalars['ID']>;
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogCancelLessonSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogCharge = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogCharge';
  amountDollars: Scalars['Float'];
  categoryId: GQLPaymentCategories;
  couponCode?: Maybe<Scalars['String']>;
  couponDiscount?: Maybe<Scalars['Float']>;
  createdById: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  items: Array<GQLPurchaseItem>;
  paymentCaptureDate?: Maybe<Scalars['DateTime']>;
  purchaseId: Scalars['ID'];
  studentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
  usStateId: GQLUsStateId;
  voucherCode?: Maybe<Scalars['String']>;
};


export type GQLLedgerLogChargeSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogEditPurchase = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogEditPurchase';
  categoryId?: Maybe<GQLPaymentCategories>;
  couponCode?: Maybe<Scalars['String']>;
  couponDiscount?: Maybe<Scalars['Float']>;
  createdById: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  itemsUpdates?: Maybe<Array<GQLPurchaseItemUpdate>>;
  paymentCaptureDate?: Maybe<Scalars['DateTime']>;
  purchaseId: Scalars['ID'];
  stripeChargeId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  studentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
  usStateId?: Maybe<GQLUsStateId>;
  voucherCode?: Maybe<Scalars['String']>;
};


export type GQLLedgerLogEditPurchaseSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogExpireProducts = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogExpireProducts';
  createdById: Scalars['ID'];
  description: Scalars['String'];
  expirationType: GQLExpireProductsTypes;
  id: Scalars['ID'];
  isVoid: Scalars['Boolean'];
  itemsToExpire: Array<GQLItemToExpire>;
  purchaseId: Scalars['ID'];
  studentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogExpireProductsSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogRefund = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogRefund';
  amountToRefund: Scalars['Float'];
  categoryId: GQLPaymentCategories;
  createdById: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  itemsToRefund?: Maybe<Array<GQLItemToRefund>>;
  purchaseId: Scalars['ID'];
  studentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogRefundSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogRemoveStudentFromLesson = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogRemoveStudentFromLesson';
  createdById: Scalars['ID'];
  expenditures: Array<GQLExpenditure>;
  id: Scalars['ID'];
  initiatedBy: Scalars['ID'];
  lessonId: Scalars['ID'];
  reason: Scalars['String'];
  studentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogRemoveStudentFromLessonSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogScheduleLesson = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogScheduleLesson';
  createdById: Scalars['ID'];
  expenditures: Array<GQLExpenditure>;
  id: Scalars['ID'];
  lessonId: Scalars['ID'];
  studentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogScheduleLessonSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogScheduleMultipleLessons = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogScheduleMultipleLessons';
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  studentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogScheduleMultipleLessonsSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogTransferExpiration = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogTransferExpiration';
  createdById: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  destinationStudentId: Scalars['ID'];
  expirationId: Scalars['ID'];
  id: Scalars['ID'];
  isVoid: Scalars['Boolean'];
  itemsToTransferAndExpire: Array<GQLItemToTransferAndExpire>;
  purchaseIds: Array<Scalars['ID']>;
  sourceStudentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogTransferExpirationSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogTransferLesson = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogTransferLesson';
  createdById: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  destinationStudentId: Scalars['ID'];
  id: Scalars['ID'];
  lessonId: Scalars['ID'];
  purchaseIds: Array<Scalars['ID']>;
  sourceStudentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogTransferLessonSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogTransferPurchase = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogTransferPurchase';
  amountToTransfer: Scalars['Float'];
  createdById: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  destinationStudentId: Scalars['ID'];
  id: Scalars['ID'];
  itemsToTransfer: Array<GQLItemToTransfer>;
  purchaseId: Scalars['ID'];
  sourceStudentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogTransferPurchaseSummaryArgs = {
  studentId: Scalars['ID'];
};

export enum GQLLedgerLogTypes {
  CancelLesson = 'cancelLesson',
  Charge = 'charge',
  EditPurchase = 'editPurchase',
  ExpireProducts = 'expireProducts',
  Refund = 'refund',
  RemoveStudentFromLesson = 'removeStudentFromLesson',
  ScheduleLesson = 'scheduleLesson',
  ScheduleMultipleLessons = 'scheduleMultipleLessons',
  TransferExpiration = 'transferExpiration',
  TransferLesson = 'transferLesson',
  TransferPurchase = 'transferPurchase',
  UpdateLesson = 'updateLesson',
  VoidExpiration = 'voidExpiration',
  VoidPurchase = 'voidPurchase',
  VoidRefund = 'voidRefund'
}

export type GQLLedgerLogUpdateLesson = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogUpdateLesson';
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  lessonId: Scalars['ID'];
  studentIds: Array<Scalars['ID']>;
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogUpdateLessonSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogVoidExpiration = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogVoidExpiration';
  createdById: Scalars['ID'];
  expirationId: Scalars['ID'];
  id: Scalars['ID'];
  purchaseId: Scalars['ID'];
  studentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogVoidExpirationSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogVoidPurchase = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogVoidPurchase';
  createdById: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  purchaseId: Scalars['ID'];
  studentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogVoidPurchaseSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogVoidRefund = GQLBaseLedgerLog & {
  __typename?: 'LedgerLogVoidRefund';
  createdById: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  purchaseId: Scalars['ID'];
  refundId: Scalars['ID'];
  studentId: Scalars['ID'];
  summary: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: GQLLedgerLogTypes;
};


export type GQLLedgerLogVoidRefundSummaryArgs = {
  studentId: Scalars['ID'];
};

export type GQLLedgerLogsEdge = {
  __typename?: 'LedgerLogsEdge';
  cursor: Scalars['ID'];
  node: GQLLedgerLog;
};

export type GQLLedgerLogsPaginatedList = {
  __typename?: 'LedgerLogsPaginatedList';
  edges: Array<GQLLedgerLogsEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLLedgerLogsQuery = {
  studentId: Scalars['ID'];
  types?: InputMaybe<Array<GQLLedgerLogTypes>>;
};

export type GQLLegacyLogSummary = {
  __typename?: 'LegacyLogSummary';
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  type: Scalars['String'];
};

export type GQLLesson = {
  __typename?: 'Lesson';
  actualDurationMinutes?: Maybe<Scalars['Int']>;
  actualDurationNote?: Maybe<Scalars['String']>;
  bookedAt: Scalars['DateTime'];
  comments?: Maybe<Scalars['String']>;
  durationMinutes: Scalars['Int'];
  endTime: Scalars['DateTime'];
  hasHomePickup: Scalars['Boolean'];
  id: Scalars['ID'];
  instructor: GQLInstructor;
  instructorId: Scalars['ID'];
  isCancelled: Scalars['Boolean'];
  isObserverAllowed: Scalars['Boolean'];
  isShareable: Scalars['Boolean'];
  locationId?: Maybe<Scalars['ID']>;
  meetingPointId?: Maybe<Scalars['ID']>;
  pickupLocation: Scalars['String'];
  pickupLocationCoords?: Maybe<GQLLatLng>;
  price: Scalars['Float'];
  startTime: Scalars['DateTime'];
  studentDetails: GQLLessonStudentDetails;
  studentDetailsList: Array<GQLLessonStudentDetails>;
  studentIds: Array<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
};


export type GQLLessonStudentDetailsArgs = {
  studentId: Scalars['ID'];
};

export type GQLLessonCancelQuery = {
  id: Scalars['ID'];
  instructorId: Scalars['ID'];
};

export type GQLLessonCancellationInfo = {
  description: Scalars['String'];
  initiatedBy: GQLInitiatedBy;
};

export type GQLLessonCoupon = {
  __typename?: 'LessonCoupon';
  code: Scalars['String'];
  discount: Scalars['Float'];
  displayInLandingPages?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  regionId: Scalars['ID'];
  regions: Array<GQLRegion>;
  title: Scalars['String'];
  type: GQLCouponType;
};

export type GQLLessonCouponInput = {
  code: Scalars['String'];
  discount: Scalars['Float'];
  displayInLandingPages?: InputMaybe<Scalars['Boolean']>;
  expiration?: InputMaybe<Scalars['DateTime']>;
  regionIds: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type GQLLessonDrivingRatingChange = {
  drivingSkillId: Scalars['ID'];
  rating: GQLDrivingSkillRating;
};

export type GQLLessonDrivingSkillRating = {
  __typename?: 'LessonDrivingSkillRating';
  drivingSkill: GQLDrivingSkill;
  drivingSkillId: Scalars['ID'];
  id: Scalars['ID'];
  lesson: GQLLesson;
  lessonId: Scalars['ID'];
  rating: GQLDrivingSkillRating;
  student: GQLStudent;
  studentId: Scalars['ID'];
};

export type GQLLessonDrivingSkillRatingInput = {
  lessonDrivingRatingChange: Array<GQLLessonDrivingRatingChange>;
  lessonId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLLessonDrivingSkillsReport = {
  __typename?: 'LessonDrivingSkillsReport';
  drivingSkillsRatings: Array<GQLLessonDrivingSkillRating>;
  id: Scalars['ID'];
  lastEmailSent?: Maybe<Scalars['DateTime']>;
  lesson: GQLLesson;
};

export type GQLLessonDrivingSkillsReportEdge = {
  __typename?: 'LessonDrivingSkillsReportEdge';
  cursor: Scalars['ID'];
  node: GQLLessonDrivingSkillsReport;
};

export type GQLLessonDrivingSkillsReportPaginatedList = {
  __typename?: 'LessonDrivingSkillsReportPaginatedList';
  edges: Array<GQLLessonDrivingSkillsReportEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLLessonEdge = {
  __typename?: 'LessonEdge';
  cursor: Scalars['ID'];
  node: GQLLesson;
};

export type GQLLessonPackage = {
  __typename?: 'LessonPackage';
  drivingMinutes: Scalars['Int'];
  id: Scalars['String'];
  isSharedLessons?: Maybe<Scalars['Boolean']>;
  numLessons: Scalars['Int'];
  numRoadTests: Scalars['Int'];
  observationMinutes: Scalars['Int'];
  totalMinutes: Scalars['Int'];
};

export type GQLLessonPackageInput = {
  drivingMinutes: Scalars['Int'];
  id: Scalars['String'];
  isSharedLessons?: InputMaybe<Scalars['Boolean']>;
  numLessons: Scalars['Int'];
  numRoadTests: Scalars['Int'];
  observationMinutes: Scalars['Int'];
  totalMinutes: Scalars['Int'];
};

export type GQLLessonQuery = {
  id: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLLessonRemoveStudentQuery = {
  id: Scalars['ID'];
  instructorId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLLessonReview = {
  __typename?: 'LessonReview';
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  instructor: GQLInstructor;
  instructorId: Scalars['ID'];
  rating: Scalars['Int'];
  source?: Maybe<Scalars['String']>;
  student: GQLStudent;
  studentId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type GQLLessonReviewCreateInput = {
  date: Scalars['DateTime'];
  instructorId: Scalars['ID'];
  lessonId: Scalars['ID'];
  rating: Scalars['Int'];
  source?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ID'];
  studentName?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type GQLLessonReviewEdge = {
  __typename?: 'LessonReviewEdge';
  cursor: Scalars['ID'];
  node: GQLLessonReview;
};

export type GQLLessonStudentDetails = {
  __typename?: 'LessonStudentDetails';
  dmvConfirmationCode?: Maybe<Scalars['String']>;
  dmvLocation?: Maybe<Scalars['String']>;
  hasReview: Scalars['Boolean'];
  hasSentReportToStudent: Scalars['Boolean'];
  isObserverAllowed: Scalars['Boolean'];
  lessonId: Scalars['ID'];
  pickupDetails?: Maybe<Scalars['String']>;
  productQuantity: Scalars['Int'];
  productQuantityList: Array<GQLProductQuantity>;
  student: GQLStudent;
  studentId: Scalars['ID'];
};


export type GQLLessonStudentDetailsProductQuantityArgs = {
  productId: Scalars['ID'];
};

export type GQLLessonStudentDetailsInput = {
  allowObserver?: InputMaybe<Scalars['String']>;
  dmvConfirmationCode?: InputMaybe<Scalars['String']>;
  dmvLocation?: InputMaybe<Scalars['String']>;
  drivingHistory?: InputMaybe<Scalars['String']>;
  pickupDetails?: InputMaybe<Scalars['String']>;
  previousSchoolLessons?: InputMaybe<Scalars['String']>;
};

export type GQLLessonStudentDetailsUpdateInput = {
  dmvLocation?: InputMaybe<Scalars['String']>;
  drivingMinutes: Scalars['Float'];
  id: Scalars['ID'];
  observationMinutes: Scalars['Float'];
  pickupDetails?: InputMaybe<Scalars['String']>;
};

export type GQLLessonType = {
  __typename?: 'LessonType';
  description?: Maybe<Scalars['String']>;
  durationMinutes: Scalars['Float'];
  features: Array<GQLLessonTypeFeature>;
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type GQLLessonTypeCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  durationMinutes: Scalars['Float'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type GQLLessonTypeEdge = {
  __typename?: 'LessonTypeEdge';
  cursor: Scalars['ID'];
  node: GQLLessonType;
};

export type GQLLessonTypeFeature = {
  __typename?: 'LessonTypeFeature';
  content: Array<Scalars['String']>;
  state: GQLUsState;
};

export type GQLLessonTypeFeaturesInput = {
  content: Array<Scalars['String']>;
  usState: Scalars['ID'];
};

export type GQLLessonTypeForStateConfigInput = {
  lessonTypeFeatures: Array<GQLLessonTypeFeaturesInput>;
  lessonTypeId: Scalars['ID'];
};

export type GQLLessonTypeUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  durationMinutes: Scalars['Float'];
  id: Scalars['ID'];
  label: Scalars['String'];
  name: Scalars['String'];
};

export type GQLLessonTypesPaginatedList = {
  __typename?: 'LessonTypesPaginatedList';
  edges: Array<GQLLessonTypeEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLLessonUpdateAddStudent = {
  dmvConfirmationCode?: InputMaybe<Scalars['String']>;
  dmvLocation?: InputMaybe<Scalars['String']>;
  drivingHistory?: InputMaybe<Scalars['String']>;
  isObserverAllowed: Scalars['Boolean'];
  pickupDetails?: InputMaybe<Scalars['String']>;
  previousSchoolLessons?: InputMaybe<Scalars['String']>;
  productQuantityList: Array<GQLProductQuantityInput>;
  studentId: Scalars['ID'];
};

export type GQLLessonUpdateChangeLessonDetails = {
  actualDurationMinutes?: InputMaybe<Scalars['Int']>;
  actualDurationNote?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  isShareable?: InputMaybe<Scalars['Boolean']>;
  pickupLocation?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type GQLLessonUpdateChangeStudentDetails = {
  dmvConfirmationCode?: InputMaybe<Scalars['String']>;
  dmvLocation?: InputMaybe<Scalars['String']>;
  drivingHistory?: InputMaybe<Scalars['String']>;
  isObserverAllowed?: InputMaybe<Scalars['Boolean']>;
  pickupDetails?: InputMaybe<Scalars['String']>;
  previousSchoolLessons?: InputMaybe<Scalars['String']>;
  productQuantityList?: InputMaybe<Array<GQLProductQuantityInput>>;
  studentId: Scalars['ID'];
};

export type GQLLessonUpdateInput = {
  addStudent?: InputMaybe<Array<GQLLessonUpdateAddStudent>>;
  changeLessonDetails?: InputMaybe<GQLLessonUpdateChangeLessonDetails>;
  changeStudentDetails?: InputMaybe<Array<GQLLessonUpdateChangeStudentDetails>>;
};

export type GQLLessonUpdateQuery = {
  id: Scalars['ID'];
  instructorId: Scalars['ID'];
};

export type GQLLessonsDrivingSkillsReportsQuery = {
  studentId: Scalars['ID'];
};

export type GQLLessonsPaginatedList = {
  __typename?: 'LessonsPaginatedList';
  edges: Array<GQLLessonEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLLessonsQuery = {
  instructorId?: InputMaybe<Scalars['ID']>;
  studentId: Scalars['ID'];
};

export type GQLLessonsQueryByInstructorIds = {
  endTime: Scalars['DateTime'];
  instructorIds: Array<Scalars['ID']>;
  startTime: Scalars['DateTime'];
};

export type GQLLessonsReviewsByInstructorQuery = {
  instructorId: Scalars['ID'];
};

export type GQLLessonsReviewsPaginatedList = {
  __typename?: 'LessonsReviewsPaginatedList';
  edges: Array<GQLLessonReviewEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLLocation = {
  __typename?: 'Location';
  address: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  neighborhood: Scalars['String'];
  neighborhoodLat: Scalars['Float'];
  neighborhoodLng: Scalars['Float'];
};

export type GQLLogChange = GQLLogDateTimeChange | GQLLogFloatChange | GQLLogIntChange | GQLLogStringChange;

export type GQLLogChangeBase = {
  path: Scalars['String'];
  valueType: GQLLogChangeValueType;
};

export enum GQLLogChangeValueType {
  DateTime = 'DateTime',
  Float = 'Float',
  Int = 'Int',
  String = 'String'
}

export type GQLLogDateTimeChange = GQLLogChangeBase & {
  __typename?: 'LogDateTimeChange';
  newDateTime?: Maybe<Scalars['DateTime']>;
  oldDateTime?: Maybe<Scalars['DateTime']>;
  path: Scalars['String'];
  valueType: GQLLogChangeValueType;
};

export type GQLLogFloatChange = GQLLogChangeBase & {
  __typename?: 'LogFloatChange';
  newFloat?: Maybe<Scalars['Float']>;
  oldFloat?: Maybe<Scalars['Float']>;
  path: Scalars['String'];
  valueType: GQLLogChangeValueType;
};

export type GQLLogIntChange = GQLLogChangeBase & {
  __typename?: 'LogIntChange';
  newInt?: Maybe<Scalars['Int']>;
  oldInt?: Maybe<Scalars['Int']>;
  path: Scalars['String'];
  valueType: GQLLogChangeValueType;
};

export type GQLLogStringChange = GQLLogChangeBase & {
  __typename?: 'LogStringChange';
  newString?: Maybe<Scalars['String']>;
  oldString?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  valueType: GQLLogChangeValueType;
};

export enum GQLLogTypes {
  All = 'All',
  Auto = 'Auto',
  Cancels = 'Cancels',
  Manual = 'Manual'
}

export type GQLMigrateOnlineCourseInput = {
  destinationStudentId: Scalars['ID'];
  onlineCourseId: Scalars['ID'];
  reason: Scalars['String'];
  sourceStudentId: Scalars['ID'];
};

export type GQLMigrateStudentAccountInput = {
  destinationStudentId: Scalars['ID'];
  reason: Scalars['String'];
  sourceStudentId: Scalars['ID'];
};

export type GQLMultipleAvailabilityCreateInput = {
  center: GQLCenterInput;
  endDate: Scalars['String'];
  endTime: Scalars['String'];
  instructor: GQLAvailabilityInstructorInput;
  minLeadHours: Scalars['Int'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
  vehicle: GQLAvailabilityVehicleInput;
  weekDays?: InputMaybe<Array<GQLWeekDays>>;
};

export type GQLMutation = {
  __typename?: 'Mutation';
  addInstructor: GQLInstructor;
  addMakeUpSessionInformation: Scalars['Boolean'];
  addStudent: GQLStudent;
  addToCart: GQLCart;
  addWaitlistComment: GQLWaitlistComment;
  applyForScholarship: GQLScholarshipApplicationResponse;
  batchDeleteAvails: GQLBatchDeleteAvailsResult;
  batchEditAvails: GQLBatchEditAvailsResult;
  cancelLesson: GQLLesson;
  cancelSyncCheck?: Maybe<GQLSyncCheck>;
  clearCoupon: GQLCart;
  clearPackages: GQLCart;
  completeOrder: GQLCart;
  completeOrderWithSlots: GQLCart;
  createAvailability: GQLAvailability;
  createCart: GQLCart;
  createCoupon: GQLCoupon;
  createInstructorAlert: GQLInstructorAlert;
  createInstructorSchedule?: Maybe<Scalars['ID']>;
  createInstructorTimeLog: GQLInstructorTimeLog;
  createLessonReview: GQLLessonReview;
  createLessonType: GQLLessonType;
  createMultipleAvailabilities: GQLBatchAddAvailsResult;
  createPriceScheme: GQLPriceScheme;
  createPriceSchemeFragment: GQLPriceSchemeFragment;
  createProduct: GQLProduct;
  createPurchase: GQLPurchase;
  createSessionCookie: Scalars['String'];
  createStateConfig: GQLStateConfig;
  createStateMeetingPoint: GQLStateMeetingPoint;
  createSuperRegion: GQLSuperRegion;
  createTransientSlot: GQLTransientSlot;
  createVoucher: GQLVoucher;
  deleteCoupon: Scalars['ID'];
  deleteInstructorAlert: Scalars['ID'];
  deleteInstructorSchedule?: Maybe<Scalars['ID']>;
  deleteInstructorTimeLog: Scalars['ID'];
  deleteLessonType: Scalars['Boolean'];
  deleteNote: Scalars['ID'];
  deletePriceSchemeFragment: Scalars['ID'];
  deleteProduct: Scalars['ID'];
  deleteStateMeetingPoint: Scalars['ID'];
  deleteSuperRegion: Scalars['ID'];
  deleteTestFirebaseUser: Scalars['Boolean'];
  deleteTransientSlot: Scalars['ID'];
  deleteVehicle: Scalars['ID'];
  deleteVoucher: Scalars['ID'];
  disableStudentAccount: Scalars['Boolean'];
  editPurchase: GQLPurchase;
  emailStudentReport: Scalars['ID'];
  enrollStudentToCohort: Scalars['Boolean'];
  enrollStudentToOnlineCourse: GQLStudentOnlineCourse;
  expireProducts: GQLExpiration;
  impersonateUser: GQLImpersonateUserResponse;
  instructorUpdateLesson: GQLLesson;
  migrateStudentAccount: Scalars['Boolean'];
  migrateStudentOnlineCourse: GQLStudentCourseMigration;
  putBookingAlert: GQLBookingAlert;
  putCertificate: GQLCertificate;
  putNote: GQLNote;
  putReview: GQLFirebaseLessonReview;
  putTestChargeLedgerLog: GQLLedgerLog;
  putTestLesson: GQLLesson;
  putTestProduct: GQLProduct;
  putTestRegions: Scalars['Boolean'];
  putTestUser: GQLStudent;
  putVehicle: GQLVehicle;
  putWaitlistStudent: GQLWaitlistStudent;
  redeemVoucher: GQLRedeemVoucherResult;
  refreshTransientSlot: Scalars['ID'];
  refundPurchase: GQLRefund;
  removeBookingAlert: Scalars['ID'];
  removeFromCart: GQLCart;
  removeStudentFromLesson: GQLLesson;
  removeWaitlist: Scalars['Boolean'];
  resetTestDb: Scalars['Boolean'];
  resetTestLedgerLogs: Scalars['Boolean'];
  saveCohort: GQLCohort;
  saveCohortSession: GQLCohortSession;
  scheduleLesson: GQLLesson;
  scheduleMultipleLessons: Array<GQLLesson>;
  selfBookSharedLesson: GQLLesson;
  setCoupon: GQLCart;
  setPaymentStatus: GQLCart;
  setStudentId: GQLCart;
  signOut: Scalars['Boolean'];
  startSyncCheck?: Maybe<GQLSyncCheck>;
  submitCoursePrepTestChapterScores: Scalars['Boolean'];
  transferAccount: Scalars['Boolean'];
  transferExpiration: GQLExpiration;
  transferLesson: GQLLesson;
  transferPurchase: GQLPurchase;
  unenrollStudentFromCohort: Scalars['Boolean'];
  unenrollStudentFromOnlineCourse: Scalars['Boolean'];
  updateAvailability: GQLAvailability;
  updateCoupon: GQLCoupon;
  updateInstructor: GQLInstructor;
  updateInstructorAlert: GQLInstructorAlert;
  updateInstructorSchedule?: Maybe<Scalars['ID']>;
  updateInstructorTimeLog: GQLInstructorTimeLog;
  updateLesson: GQLLesson;
  updateLessonReportForStudent: Scalars['Boolean'];
  updateLessonType: GQLLessonType;
  updatePriceScheme: GQLPriceScheme;
  updatePriceSchemeFragment: GQLPriceSchemeFragment;
  updateProduct: GQLProduct;
  updateRegionScheme: GQLRegionScheme;
  updateReview: GQLFirebaseLessonReview;
  updateStateConfig: GQLStateConfig;
  updateStateMeetingPoint: GQLStateMeetingPoint;
  updateStudent: GQLStudent;
  updateStudentCourseLectureSession: GQLFirebaseCourseSessionTime;
  updateStudentCourseMeta: Scalars['Boolean'];
  updateStudentCourseSession: GQLFirebaseCourseSessionTime;
  updateStudentSessionAttendance: Scalars['Boolean'];
  updateStudentsSessionAttendance: Scalars['Boolean'];
  updateSuperRegion: GQLSuperRegion;
  updateUserRoles: Array<GQLUserRole>;
  updateVoucher: GQLVoucher;
  voidExpiration: GQLExpiration;
  voidPurchase: GQLPurchase;
  voidRefund: GQLRefund;
};


export type GQLMutationAddInstructorArgs = {
  instructor: GQLNewInstructorInput;
};


export type GQLMutationAddMakeUpSessionInformationArgs = {
  input: GQLAddMakeUpSessionInformationInput;
};


export type GQLMutationAddStudentArgs = {
  student: GQLNewStudentInput;
};


export type GQLMutationAddToCartArgs = {
  input: GQLIdLessonPackageInput;
};


export type GQLMutationAddWaitlistCommentArgs = {
  comment: GQLWaitlistCommentInput;
};


export type GQLMutationApplyForScholarshipArgs = {
  courseId: Scalars['String'];
  dob: Scalars['Float'];
  email: Scalars['String'];
  scholarshipAnswer: Scalars['String'];
  userName: Scalars['String'];
};


export type GQLMutationBatchDeleteAvailsArgs = {
  query: GQLBatchDeleteAvailsQuery;
};


export type GQLMutationBatchEditAvailsArgs = {
  query: GQLBatchEditAvailsQuery;
  update: GQLBatchEditAvailsUpdate;
};


export type GQLMutationCancelLessonArgs = {
  input: GQLLessonCancellationInfo;
  query: GQLLessonCancelQuery;
};


export type GQLMutationCancelSyncCheckArgs = {
  id: Scalars['ID'];
};


export type GQLMutationClearCouponArgs = {
  input: GQLCartInput;
};


export type GQLMutationClearPackagesArgs = {
  input: GQLCartInput;
};


export type GQLMutationCompleteOrderArgs = {
  input: GQLCartInput;
};


export type GQLMutationCompleteOrderWithSlotsArgs = {
  input: GQLCompleteOrderInput;
};


export type GQLMutationCreateAvailabilityArgs = {
  input: GQLAvailabilityCreateInput;
};


export type GQLMutationCreateCartArgs = {
  input: GQLCartCreateInput;
};


export type GQLMutationCreateCouponArgs = {
  input: GQLUpsertCouponInput;
};


export type GQLMutationCreateInstructorAlertArgs = {
  input: GQLInstructorAlertCreateInput;
};


export type GQLMutationCreateInstructorScheduleArgs = {
  input: GQLInstructorScheduleInput;
};


export type GQLMutationCreateInstructorTimeLogArgs = {
  input: GQLInstructorTimeLogCreateInput;
};


export type GQLMutationCreateLessonReviewArgs = {
  input: GQLLessonReviewCreateInput;
};


export type GQLMutationCreateLessonTypeArgs = {
  input: GQLLessonTypeCreateInput;
};


export type GQLMutationCreateMultipleAvailabilitiesArgs = {
  input: GQLMultipleAvailabilityCreateInput;
};


export type GQLMutationCreatePriceSchemeArgs = {
  input: GQLPriceSchemeCreateInput;
};


export type GQLMutationCreatePriceSchemeFragmentArgs = {
  input: GQLPriceSchemeFragmentCreateInput;
};


export type GQLMutationCreateProductArgs = {
  product: GQLCreateProductInput;
};


export type GQLMutationCreatePurchaseArgs = {
  input: GQLCreatePurchaseInput;
};


export type GQLMutationCreateSessionCookieArgs = {
  session: GQLCreateSessionCookieInput;
};


export type GQLMutationCreateStateConfigArgs = {
  input: GQLStateConfigCreateInput;
};


export type GQLMutationCreateStateMeetingPointArgs = {
  input: GQLStateMeetingPointCreateInput;
};


export type GQLMutationCreateSuperRegionArgs = {
  input: GQLSuperRegionCreateInput;
};


export type GQLMutationCreateTransientSlotArgs = {
  input: GQLTransientSlotInput;
};


export type GQLMutationCreateVoucherArgs = {
  voucher: GQLCreateVoucherInput;
};


export type GQLMutationDeleteCouponArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteInstructorAlertArgs = {
  input: GQLInstructorAlertDeleteInput;
};


export type GQLMutationDeleteInstructorScheduleArgs = {
  query: GQLInstructorScheduleMutateQuery;
};


export type GQLMutationDeleteInstructorTimeLogArgs = {
  query: GQLInstructorTimeLogQuery;
};


export type GQLMutationDeleteLessonTypeArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteNoteArgs = {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};


export type GQLMutationDeletePriceSchemeFragmentArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteStateMeetingPointArgs = {
  id: Scalars['ID'];
  state: Scalars['ID'];
};


export type GQLMutationDeleteSuperRegionArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteTestFirebaseUserArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteTransientSlotArgs = {
  transientSlotId: Scalars['ID'];
};


export type GQLMutationDeleteVehicleArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDeleteVoucherArgs = {
  id: Scalars['ID'];
};


export type GQLMutationDisableStudentAccountArgs = {
  studentId: Scalars['ID'];
};


export type GQLMutationEditPurchaseArgs = {
  input: GQLEditPurchaseInput;
};


export type GQLMutationEmailStudentReportArgs = {
  input: GQLEmailStudentReportInput;
};


export type GQLMutationEnrollStudentToCohortArgs = {
  input: GQLEnrollStudentToCohortInput;
};


export type GQLMutationEnrollStudentToOnlineCourseArgs = {
  input: GQLStudentOnlineCourseInput;
};


export type GQLMutationExpireProductsArgs = {
  input: GQLExpireProductsInput;
};


export type GQLMutationImpersonateUserArgs = {
  userId: Scalars['ID'];
};


export type GQLMutationInstructorUpdateLessonArgs = {
  query: GQLLessonUpdateQuery;
  update: GQLInstructorLessonUpdateInput;
};


export type GQLMutationMigrateStudentAccountArgs = {
  input: GQLMigrateStudentAccountInput;
};


export type GQLMutationMigrateStudentOnlineCourseArgs = {
  input: GQLMigrateOnlineCourseInput;
};


export type GQLMutationPutBookingAlertArgs = {
  bookingAlert: GQLBookingAlertInput;
};


export type GQLMutationPutCertificateArgs = {
  certificate: GQLCertificateInput;
};


export type GQLMutationPutNoteArgs = {
  note: GQLNoteInput;
};


export type GQLMutationPutReviewArgs = {
  query: GQLPutLessonReviewInput;
};


export type GQLMutationPutTestChargeLedgerLogArgs = {
  input: GQLTestChargeLedgerLogInput;
};


export type GQLMutationPutTestLessonArgs = {
  input: GQLPutTestLessonInput;
};


export type GQLMutationPutTestProductArgs = {
  product: GQLPutTestProductInput;
};


export type GQLMutationPutTestRegionsArgs = {
  input: GQLPutTestRegionsInput;
};


export type GQLMutationPutTestUserArgs = {
  input: GQLTestUserInput;
};


export type GQLMutationPutVehicleArgs = {
  vehicle: GQLVehicleInput;
};


export type GQLMutationPutWaitlistStudentArgs = {
  waitlistStudent: GQLWaitlistStudentInput;
};


export type GQLMutationRedeemVoucherArgs = {
  voucher: GQLVoucherRedeemInput;
};


export type GQLMutationRefreshTransientSlotArgs = {
  transientSlotId: Scalars['ID'];
};


export type GQLMutationRefundPurchaseArgs = {
  input: GQLRefundPurchaseInput;
};


export type GQLMutationRemoveBookingAlertArgs = {
  bookingAlert: GQLDeleteBookingAlertInput;
};


export type GQLMutationRemoveFromCartArgs = {
  input: GQLIdLessonPackageInput;
};


export type GQLMutationRemoveStudentFromLessonArgs = {
  input: GQLLessonCancellationInfo;
  query: GQLLessonRemoveStudentQuery;
};


export type GQLMutationRemoveWaitlistArgs = {
  id: Scalars['ID'];
};


export type GQLMutationSaveCohortArgs = {
  input: GQLCohortInput;
};


export type GQLMutationSaveCohortSessionArgs = {
  input: GQLCohortSessionInput;
};


export type GQLMutationScheduleLessonArgs = {
  input: GQLScheduleLessonInput;
};


export type GQLMutationScheduleMultipleLessonsArgs = {
  input: Array<GQLScheduleLessonInput>;
};


export type GQLMutationSelfBookSharedLessonArgs = {
  input: GQLSelfBookSharedLessonInput;
};


export type GQLMutationSetCouponArgs = {
  input: GQLSetCouponInput;
};


export type GQLMutationSetPaymentStatusArgs = {
  input: GQLPaymentStatusInput;
};


export type GQLMutationSetStudentIdArgs = {
  input: GQLSetStudentInput;
};


export type GQLMutationSubmitCoursePrepTestChapterScoresArgs = {
  input: GQLSubmitCoursePrepTestChapterScoresInput;
};


export type GQLMutationTransferAccountArgs = {
  input: GQLTransferAccountInput;
};


export type GQLMutationTransferExpirationArgs = {
  input: GQLTransferExpirationInput;
};


export type GQLMutationTransferLessonArgs = {
  input: GQLTransferLessonInput;
};


export type GQLMutationTransferPurchaseArgs = {
  input: GQLTransferPurchaseInput;
};


export type GQLMutationUnenrollStudentFromCohortArgs = {
  input: GQLUnenrollStudentFromCohortInput;
};


export type GQLMutationUnenrollStudentFromOnlineCourseArgs = {
  input: GQLStudentOnlineCourseInput;
};


export type GQLMutationUpdateAvailabilityArgs = {
  input: GQLAvailabilityUpdateInput;
  query: GQLAvailabilityQuery;
};


export type GQLMutationUpdateCouponArgs = {
  id: Scalars['ID'];
  input: GQLUpsertCouponInput;
};


export type GQLMutationUpdateInstructorArgs = {
  query: GQLUpdateInstructorQuery;
  update: GQLUpdateInstructorInput;
};


export type GQLMutationUpdateInstructorAlertArgs = {
  input: GQLInstructorAlertUpdateInput;
};


export type GQLMutationUpdateInstructorScheduleArgs = {
  input: GQLInstructorScheduleUpdateInput;
  query: GQLInstructorScheduleMutateQuery;
};


export type GQLMutationUpdateInstructorTimeLogArgs = {
  input: GQLInstructorTimeLogUpdateInput;
  query: GQLInstructorTimeLogQuery;
};


export type GQLMutationUpdateLessonArgs = {
  query: GQLLessonUpdateQuery;
  update: GQLLessonUpdateInput;
};


export type GQLMutationUpdateLessonReportForStudentArgs = {
  input: GQLLessonDrivingSkillRatingInput;
};


export type GQLMutationUpdateLessonTypeArgs = {
  input: GQLLessonTypeUpdateInput;
};


export type GQLMutationUpdatePriceSchemeArgs = {
  input: GQLPriceSchemeUpdateInput;
};


export type GQLMutationUpdatePriceSchemeFragmentArgs = {
  input: GQLPriceSchemeFragmentUpdateInput;
};


export type GQLMutationUpdateProductArgs = {
  product: GQLUpdateProductInput;
};


export type GQLMutationUpdateRegionSchemeArgs = {
  input: GQLRegionSchemeUpdateInput;
};


export type GQLMutationUpdateReviewArgs = {
  query: GQLPutLessonReviewInput;
};


export type GQLMutationUpdateStateConfigArgs = {
  input: GQLStateConfigUpdateInput;
};


export type GQLMutationUpdateStateMeetingPointArgs = {
  input: GQLStateMeetingPointUpdateInput;
};


export type GQLMutationUpdateStudentArgs = {
  student: GQLStudentInput;
};


export type GQLMutationUpdateStudentCourseLectureSessionArgs = {
  input: GQLUpdateCourseSessionInput;
};


export type GQLMutationUpdateStudentCourseMetaArgs = {
  input: GQLUpdateStudentCourseMetaInput;
};


export type GQLMutationUpdateStudentCourseSessionArgs = {
  input: GQLUpdateCourseSessionInput;
};


export type GQLMutationUpdateStudentSessionAttendanceArgs = {
  input: GQLUpdateStudentSessionAttendanceInput;
};


export type GQLMutationUpdateStudentsSessionAttendanceArgs = {
  input: GQLUpdateStudentsSessionAttendanceInput;
};


export type GQLMutationUpdateSuperRegionArgs = {
  input: GQLSuperRegionUpdateInput;
};


export type GQLMutationUpdateUserRolesArgs = {
  userId: Scalars['ID'];
  userRoles: Array<GQLUserRole>;
};


export type GQLMutationUpdateVoucherArgs = {
  voucher: GQLUpdateVoucherInput;
};


export type GQLMutationVoidExpirationArgs = {
  input: GQLVoidExpirationInput;
};


export type GQLMutationVoidPurchaseArgs = {
  input: GQLVoidPurchaseInput;
};


export type GQLMutationVoidRefundArgs = {
  input: GQLVoidRefundInput;
};

export type GQLMyself = {
  __typename?: 'Myself';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  roles: Array<Scalars['String']>;
  signInEmail?: Maybe<Scalars['String']>;
};

export type GQLNewInstructorInput = {
  about?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<Array<GQLInstructorBadgeInput>>;
  baseAddress?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  isInactive?: InputMaybe<Scalars['Boolean']>;
  languages?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  licenseExpirationDate?: InputMaybe<Scalars['DateTime']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  longBreakMinutes?: InputMaybe<Scalars['Int']>;
  maxBaseTravelMinutes?: InputMaybe<Scalars['Int']>;
  maxBetweenTravelMinutes?: InputMaybe<Scalars['Int']>;
  payrollFirstName?: InputMaybe<Scalars['String']>;
  payrollLastName?: InputMaybe<Scalars['String']>;
  payrollTitle?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  portraitImage?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['ID']>;
  regionalManagerId?: InputMaybe<Scalars['ID']>;
  shortBreakMinutes?: InputMaybe<Scalars['Int']>;
  slackUsername?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  timeZone: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  usStateId?: InputMaybe<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type GQLNewStudentInput = {
  acceptTOS: Scalars['Boolean'];
  address?: InputMaybe<GQLAddressInput>;
  dob?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  legalAndPermissions?: InputMaybe<GQLStudentLegalAndPermissionsInput>;
  licenseExpirationDate?: InputMaybe<Scalars['DateTime']>;
  licenseIssueDate?: InputMaybe<Scalars['DateTime']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentEmail?: InputMaybe<Scalars['String']>;
  parentName?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  smsOptIn?: InputMaybe<Scalars['Boolean']>;
};

export type GQLNote = {
  __typename?: 'Note';
  createdAt: Scalars['DateTime'];
  createdBy: GQLPerson;
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  isHiddenFromInstructorView: Scalars['Boolean'];
  isPinned: Scalars['Boolean'];
  text: Scalars['String'];
  userId: Scalars['ID'];
};

export type GQLNoteEdge = {
  __typename?: 'NoteEdge';
  cursor: Scalars['ID'];
  node: GQLNote;
};

export type GQLNoteInput = {
  id?: InputMaybe<Scalars['ID']>;
  isHiddenFromInstructorView?: InputMaybe<Scalars['Boolean']>;
  isPinned?: InputMaybe<Scalars['Boolean']>;
  text: Scalars['String'];
  userId: Scalars['ID'];
};

export type GQLNotesPaginatedList = {
  __typename?: 'NotesPaginatedList';
  edges: Array<GQLNoteEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLNotesQuery = {
  userId: Scalars['ID'];
};

export type GQLOnlineCourseContent = {
  __typename?: 'OnlineCourseContent';
  anvilPdfIds: GQLAnvilPdfIds;
  anvilPdfTemplateId?: Maybe<Scalars['String']>;
  branchLicenseNumber?: Maybe<Scalars['String']>;
  certificateCourseName?: Maybe<Scalars['String']>;
  certificateIncludeMeetsRequirements?: Maybe<Scalars['Boolean']>;
  certificateInstructor?: Maybe<Scalars['String']>;
  challengeRequired?: Maybe<Scalars['Boolean']>;
  completionFlow: Array<Scalars['String']>;
  courseAgeMinimum?: Maybe<Scalars['Float']>;
  courseTypeId?: Maybe<Scalars['ID']>;
  dailyTimeLimit?: Maybe<Scalars['Int']>;
  datoStateId: Scalars['ID'];
  dmvPreRegistrationLink?: Maybe<Scalars['String']>;
  externalCourseId?: Maybe<Scalars['String']>;
  failTwiceReview: Scalars['Boolean'];
  hasCertificate: Scalars['Boolean'];
  hasControlNumber: Scalars['Boolean'];
  hasDrivingLessons: Scalars['Boolean'];
  hasShipping: Scalars['Boolean'];
  hasTimer: Scalars['Boolean'];
  hasWeakSecurityMeasures: Scalars['Boolean'];
  id: Scalars['ID'];
  inactivityCheck: Scalars['Boolean'];
  instructionsText?: Maybe<Scalars['String']>;
  isClassroomCourse: Scalars['Boolean'];
  isEnrollmentOpen: Scalars['Boolean'];
  isExternalCourse: Scalars['Boolean'];
  isGuideRequired: Scalars['Boolean'];
  lectureIds: Array<Scalars['ID']>;
  licenseNumber?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  maxEnrollees?: Maybe<Scalars['Int']>;
  minPercentCorrect: Scalars['Int'];
  notarizedFormLink?: Maybe<Scalars['String']>;
  numLectures: Scalars['Int'];
  numTests: Scalars['Int'];
  requireOrder?: Maybe<Scalars['Boolean']>;
  requiredHours: Scalars['Int'];
  requiresDmvPreRegistration?: Maybe<Scalars['Boolean']>;
  requiresNotarizedForm?: Maybe<Scalars['Boolean']>;
  requiresSampleCertificateCheck?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  stateLongName: Scalars['String'];
  stateShortName: Scalars['String'];
  testLectures: Array<GQLCourseTestLecture>;
  timerLockedLectureId?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  topics: Array<GQLCourseTopic>;
  verifyAddressText?: Maybe<Scalars['String']>;
};

export type GQLPackagePrice = {
  __typename?: 'PackagePrice';
  banner?: Maybe<Scalars['String']>;
  couponDiscountAmount?: Maybe<Scalars['Float']>;
  courseId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  discountAmount?: Maybe<Scalars['Float']>;
  extras?: Maybe<Scalars['String']>;
  hasCourse: Scalars['Boolean'];
  hasLessons: Scalars['Boolean'];
  id: Scalars['String'];
  lessonPackage?: Maybe<GQLLessonPackage>;
  msrp?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  state: Scalars['String'];
  title: Scalars['String'];
};

export type GQLPackagePriceInput = {
  banner?: InputMaybe<Scalars['String']>;
  couponDiscountAmount?: InputMaybe<Scalars['Float']>;
  courseId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  discountAmount?: InputMaybe<Scalars['Float']>;
  extras?: InputMaybe<Scalars['String']>;
  hasCourse: Scalars['Boolean'];
  hasLessons: Scalars['Boolean'];
  id: Scalars['String'];
  lessonPackage?: InputMaybe<GQLLessonPackageInput>;
  msrp?: InputMaybe<Scalars['Float']>;
  price?: InputMaybe<Scalars['Float']>;
  title: Scalars['String'];
};

export type GQLPackageWithPrices = {
  __typename?: 'PackageWithPrices';
  banner?: Maybe<Scalars['String']>;
  couponDiscountAmount?: Maybe<Scalars['Float']>;
  courseId?: Maybe<Scalars['String']>;
  courseType?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  didApplyCoupon?: Maybe<Scalars['Boolean']>;
  discountAmount: Scalars['Float'];
  extras?: Maybe<Scalars['String']>;
  hasCertificateShipping?: Maybe<Scalars['Boolean']>;
  hasCourse: Scalars['Boolean'];
  hasLessons: Scalars['Boolean'];
  id: Scalars['String'];
  lessonPackage?: Maybe<GQLLessonPackage>;
  msrp: Scalars['Float'];
  price: Scalars['Float'];
  state: Scalars['String'];
  title: Scalars['String'];
};

export type GQLPackageWithPricesInput = {
  banner?: InputMaybe<Scalars['String']>;
  bookUrl?: InputMaybe<Scalars['String']>;
  couponDiscountAmount?: InputMaybe<Scalars['Float']>;
  courseId?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  didApplyCoupon?: InputMaybe<Scalars['Boolean']>;
  discountAmount: Scalars['Float'];
  doHighlight?: InputMaybe<Scalars['Boolean']>;
  extras?: InputMaybe<Scalars['String']>;
  hasCertificateShipping?: InputMaybe<Scalars['Boolean']>;
  hasCourse: Scalars['Boolean'];
  hasLessons: Scalars['Boolean'];
  id: Scalars['String'];
  isCheckoutFlowPackage?: InputMaybe<Scalars['Boolean']>;
  isHomePickup?: InputMaybe<Scalars['Boolean']>;
  lessonPackage?: InputMaybe<GQLLessonPackageInput>;
  msrp: Scalars['Float'];
  price: Scalars['Float'];
  state: Scalars['String'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title: Scalars['String'];
};

export type GQLPageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['ID']>;
  hasNextPage: Scalars['Boolean'];
};

export type GQLPageQuery = {
  after?: InputMaybe<Scalars['ID']>;
  first: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Array<GQLSortKeyDirectionPair>>;
};

export enum GQLPaymentCategories {
  Acquisition = 'acquisition',
  CashCheck = 'cashCheck',
  Charter = 'charter',
  Complimentary = 'complimentary',
  Corporate = 'corporate',
  NotApplicable = 'notApplicable',
  Paypal = 'paypal',
  Stripe = 'stripe',
  Transfer = 'transfer',
  Voucher = 'voucher'
}

export type GQLPaymentStatusInput = {
  id: Scalars['ID'];
  paymentStatus: Scalars['String'];
};

export type GQLPerson = {
  __typename?: 'Person';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLPersonEdge = {
  __typename?: 'PersonEdge';
  cursor: Scalars['ID'];
  node: GQLPerson;
};

export type GQLPersonPaginatedList = {
  __typename?: 'PersonPaginatedList';
  edges: Array<GQLPersonEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLPriceScheme = {
  __typename?: 'PriceScheme';
  id: Scalars['ID'];
  name: Scalars['String'];
  priceSchemeFragments: Array<GQLPriceSchemeFragment>;
  products: Array<GQLPriceSchemeProductLink>;
};

export type GQLPriceSchemeCreateInput = {
  name: Scalars['String'];
  priceSchemeFragmentIds: Array<Scalars['ID']>;
};

export type GQLPriceSchemeEdge = {
  __typename?: 'PriceSchemeEdge';
  cursor: Scalars['ID'];
  node: GQLPriceScheme;
};

export type GQLPriceSchemeFragment = {
  __typename?: 'PriceSchemeFragment';
  id: Scalars['ID'];
  name: Scalars['String'];
  priceSchemeIds: Array<Scalars['ID']>;
  priceSchemes: Array<GQLPriceSchemeInfo>;
  productPrices: Array<GQLProductPrice>;
};

export type GQLPriceSchemeFragmentCreateInput = {
  name: Scalars['String'];
  productPrices: Array<GQLProductPriceInput>;
};

export type GQLPriceSchemeFragmentEdge = {
  __typename?: 'PriceSchemeFragmentEdge';
  cursor: Scalars['ID'];
  node: GQLPriceSchemeFragment;
};

export type GQLPriceSchemeFragmentPaginatedList = {
  __typename?: 'PriceSchemeFragmentPaginatedList';
  edges: Array<GQLPriceSchemeFragmentEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLPriceSchemeFragmentUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  productPrices: Array<GQLProductPriceInput>;
};

export type GQLPriceSchemeFragmentsQuery = {
  name?: InputMaybe<Scalars['String']>;
  priceSchemeId?: InputMaybe<Scalars['ID']>;
  productId?: InputMaybe<Scalars['ID']>;
};

export type GQLPriceSchemeInfo = {
  __typename?: 'PriceSchemeInfo';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLPriceSchemePaginatedList = {
  __typename?: 'PriceSchemePaginatedList';
  edges: Array<GQLPriceSchemeEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLPriceSchemeProductLink = {
  __typename?: 'PriceSchemeProductLink';
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  productLinks: Array<GQLProductLink>;
  type: GQLProductType;
};

export type GQLPriceSchemeUpdateInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  priceSchemeFragmentIds: Array<Scalars['ID']>;
};

export type GQLPriceSchemesQuery = {
  term?: InputMaybe<Scalars['String']>;
};

export type GQLProduct = {
  __typename?: 'Product';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  products?: Maybe<Array<GQLProductLink>>;
  type: GQLProductType;
};


export type GQLProductPriceArgs = {
  address: Scalars['String'];
};

export type GQLProductBalance = GQLBalancedQuantity & {
  __typename?: 'ProductBalance';
  availableQuantity: Scalars['Int'];
  expiredQuantity: Scalars['Int'];
  productId: Scalars['ID'];
  purchasedQuantity: Scalars['Int'];
  receivedQuantity: Scalars['Int'];
  refundedQuantity: Scalars['Int'];
  sentQuantity: Scalars['Int'];
  usedQuantity: Scalars['Int'];
  voidedQuantity: Scalars['Int'];
};

export type GQLProductEdge = {
  __typename?: 'ProductEdge';
  cursor: Scalars['ID'];
  node: GQLProduct;
};

export type GQLProductLink = {
  __typename?: 'ProductLink';
  product: GQLProduct;
  productId: Scalars['ID'];
  productName: Scalars['String'];
  quantity: Scalars['Int'];
};

export type GQLProductLinkInput = {
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type GQLProductPrice = {
  __typename?: 'ProductPrice';
  id: Scalars['ID'];
  priceAlgo: GQLProductPriceAlgo;
  priceSchemeFragmentId: Scalars['ID'];
  product: GQLProduct;
  productId: Scalars['ID'];
};

export type GQLProductPriceAlgo = GQLProductPriceAlgoDollarDiscount | GQLProductPriceAlgoDollarThenPercentDiscount | GQLProductPriceAlgoFixed | GQLProductPriceAlgoPercentDiscount | GQLProductPriceAlgoPercentThenDollarDiscount;

export type GQLProductPriceAlgoBase = {
  type: GQLProductPriceAlgoType;
};

export type GQLProductPriceAlgoDollarDiscount = GQLProductPriceAlgoBase & {
  __typename?: 'ProductPriceAlgoDollarDiscount';
  dollarDiscount: Scalars['Float'];
  rounding: GQLProductPriceRounding;
  type: GQLProductPriceAlgoType;
};

export type GQLProductPriceAlgoDollarDiscountInput = {
  dollarDiscount: Scalars['Float'];
  rounding: GQLProductPriceRoundingInput;
  type: GQLProductPriceAlgoType;
};

export type GQLProductPriceAlgoDollarThenPercentDiscount = GQLProductPriceAlgoBase & {
  __typename?: 'ProductPriceAlgoDollarThenPercentDiscount';
  dollarDiscount: Scalars['Float'];
  percentDiscount: Scalars['Float'];
  rounding: GQLProductPriceRounding;
  type: GQLProductPriceAlgoType;
};

export type GQLProductPriceAlgoDollarThenPercentDiscountInput = {
  dollarDiscount: Scalars['Float'];
  percentDiscount: Scalars['Float'];
  rounding: GQLProductPriceRoundingInput;
  type: GQLProductPriceAlgoType;
};

export type GQLProductPriceAlgoFixed = GQLProductPriceAlgoBase & {
  __typename?: 'ProductPriceAlgoFixed';
  fixedPrice: Scalars['Float'];
  type: GQLProductPriceAlgoType;
};

export type GQLProductPriceAlgoFixedPriceInput = {
  fixedPrice: Scalars['Float'];
  type: GQLProductPriceAlgoType;
};

/** Only one of the fields should be passed */
export type GQLProductPriceAlgoInput = {
  dollarDiscount?: InputMaybe<GQLProductPriceAlgoDollarDiscountInput>;
  dollarThenPercentDiscount?: InputMaybe<GQLProductPriceAlgoDollarThenPercentDiscountInput>;
  fixed?: InputMaybe<GQLProductPriceAlgoFixedPriceInput>;
  percentDiscount?: InputMaybe<GQLProductPriceAlgoPercentDiscountInput>;
  percentThenDollarDiscount?: InputMaybe<GQLProductPriceAlgoPercentThenDollarDiscountInput>;
};

export type GQLProductPriceAlgoPercentDiscount = GQLProductPriceAlgoBase & {
  __typename?: 'ProductPriceAlgoPercentDiscount';
  percentDiscount: Scalars['Float'];
  rounding: GQLProductPriceRounding;
  type: GQLProductPriceAlgoType;
};

export type GQLProductPriceAlgoPercentDiscountInput = {
  percentDiscount: Scalars['Float'];
  rounding: GQLProductPriceRoundingInput;
  type: GQLProductPriceAlgoType;
};

export type GQLProductPriceAlgoPercentThenDollarDiscount = GQLProductPriceAlgoBase & {
  __typename?: 'ProductPriceAlgoPercentThenDollarDiscount';
  dollarDiscount: Scalars['Float'];
  percentDiscount: Scalars['Float'];
  rounding: GQLProductPriceRounding;
  type: GQLProductPriceAlgoType;
};

export type GQLProductPriceAlgoPercentThenDollarDiscountInput = {
  dollarDiscount: Scalars['Float'];
  percentDiscount: Scalars['Float'];
  rounding: GQLProductPriceRoundingInput;
  type: GQLProductPriceAlgoType;
};

export enum GQLProductPriceAlgoType {
  DollarDiscount = 'dollarDiscount',
  DollarThenPercentDiscount = 'dollarThenPercentDiscount',
  Fixed = 'fixed',
  PercentDiscount = 'percentDiscount',
  PercentThenDollarDiscount = 'percentThenDollarDiscount'
}

export type GQLProductPriceInput = {
  priceAlgo: GQLProductPriceAlgoInput;
  productId: Scalars['ID'];
};

export type GQLProductPriceRounding = {
  __typename?: 'ProductPriceRounding';
  type: GQLProductPriceRoundingType;
  value: Scalars['Float'];
};

export type GQLProductPriceRoundingInput = {
  type: GQLProductPriceRoundingType;
  value: Scalars['Float'];
};

export enum GQLProductPriceRoundingType {
  Digit = 'digit',
  Down = 'down',
  Nearest = 'nearest',
  Up = 'up'
}

export type GQLProductQuantity = {
  __typename?: 'ProductQuantity';
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type GQLProductQuantityInput = {
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export enum GQLProductType {
  Course = 'course',
  DrivingMinute = 'drivingMinute',
  Fee = 'fee',
  HomePickup = 'homePickup',
  ObservationMinute = 'observationMinute',
  Package = 'package'
}

export type GQLProductsPaginatedList = {
  __typename?: 'ProductsPaginatedList';
  edges: Array<GQLProductEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLProductsQuery = {
  omitPackages?: InputMaybe<Scalars['Boolean']>;
  onlyPackages?: InputMaybe<Scalars['Boolean']>;
  term?: InputMaybe<Scalars['String']>;
};

export type GQLPurchase = {
  __typename?: 'Purchase';
  categoryId: GQLPaymentCategories;
  couponCode?: Maybe<Scalars['String']>;
  couponDiscount?: Maybe<Scalars['Float']>;
  createdById: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  items: Array<GQLAddedPurchaseItem>;
  paymentCaptureDate?: Maybe<Scalars['DateTime']>;
  stripeChargeId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  usStateId: GQLUsStateId;
  versionId: Scalars['ID'];
  voucherCode?: Maybe<Scalars['String']>;
};

export type GQLPurchaseItem = GQLPurchasePackageItem | GQLPurchaseProductItem;

export type GQLPurchaseItemInput = {
  customPrice?: InputMaybe<Scalars['Float']>;
  itemId: Scalars['ID'];
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type GQLPurchaseItemUpdate = GQLAddedPurchasePackage | GQLAddedPurchaseProduct | GQLExistingPurchaseItemUpdate;

export type GQLPurchasePackageItem = {
  __typename?: 'PurchasePackageItem';
  itemId: Scalars['ID'];
  items?: Maybe<Array<GQLPurchaseProductItem>>;
  name: Scalars['String'];
  priceDollars: Scalars['Float'];
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
  type: GQLProductType;
};

export type GQLPurchaseProductItem = {
  __typename?: 'PurchaseProductItem';
  basePriceDollars: Scalars['Float'];
  itemId: Scalars['ID'];
  name: Scalars['String'];
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
  type: GQLProductType;
};

export type GQLPurchaseQuery = {
  purchaseId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLPutLessonReviewInput = {
  date: Scalars['DateTime'];
  instructorId: Scalars['ID'];
  lessonId: Scalars['ID'];
  rating: Scalars['Int'];
  source: Scalars['String'];
  studentId: Scalars['ID'];
  studentName: Scalars['String'];
  text: Scalars['String'];
};

export type GQLPutTestLessonInput = {
  bookedAt: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  instructorId: Scalars['ID'];
  isObserverAllowed: Scalars['Boolean'];
  isShareable: Scalars['Boolean'];
  lessonId?: InputMaybe<Scalars['ID']>;
  pickupLocation: Scalars['String'];
  price: Scalars['Float'];
  startTime: Scalars['DateTime'];
  students: Array<GQLLessonStudentDetailsUpdateInput>;
};

export type GQLPutTestProductInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  products?: InputMaybe<Array<GQLProductLinkInput>>;
  type: GQLProductType;
};

export type GQLPutTestRegionsInput = {
  features: Array<GQLGeoJsonFeatureInput>;
  type: Scalars['String'];
};

export type GQLQuantityByIndicator = {
  __typename?: 'QuantityByIndicator';
  quantity: Scalars['Int'];
  syncIndicator: GQLSyncIndicator;
};

export type GQLQuery = {
  __typename?: 'Query';
  activeInstructors: Array<GQLInstructor>;
  auditLogs?: Maybe<GQLAuditLogsPaginatedList>;
  availabilities: Array<GQLAvailability>;
  availableLessonSlots: Array<GQLAvailabilitySlotPerDay>;
  bookingAlerts: GQLBookingAlertsPaginatedList;
  cart: GQLCart;
  certificateTypes: Array<GQLCertificateType>;
  certificates: GQLCertificatesPaginatedList;
  cohort: GQLCohort;
  cohorts: Array<GQLCohort>;
  cohortsForStudent: Array<GQLCohort>;
  coupons: GQLCouponsPaginatedList;
  courseById: GQLOnlineCourseContent;
  courseBySlug: GQLOnlineCourseContent;
  courseCoupons: Array<Maybe<GQLCourseCoupon>>;
  coursePrepTest: GQLCoursePrepTest;
  coursePrepTestScores: Array<GQLCoursePrepTestChapterScores>;
  courses: Array<GQLOnlineCourseContent>;
  coursesWithIds: Array<GQLOnlineCourseContent>;
  drivingSkills: Array<GQLDrivingSkill>;
  expiration: GQLExpiration;
  getReview: GQLFirebaseLessonReview;
  getStudentBalance: GQLStudentBalance;
  getTestStudent?: Maybe<GQLStudent>;
  instructor: GQLInstructor;
  instructorAlerts: GQLInstructorAlertPaginatedList;
  instructorBadges: Array<GQLInstructorBadge>;
  instructorSchedule: GQLInstructorSchedule;
  instructorSchedules?: Maybe<Array<GQLInstructorSchedule>>;
  instructorTimeLogs: GQLInstructorTimeLogPaginatedList;
  instructorZoneGeoJson: GQLInstructorZone;
  instructors: GQLInstructorsPaginatedList;
  instructorsCoverageGeoJson: GQLInstructorsCoverage;
  instructorsInSuperRegionForUser: Array<GQLInstructorWithSuperRegion>;
  instructorsSchedules?: Maybe<Array<GQLInstructorSchedule>>;
  lastBookedInstructor?: Maybe<GQLLastBookedInstructor>;
  lastCompletedSyncCheck?: Maybe<GQLSyncCheck>;
  lectureById: GQLCourseLecture;
  ledgerLog: GQLLedgerLog;
  ledgerLogs: GQLLedgerLogsPaginatedList;
  lesson: GQLLesson;
  lessonDrivingSkillsReportByStudentAndLesson: GQLLessonDrivingSkillsReport;
  lessonTypeById: GQLLessonType;
  lessonTypes: GQLLessonTypesPaginatedList;
  lessonTypesForAddress: Array<GQLLessonType>;
  lessons: GQLLessonsPaginatedList;
  lessonsByInstructorIds: Array<GQLLesson>;
  lessonsDrivingSkillsReportsByStudent: GQLLessonDrivingSkillsReportPaginatedList;
  lessonsReviewsByInstructor: GQLLessonsReviewsPaginatedList;
  me: GQLMyself;
  notes: GQLNotesPaginatedList;
  priceScheme: GQLPriceScheme;
  priceSchemeForAddress?: Maybe<GQLPriceScheme>;
  priceSchemeFragment: GQLPriceSchemeFragment;
  priceSchemeFragments: GQLPriceSchemeFragmentPaginatedList;
  priceSchemes: GQLPriceSchemePaginatedList;
  product: GQLProduct;
  products: GQLProductsPaginatedList;
  purchase: GQLPurchase;
  reference: Array<GQLReferenceItem>;
  refund: GQLRefund;
  regionSchemes: GQLRegionSchemePaginatedList;
  regionalManagers: GQLPersonPaginatedList;
  regions: GQLRegionPaginatedList;
  regionsGeoJson: GQLRegionZones;
  sharedLessons: Array<GQLSharedLesson>;
  stateConfigs: GQLStateConfigPaginatedList;
  stateMeetingPoints: GQLStateMeetingPointPaginatedList;
  student?: Maybe<GQLStudent>;
  studentOnlineCourse: GQLStudentOnlineCoursesPaginatedList;
  studentOnlineCourseReport: GQLStudentOnlineCourseReport;
  studentOnlineCourseTimeProgress: GQLStudentOnlineCourseTimeProgress;
  students: GQLStudentsPaginatedList;
  superRegions: GQLSuperRegionPaginatedList;
  syncCheck: GQLSyncCheck;
  syncChecks: GQLSyncChecksPaginatedList;
  syncConflict: GQLSyncConflict;
  syncConflicts: GQLSyncConflictsPaginatedList;
  transferAccountCheck: GQLTransferAccountCheckResult;
  transientSlots: Array<GQLTransientSlot>;
  usStates: Array<GQLUsState>;
  userLogs?: Maybe<GQLUserLogsPaginatedList>;
  userRoles: Array<GQLUserRole>;
  vehicleAvailabilities: Array<GQLVehicleAvailability>;
  vehicleAvails: Array<GQLVehicleAvail>;
  vehicleMapAvailabilities: Array<GQLVehicleMapAvailability>;
  vehicles?: Maybe<GQLVehiclesPaginatedList>;
  voucherSources: Array<GQLVoucherSource>;
  vouchers?: Maybe<GQLVouchersPaginatedList>;
  vouchersRedeemedByStudent?: Maybe<GQLVouchersPaginatedList>;
  waitlist: GQLWaitlistPaginatedList;
  waitlistComments: Array<GQLWaitlistComment>;
};


export type GQLQueryAuditLogsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query: GQLAuditLogsQuery;
};


export type GQLQueryAvailabilitiesArgs = {
  query: GQLAvailabilitiesQuery;
};


export type GQLQueryAvailableLessonSlotsArgs = {
  query: GQLSchedulerQueryInput;
};


export type GQLQueryBookingAlertsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query: GQLBookingAlertsQuery;
};


export type GQLQueryCartArgs = {
  id: Scalars['ID'];
};


export type GQLQueryCertificatesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query?: InputMaybe<GQLCertificatesQuery>;
};


export type GQLQueryCohortArgs = {
  id: Scalars['ID'];
};


export type GQLQueryCohortsArgs = {
  courseId: Scalars['ID'];
  doShowClosed?: InputMaybe<Scalars['Boolean']>;
};


export type GQLQueryCohortsForStudentArgs = {
  courseId: Scalars['ID'];
  studentId: Scalars['ID'];
};


export type GQLQueryCouponsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
};


export type GQLQueryCourseByIdArgs = {
  id: Scalars['ID'];
};


export type GQLQueryCourseBySlugArgs = {
  slug: Scalars['String'];
};


export type GQLQueryCourseCouponsArgs = {
  courseId: Scalars['String'];
};


export type GQLQueryCoursePrepTestArgs = {
  courseId: Scalars['ID'];
};


export type GQLQueryCoursePrepTestScoresArgs = {
  courseId: Scalars['ID'];
};


export type GQLQueryCoursesArgs = {
  query?: InputMaybe<GQLCoursesQuery>;
};


export type GQLQueryCoursesWithIdsArgs = {
  ids: Array<Scalars['ID']>;
};


export type GQLQueryExpirationArgs = {
  query: GQLExpirationQuery;
};


export type GQLQueryGetReviewArgs = {
  input: GQLGetLessonReviewInput;
};


export type GQLQueryGetStudentBalanceArgs = {
  studentId: Scalars['ID'];
};


export type GQLQueryGetTestStudentArgs = {
  studentId: Scalars['ID'];
};


export type GQLQueryInstructorArgs = {
  instructorId: Scalars['ID'];
};


export type GQLQueryInstructorAlertsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
};


export type GQLQueryInstructorBadgesArgs = {
  query: GQLInstructorBadgeQuery;
};


export type GQLQueryInstructorScheduleArgs = {
  query: GQLInstructorScheduleQuery;
};


export type GQLQueryInstructorSchedulesArgs = {
  query: GQLInstructorScheduleQuery;
};


export type GQLQueryInstructorTimeLogsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query: GQLInstructorTimeLogListQuery;
};


export type GQLQueryInstructorZoneGeoJsonArgs = {
  query: GQLInstructorZoneQueryInput;
};


export type GQLQueryInstructorsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query?: InputMaybe<GQLInstructorsQuery>;
};


export type GQLQueryInstructorsSchedulesArgs = {
  query: GQLInstructorSchedulesQuery;
};


export type GQLQueryLastBookedInstructorArgs = {
  studentId: Scalars['ID'];
};


export type GQLQueryLectureByIdArgs = {
  courseId: Scalars['ID'];
  lectureId: Scalars['ID'];
};


export type GQLQueryLedgerLogArgs = {
  id: Scalars['ID'];
};


export type GQLQueryLedgerLogsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query: GQLLedgerLogsQuery;
};


export type GQLQueryLessonArgs = {
  query: GQLLessonQuery;
};


export type GQLQueryLessonDrivingSkillsReportByStudentAndLessonArgs = {
  query: GQLLessonQuery;
};


export type GQLQueryLessonTypeByIdArgs = {
  lessonTypeId: Scalars['ID'];
};


export type GQLQueryLessonTypesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
};


export type GQLQueryLessonTypesForAddressArgs = {
  address: Scalars['String'];
};


export type GQLQueryLessonsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query: GQLLessonsQuery;
};


export type GQLQueryLessonsByInstructorIdsArgs = {
  query: GQLLessonsQueryByInstructorIds;
};


export type GQLQueryLessonsDrivingSkillsReportsByStudentArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query: GQLLessonsDrivingSkillsReportsQuery;
};


export type GQLQueryLessonsReviewsByInstructorArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query: GQLLessonsReviewsByInstructorQuery;
};


export type GQLQueryNotesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query: GQLNotesQuery;
};


export type GQLQueryPriceSchemeArgs = {
  id: Scalars['ID'];
};


export type GQLQueryPriceSchemeForAddressArgs = {
  address: Scalars['String'];
};


export type GQLQueryPriceSchemeFragmentArgs = {
  id: Scalars['ID'];
};


export type GQLQueryPriceSchemeFragmentsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query?: InputMaybe<GQLPriceSchemeFragmentsQuery>;
};


export type GQLQueryPriceSchemesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query?: InputMaybe<GQLPriceSchemesQuery>;
};


export type GQLQueryProductArgs = {
  productId: Scalars['ID'];
};


export type GQLQueryProductsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query?: InputMaybe<GQLProductsQuery>;
};


export type GQLQueryPurchaseArgs = {
  query: GQLPurchaseQuery;
};


export type GQLQueryRefundArgs = {
  query: GQLRefundQuery;
};


export type GQLQueryRegionSchemesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query?: InputMaybe<GQLRegionSchemeQuery>;
};


export type GQLQueryRegionalManagersArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query?: InputMaybe<GQLRegionalManagersQuery>;
};


export type GQLQueryRegionsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
};


export type GQLQuerySharedLessonsArgs = {
  query: GQLSharedLessonsQuery;
};


export type GQLQueryStateConfigsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
};


export type GQLQueryStateMeetingPointsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query: GQLStateMeetingPointsQuery;
};


export type GQLQueryStudentArgs = {
  studentId: Scalars['ID'];
};


export type GQLQueryStudentOnlineCourseArgs = {
  pageQuery: GQLPageQuery;
  studentId: Scalars['ID'];
};


export type GQLQueryStudentOnlineCourseReportArgs = {
  courseId: Scalars['ID'];
  studentId: Scalars['ID'];
};


export type GQLQueryStudentOnlineCourseTimeProgressArgs = {
  onlineCourseId: Scalars['ID'];
  studentId: Scalars['ID'];
};


export type GQLQueryStudentsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query?: InputMaybe<GQLStudentsQuery>;
};


export type GQLQuerySuperRegionsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query?: InputMaybe<GQLSuperRegionQuery>;
};


export type GQLQuerySyncCheckArgs = {
  id: Scalars['ID'];
};


export type GQLQuerySyncChecksArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
};


export type GQLQuerySyncConflictArgs = {
  id: Scalars['ID'];
};


export type GQLQuerySyncConflictsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query?: InputMaybe<GQLSyncConflictsQuery>;
};


export type GQLQueryTransferAccountCheckArgs = {
  query: GQLTransferAccountInput;
};


export type GQLQueryTransientSlotsArgs = {
  query: GQLTransientSlotsQuery;
};


export type GQLQueryUserLogsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
  query: GQLUserLogsQuery;
};


export type GQLQueryUserRolesArgs = {
  userId: Scalars['ID'];
};


export type GQLQueryVehicleAvailabilitiesArgs = {
  query: GQLVehicleAvailabilityQuery;
};


export type GQLQueryVehicleAvailsArgs = {
  startTime: Scalars['DateTime'];
  vehicleId: Scalars['ID'];
};


export type GQLQueryVehicleMapAvailabilitiesArgs = {
  query: GQLVehicleMapAvailabilityQuery;
};


export type GQLQueryVehiclesArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
};


export type GQLQueryVouchersArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
};


export type GQLQueryVouchersRedeemedByStudentArgs = {
  query: GQLVouchersRedeemedByStudentQuery;
};


export type GQLQueryWaitlistArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
};


export type GQLQueryWaitlistCommentsArgs = {
  query: GQLWaitlistCommentsQuery;
};

export type GQLRedeemVoucherResult = {
  __typename?: 'RedeemVoucherResult';
  purchase: GQLPurchase;
  voucher: GQLVoucher;
};

export type GQLReferenceItem = {
  __typename?: 'ReferenceItem';
  category: Scalars['String'];
  label: Scalars['String'];
  subLabel?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type GQLRefund = {
  __typename?: 'Refund';
  amountToRefund: Scalars['Float'];
  categoryId: GQLPaymentCategories;
  createdById: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isVoid: Scalars['Boolean'];
  itemsToRefund?: Maybe<Array<GQLItemToRefund>>;
  purchaseId: Scalars['ID'];
  stripeChargeId?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  versionId: Scalars['ID'];
};

/**
 * Refund requests have to have at least one of
 * (amountToRefund, itemsToRefund) fields set.
 *
 * Here are all the valid options:
 *
 *   1. only amountToRefund field is set
 *   2. both amountToRefund & itemsToRefund are set
 *   3. only itemsToRefund field is set
 *
 * Having both amountToRefund & itemsToRefund not set is invalid.
 *
 * Case #1: amountToRefund was refunded, no items invalidated
 * Case #2: amountToRefund was refunded, itemsToRefund can't be used anymore
 * Case #3: amountToRefund is calculated from itemsToRefund,
 *         itemsToRefund can't be used anymore
 */
export type GQLRefundPurchaseInput = {
  amountToRefund?: InputMaybe<Scalars['Float']>;
  categoryId: GQLPaymentCategories;
  description: Scalars['String'];
  itemsToRefund?: InputMaybe<Array<GQLItemToRefundInput>>;
  purchaseId: Scalars['ID'];
  stripeChargeId?: InputMaybe<Scalars['String']>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  studentId: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type GQLRefundQuery = {
  refundId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLRegion = {
  __typename?: 'Region';
  id: Scalars['ID'];
  name: Scalars['String'];
  state?: Maybe<GQLUsStateId>;
  superRegion?: Maybe<Scalars['String']>;
};

export type GQLRegionEdge = {
  __typename?: 'RegionEdge';
  cursor: Scalars['ID'];
  node: GQLRegion;
};

export type GQLRegionPaginatedList = {
  __typename?: 'RegionPaginatedList';
  edges: Array<GQLRegionEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLRegionScheme = {
  __typename?: 'RegionScheme';
  id: Scalars['ID'];
  priceScheme?: Maybe<GQLPriceScheme>;
  priceSchemeId?: Maybe<Scalars['ID']>;
  superRegion?: Maybe<GQLSuperRegion>;
  superRegionId?: Maybe<Scalars['ID']>;
};

export type GQLRegionSchemeEdge = {
  __typename?: 'RegionSchemeEdge';
  cursor: Scalars['ID'];
  node: GQLRegionScheme;
};

export type GQLRegionSchemePaginatedList = {
  __typename?: 'RegionSchemePaginatedList';
  edges: Array<GQLRegionSchemeEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLRegionSchemeQuery = {
  /** The value 'unset' will target region schemes without a price scheme assigned */
  priceSchemeId?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<GQLUsStateId>;
  /** The value 'unset' will target region schemes without a super region assigned */
  superRegionId?: InputMaybe<Scalars['ID']>;
};

export type GQLRegionSchemeUpdateInput = {
  id: Scalars['ID'];
  priceSchemeId?: InputMaybe<Scalars['ID']>;
  superRegionId?: InputMaybe<Scalars['ID']>;
};

export type GQLRegionZones = {
  __typename?: 'RegionZones';
  center: GQLLatLng;
  features: Array<GQLGeoJsonFeature>;
};

export type GQLRegionalManagersQuery = {
  id?: InputMaybe<Scalars['ID']>;
  term?: InputMaybe<Scalars['String']>;
};

export type GQLScheduleDayTimes = {
  __typename?: 'ScheduleDayTimes';
  dayOfTheWeek: Scalars['String'];
  endTime: Scalars['String'];
  startTime: Scalars['String'];
};

export type GQLScheduleLessonInput = {
  studentDetails?: InputMaybe<GQLLessonStudentDetailsInput>;
  studentId: Scalars['ID'];
  transientSlotId: Scalars['ID'];
};

export type GQLSchedulerQueryInput = {
  address: Scalars['String'];
  appointmentValue: Scalars['Int'];
  doPrioritization?: InputMaybe<Scalars['Boolean']>;
  durationMinutes: Scalars['Int'];
  endDate: Scalars['String'];
  forceFresh?: InputMaybe<Scalars['Boolean']>;
  includePrivate?: InputMaybe<Scalars['Boolean']>;
  instructorId?: InputMaybe<Scalars['ID']>;
  maximizeSlotsInAvail?: InputMaybe<Scalars['Boolean']>;
  preferAfternoons?: InputMaybe<Scalars['Boolean']>;
  startDate: Scalars['String'];
};

export type GQLScholarshipApplicationResponse = {
  __typename?: 'ScholarshipApplicationResponse';
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type GQLSelfBookSharedLessonInput = {
  addStudent: GQLLessonUpdateAddStudent;
  transientSlotId: Scalars['ID'];
};

export type GQLSetCouponInput = {
  cartId: Scalars['ID'];
  couponCode: Scalars['String'];
};

export type GQLSetStudentInput = {
  id: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLSharedLesson = {
  __typename?: 'SharedLesson';
  eligibleStudents: Array<GQLEligibleStudent>;
  lesson: GQLLesson;
};

export type GQLSharedLessonsQuery = {
  maxMilesFromLesson: Scalars['Int'];
  meetingPointId?: InputMaybe<Scalars['ID']>;
  minDrivingMinutes: Scalars['Int'];
  minObservationMinutes: Scalars['Int'];
  superRegionId?: InputMaybe<Scalars['ID']>;
  withLicense?: InputMaybe<Scalars['Boolean']>;
};

export type GQLSignInWithEmailInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export enum GQLSortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type GQLSortKeyDirectionPair = {
  key: Scalars['String'];
  sortDirection: GQLSortDirection;
};

export type GQLStateConfig = {
  __typename?: 'StateConfig';
  hasHomePickup: Scalars['Boolean'];
  hasMeetingPoints: Scalars['Boolean'];
  id: Scalars['ID'];
  isShareableByDefault: Scalars['Boolean'];
  lessonTypeIds?: Maybe<Array<Scalars['ID']>>;
  lessonTypes?: Maybe<Array<GQLLessonType>>;
  longName: Scalars['String'];
  state: Scalars['ID'];
};

export type GQLStateConfigCreateInput = {
  hasHomePickup: Scalars['Boolean'];
  hasMeetingPoints: Scalars['Boolean'];
  isShareableByDefault: Scalars['Boolean'];
  lessonTypes?: InputMaybe<Array<GQLLessonTypeForStateConfigInput>>;
  longName: Scalars['String'];
  state: Scalars['String'];
};

export type GQLStateConfigEdge = {
  __typename?: 'StateConfigEdge';
  cursor: Scalars['ID'];
  node: GQLStateConfig;
};

export type GQLStateConfigPaginatedList = {
  __typename?: 'StateConfigPaginatedList';
  edges: Array<GQLStateConfigEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLStateConfigUpdateInput = {
  hasHomePickup?: InputMaybe<Scalars['Boolean']>;
  hasMeetingPoints?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isShareableByDefault?: InputMaybe<Scalars['Boolean']>;
  lessonTypes?: InputMaybe<Array<GQLLessonTypeForStateConfigInput>>;
  longName?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type GQLStateMeetingPoint = {
  __typename?: 'StateMeetingPoint';
  address: Scalars['String'];
  coords?: Maybe<GQLLatLng>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  state: Scalars['ID'];
};

export type GQLStateMeetingPointCreateInput = {
  address: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  state: Scalars['ID'];
};

export type GQLStateMeetingPointEdge = {
  __typename?: 'StateMeetingPointEdge';
  cursor: Scalars['ID'];
  node: GQLStateMeetingPoint;
};

export type GQLStateMeetingPointPaginatedList = {
  __typename?: 'StateMeetingPointPaginatedList';
  edges: Array<GQLStateMeetingPointEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLStateMeetingPointUpdateInput = {
  address?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: InputMaybe<Scalars['String']>;
  state: Scalars['ID'];
};

export type GQLStateMeetingPointsQuery = {
  state: Scalars['ID'];
};

export type GQLStudent = {
  __typename?: 'Student';
  acceptTOS?: Maybe<Scalars['Boolean']>;
  address: GQLAddress;
  age?: Maybe<Scalars['Int']>;
  dmvPreRegNumber?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['DateTime']>;
  drivingHistory?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  legalAndPermissions?: Maybe<GQLStudentLegalAndPermissions>;
  licenseExpirationDate?: Maybe<Scalars['DateTime']>;
  licenseIssueDate?: Maybe<Scalars['DateTime']>;
  licenseNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentEmail?: Maybe<Scalars['String']>;
  parentName?: Maybe<Scalars['String']>;
  preferredPronoun?: Maybe<Scalars['String']>;
  previousHours?: Maybe<Scalars['Float']>;
  previousSchoolLessons?: Maybe<Scalars['String']>;
  productBalance: GQLProductBalance;
  productBalanceList: Array<GQLProductBalance>;
  referral?: Maybe<Scalars['String']>;
  signInEmail?: Maybe<Scalars['String']>;
  signupTimestamp?: Maybe<Scalars['DateTime']>;
  smsOptIn?: Maybe<Scalars['Boolean']>;
  stripeCustomerId?: Maybe<Scalars['ID']>;
  timeZone?: Maybe<Scalars['String']>;
  usStateId?: Maybe<Scalars['ID']>;
};


export type GQLStudentProductBalanceArgs = {
  productId: Scalars['ID'];
};

export type GQLStudentBalance = {
  __typename?: 'StudentBalance';
  drivingMinutesPurchased: Scalars['Float'];
  drivingMinutesRemaining: Scalars['Float'];
  drivingMinutesUsed: Scalars['Float'];
  expirationDrivingMinutesUsed: Scalars['Float'];
  homePickupsExpired: Scalars['Float'];
  homePickupsPurchased: Scalars['Float'];
  homePickupsRemaining: Scalars['Float'];
  homePickupsUsed: Scalars['Float'];
  observationMinutesPurchased: Scalars['Float'];
  observationMinutesRemaining: Scalars['Float'];
  observationMinutesUsed: Scalars['Float'];
  userId: Scalars['ID'];
};

export enum GQLStudentContractStatus {
  Issue = 'Issue',
  NotSent = 'NotSent',
  Sent = 'Sent',
  Signed = 'Signed'
}

export type GQLStudentCourseMigration = {
  __typename?: 'StudentCourseMigration';
  destinationStudentId: Scalars['ID'];
  onlineCourseId: Scalars['ID'];
  sourceStudentId: Scalars['ID'];
};

export type GQLStudentEdge = {
  __typename?: 'StudentEdge';
  cursor: Scalars['ID'];
  node: GQLStudent;
};

export type GQLStudentId = {
  __typename?: 'StudentId';
  studentId: Scalars['ID'];
};

export type GQLStudentIdProductId = {
  __typename?: 'StudentIdProductId';
  productId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLStudentInput = {
  acceptTOS?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<GQLAddressInput>;
  dob?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  legalAndPermissions?: InputMaybe<GQLStudentLegalAndPermissionsInput>;
  licenseExpirationDate?: InputMaybe<Scalars['DateTime']>;
  licenseIssueDate?: InputMaybe<Scalars['DateTime']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentEmail?: InputMaybe<Scalars['String']>;
  parentName?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  previousHours?: InputMaybe<Scalars['Float']>;
  signupTimestamp?: InputMaybe<Scalars['DateTime']>;
  smsOptIn?: InputMaybe<Scalars['Boolean']>;
  stripeCustomerId?: InputMaybe<Scalars['ID']>;
  usStateId?: InputMaybe<Scalars['ID']>;
};

export type GQLStudentLegalAndPermissions = {
  __typename?: 'StudentLegalAndPermissions';
  contractStatus: GQLStudentContractStatus;
  observerApproved: Scalars['Boolean'];
  privateApproved: Scalars['Boolean'];
  transferStatus: GQLStudentTransferStatus;
  transferType?: Maybe<GQLStudentTransferType>;
};

export type GQLStudentLegalAndPermissionsInput = {
  contractStatus: GQLStudentContractStatus;
  observerApproved: Scalars['Boolean'];
  privateApproved: Scalars['Boolean'];
  transferStatus: GQLStudentTransferStatus;
  transferType?: InputMaybe<GQLStudentTransferType>;
};

export type GQLStudentObject = {
  __typename?: 'StudentObject';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLStudentOnlineCourse = {
  __typename?: 'StudentOnlineCourse';
  courseId: Scalars['ID'];
  currentLecture?: Maybe<Scalars['String']>;
  enrolled: Scalars['Boolean'];
  id: Scalars['ID'];
  lectures?: Maybe<Array<GQLStudentOnlineCourseLecture>>;
  name: Scalars['String'];
  percentComplete?: Maybe<Scalars['Float']>;
  percentTestsPassed?: Maybe<Scalars['Float']>;
  studentId: Scalars['ID'];
  tests?: Maybe<Array<GQLStudentOnlineCourseTest>>;
};

export type GQLStudentOnlineCourseEdge = {
  __typename?: 'StudentOnlineCourseEdge';
  cursor: Scalars['ID'];
  node: GQLStudentOnlineCourse;
};

export type GQLStudentOnlineCourseInput = {
  onlineCourseId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLStudentOnlineCourseLecture = {
  __typename?: 'StudentOnlineCourseLecture';
  id: Scalars['ID'];
};

export type GQLStudentOnlineCourseReport = {
  __typename?: 'StudentOnlineCourseReport';
  address: Scalars['String'];
  completionDate?: Maybe<Scalars['String']>;
  courseTotalTimeSeconds?: Maybe<Scalars['Int']>;
  dmvPreRegNumber?: Maybe<Scalars['String']>;
  dob: Scalars['String'];
  lectureReports?: Maybe<Array<GQLLectureReport>>;
  name: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
};

export type GQLStudentOnlineCourseTest = {
  __typename?: 'StudentOnlineCourseTest';
  id: Scalars['ID'];
  numCorrect: Scalars['Int'];
  totalQuestions: Scalars['Int'];
};

export type GQLStudentOnlineCourseTimeProgress = {
  __typename?: 'StudentOnlineCourseTimeProgress';
  courseTotalTime: Scalars['Int'];
  totalTimePerDay?: Maybe<Array<GQLLectureTimeDaily>>;
};

export type GQLStudentOnlineCoursesPaginatedList = {
  __typename?: 'StudentOnlineCoursesPaginatedList';
  edges: Array<GQLStudentOnlineCourseEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLStudentSessionAttendance = {
  __typename?: 'StudentSessionAttendance';
  attendance: GQLAttendance;
  cohortSessionId: Scalars['ID'];
  id: Scalars['ID'];
  makeUpCohortId?: Maybe<Scalars['ID']>;
  makeUpSessionId?: Maybe<Scalars['ID']>;
  studentId: Scalars['ID'];
};

export enum GQLStudentTransferStatus {
  No = 'No',
  YesMissingPaperwork = 'YesMissingPaperwork',
  YesPaperWorkComplete = 'YesPaperWorkComplete'
}

export enum GQLStudentTransferType {
  Both = 'Both',
  CarOnly = 'CarOnly',
  CourseOnly = 'CourseOnly'
}

export type GQLStudentsPaginatedList = {
  __typename?: 'StudentsPaginatedList';
  edges: Array<GQLStudentEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLStudentsQuery = {
  id?: InputMaybe<Scalars['ID']>;
  term?: InputMaybe<Scalars['String']>;
};

export type GQLSubItemInput = {
  itemId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type GQLSubmitCoursePrepTestChapterScoresInput = {
  chapterId: Scalars['ID'];
  courseId: Scalars['ID'];
  questionScores: Array<GQLLectureQuizQuestionPrepTestScoreInput>;
};

export type GQLSuperRegion = {
  __typename?: 'SuperRegion';
  googleBusinessPageUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  state?: Maybe<GQLUsStateId>;
};

export type GQLSuperRegionCreateInput = {
  googleBusinessPageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  state: GQLUsStateId;
};

export type GQLSuperRegionEdge = {
  __typename?: 'SuperRegionEdge';
  cursor: Scalars['ID'];
  node: GQLSuperRegion;
};

export type GQLSuperRegionPaginatedList = {
  __typename?: 'SuperRegionPaginatedList';
  edges: Array<GQLSuperRegionEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLSuperRegionQuery = {
  term?: InputMaybe<Scalars['String']>;
};

export type GQLSuperRegionUpdateInput = {
  googleBusinessPageUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<GQLUsStateId>;
};

export type GQLSyncCheck = {
  __typename?: 'SyncCheck';
  checkedStudentsCount: Scalars['Int'];
  conflicts: GQLSyncConflictsPaginatedList;
  conflictsByIndicator: Array<GQLQuantityByIndicator>;
  conflictsCount: Scalars['Int'];
  endTime?: Maybe<Scalars['DateTime']>;
  errors: Array<GQLSyncCheckError>;
  errorsCount: Scalars['Int'];
  id: Scalars['ID'];
  ignoredStudentsCount: Scalars['Int'];
  isCancelled: Scalars['Boolean'];
  ledgerStudentsCount: Scalars['Int'];
  outOfSyncStudentsCount: Scalars['Int'];
  startTime: Scalars['DateTime'];
  versionId: Scalars['ID'];
};


export type GQLSyncCheckConflictsArgs = {
  pageQuery?: InputMaybe<GQLPageQuery>;
};

export type GQLSyncCheckEdge = {
  __typename?: 'SyncCheckEdge';
  cursor: Scalars['ID'];
  node: GQLSyncCheck;
};

export type GQLSyncCheckError = {
  __typename?: 'SyncCheckError';
  message: Scalars['String'];
  stack: Scalars['String'];
};

export enum GQLSyncCheckLogTypes {
  Cancel = 'cancel',
  Conflict = 'conflict',
  End = 'end',
  Progress = 'progress',
  Start = 'start'
}

export type GQLSyncChecksPaginatedList = {
  __typename?: 'SyncChecksPaginatedList';
  edges: Array<GQLSyncCheckEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLSyncConflict = {
  details: GQLSyncConflictDetails;
  id: Scalars['ID'];
  lastSyncCheckId: Scalars['ID'];
  syncIndicator: GQLSyncIndicator;
};

export type GQLSyncConflictDayBreakdown = {
  __typename?: 'SyncConflictDayBreakdown';
  date: Scalars['DateTime'];
  hasConflict: Scalars['Boolean'];
  legacyLogs: Array<GQLLegacyLogSummary>;
  nextLogs: Array<GQLLedgerLog>;
};

export type GQLSyncConflictDetails = {
  __typename?: 'SyncConflictDetails';
  dayByDayBreakdown: Array<GQLSyncConflictDayBreakdown>;
  timeZone: Scalars['String'];
};

export type GQLSyncConflictEdge = {
  __typename?: 'SyncConflictEdge';
  cursor: Scalars['ID'];
  node: GQLSyncConflict;
};

export type GQLSyncConflictsPaginatedList = {
  __typename?: 'SyncConflictsPaginatedList';
  edges: Array<GQLSyncConflictEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLSyncConflictsQuery = {
  syncCheckId?: InputMaybe<Scalars['ID']>;
};

export enum GQLSyncIndicator {
  StudentExpiredMinutes = 'studentExpiredMinutes',
  StudentFundsBalance = 'studentFundsBalance',
  StudentProductBalance = 'studentProductBalance'
}

export type GQLSyncStudentExpiredMinutesConflict = GQLSyncConflict & {
  __typename?: 'SyncStudentExpiredMinutesConflict';
  details: GQLSyncConflictDetails;
  id: Scalars['ID'];
  lastSyncCheckId: Scalars['ID'];
  legacyId: GQLStudentId;
  legacyValue: GQLSyncStudentExpiredMinutesEntityValue;
  nextId: GQLStudentId;
  nextValue: GQLSyncStudentExpiredMinutesEntityValue;
  syncIndicator: GQLSyncIndicator;
};

export type GQLSyncStudentExpiredMinutesEntityValue = {
  __typename?: 'SyncStudentExpiredMinutesEntityValue';
  expiredMinutesTotal: Scalars['Int'];
};

export type GQLSyncStudentFundsBalanceConflict = GQLSyncConflict & {
  __typename?: 'SyncStudentFundsBalanceConflict';
  details: GQLSyncConflictDetails;
  id: Scalars['ID'];
  lastSyncCheckId: Scalars['ID'];
  legacyId: GQLStudentId;
  legacyValue: GQLSyncStudentFundsBalanceConflictEntityValue;
  nextId: GQLStudentId;
  nextValue: GQLSyncStudentFundsBalanceConflictEntityValue;
  syncIndicator: GQLSyncIndicator;
};

export type GQLSyncStudentFundsBalanceConflictEntityValue = {
  __typename?: 'SyncStudentFundsBalanceConflictEntityValue';
  paidCents: Scalars['Int'];
  refundedCents: Scalars['Int'];
  sentCents: Scalars['Int'];
};

export type GQLSyncStudentProductBalanceConflict = GQLSyncConflict & {
  __typename?: 'SyncStudentProductBalanceConflict';
  details: GQLSyncConflictDetails;
  id: Scalars['ID'];
  lastSyncCheckId: Scalars['ID'];
  legacyId: GQLStudentIdProductId;
  legacyValue: GQLSyncStudentProductBalanceConflictEntityValue;
  nextId: GQLStudentIdProductId;
  nextValue: GQLSyncStudentProductBalanceConflictEntityValue;
  syncIndicator: GQLSyncIndicator;
};

export type GQLSyncStudentProductBalanceConflictEntityValue = {
  __typename?: 'SyncStudentProductBalanceConflictEntityValue';
  purchasedQuantity: Scalars['Int'];
  refundedQuantity: Scalars['Int'];
  sentQuantity: Scalars['Int'];
  usedQuantity: Scalars['Int'];
};

export type GQLTestChargeLedgerLogInput = {
  drivingMinutes: Scalars['Int'];
  observationMinutes: Scalars['Int'];
  studentId: Scalars['ID'];
};

export type GQLTestUserInput = {
  acceptTOS?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<GQLAddressInput>;
  dob?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentEmail?: InputMaybe<Scalars['String']>;
  parentName?: InputMaybe<Scalars['String']>;
  preferredPronoun?: InputMaybe<Scalars['String']>;
  roles: Array<GQLUserRole>;
  smsOptIn?: InputMaybe<Scalars['Boolean']>;
  uid?: InputMaybe<Scalars['ID']>;
};

export type GQLTransferAccountCheckResult = {
  __typename?: 'TransferAccountCheckResult';
  canTransfer: Scalars['Boolean'];
  errors: Array<Scalars['String']>;
  fromId: Scalars['ID'];
  toId: Scalars['ID'];
  warnings: Array<Scalars['String']>;
};

export type GQLTransferAccountInput = {
  fromId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  toId: Scalars['ID'];
};

export type GQLTransferExpirationInput = {
  description: Scalars['String'];
  destinationStudentId: Scalars['ID'];
  expirationId: Scalars['ID'];
  sourceStudentId: Scalars['ID'];
};

export type GQLTransferLessonInput = {
  description: Scalars['String'];
  destinationStudentId: Scalars['ID'];
  lessonId: Scalars['ID'];
  shouldTransferPurchases: Scalars['Boolean'];
  sourceStudentId: Scalars['ID'];
};

export type GQLTransferPurchaseInput = {
  amountToTransfer?: InputMaybe<Scalars['Float']>;
  description: Scalars['String'];
  destinationStudentId: Scalars['ID'];
  itemsToTransfer: Array<GQLItemToTransferInput>;
  purchaseId: Scalars['ID'];
  sourceStudentId: Scalars['ID'];
};

export type GQLTransientSlot = {
  __typename?: 'TransientSlot';
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  instructor: GQLInstructor;
  instructorId: Scalars['ID'];
  isBooked?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isReserved?: Maybe<Scalars['Boolean']>;
  isShareable: Scalars['Boolean'];
  isShared?: Maybe<Scalars['Boolean']>;
  lessonType: GQLLessonType;
  lessonTypeId: Scalars['ID'];
  locationId?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  packagePrices?: Maybe<Array<GQLPackagePrice>>;
  pickupAddress: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  reservedBy?: Maybe<GQLPerson>;
  reservedFor?: Maybe<GQLPerson>;
  searchAddress?: Maybe<Scalars['String']>;
  searchCity?: Maybe<Scalars['String']>;
  searchRegion?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GQLTransientSlotInput = {
  durationMinutes: Scalars['Float'];
  endTime: Scalars['DateTime'];
  instructorId: Scalars['ID'];
  isReserved?: InputMaybe<Scalars['Boolean']>;
  isShareable?: InputMaybe<Scalars['Boolean']>;
  lessonTypeId: Scalars['ID'];
  packagePrices?: InputMaybe<Array<GQLPackagePriceInput>>;
  price?: InputMaybe<Scalars['Float']>;
  reservedForId?: InputMaybe<Scalars['ID']>;
  searchAddress: Scalars['String'];
  startTime: Scalars['DateTime'];
};

export type GQLTransientSlotsQuery = {
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type GQLUsState = {
  __typename?: 'USState';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum GQLUsStateId {
  Ca = 'CA',
  Co = 'CO',
  Il = 'IL',
  In = 'IN',
  Mo = 'MO',
  Nv = 'NV',
  Pa = 'PA',
  Tx = 'TX',
  Wi = 'WI'
}

export type GQLUnenrollStudentFromCohortInput = {
  cohortId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLUpdateCourseSessionInput = {
  lectureId?: InputMaybe<Scalars['ID']>;
  onlineCourseId: Scalars['ID'];
  sessionId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLUpdateInstructorInput = {
  about?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<Array<GQLInstructorBadgeInput>>;
  baseAddress?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  isInactive?: InputMaybe<Scalars['Boolean']>;
  languages?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  licenseExpirationDate?: InputMaybe<Scalars['DateTime']>;
  licenseNumber?: InputMaybe<Scalars['String']>;
  longBreakMinutes?: InputMaybe<Scalars['Int']>;
  maxBaseTravelMinutes?: InputMaybe<Scalars['Int']>;
  maxBetweenTravelMinutes?: InputMaybe<Scalars['Int']>;
  payrollFirstName?: InputMaybe<Scalars['String']>;
  payrollLastName?: InputMaybe<Scalars['String']>;
  payrollTitle?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  portraitImage?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['ID']>;
  regionalManagerId?: InputMaybe<Scalars['ID']>;
  shortBreakMinutes?: InputMaybe<Scalars['Int']>;
  slackUsername?: InputMaybe<Scalars['String']>;
  summary?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  usStateId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type GQLUpdateInstructorQuery = {
  instructorId: Scalars['ID'];
};

export type GQLUpdateProductInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<GQLProductLinkInput>>;
};

export type GQLUpdateStudentCourseMetaInput = {
  allowLowerGrade?: InputMaybe<Scalars['Boolean']>;
  isPassed?: InputMaybe<Scalars['Boolean']>;
  lectureId: Scalars['ID'];
  numCorrect?: InputMaybe<Scalars['Int']>;
  onlineCourseId: Scalars['ID'];
  studentId: Scalars['ID'];
  totalQuestions?: InputMaybe<Scalars['Int']>;
};

export type GQLUpdateStudentSessionAttendanceInput = {
  attendance: GQLAttendance;
  cohortSessionId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLUpdateStudentsSessionAttendanceInput = {
  attendance: GQLAttendance;
  cohortSessionId: Scalars['ID'];
  studentIds: Array<Scalars['ID']>;
};

export type GQLUpdateVoucherInput = {
  amountDollars?: InputMaybe<Scalars['Float']>;
  amountHours?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type GQLUpdatedBy = {
  __typename?: 'UpdatedBy';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  role: GQLAuditLogUserRoles;
};

export type GQLUpsertCouponInput = {
  course?: InputMaybe<GQLCourseCouponInput>;
  lesson?: InputMaybe<GQLLessonCouponInput>;
  type: GQLCouponType;
};

export type GQLUserLog = {
  __typename?: 'UserLog';
  action: Scalars['String'];
  changedBy?: Maybe<GQLPerson>;
  changedById: Scalars['ID'];
  changes: Array<GQLLogChange>;
  id: Scalars['ID'];
  initiatedBy?: Maybe<GQLInitiatedBy>;
  instructor?: Maybe<GQLInstructor>;
  instructorId?: Maybe<Scalars['ID']>;
  lessonStartTime?: Maybe<Scalars['DateTime']>;
  note?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
  user?: Maybe<GQLPerson>;
  userId: Scalars['ID'];
};

export type GQLUserLogEdge = {
  __typename?: 'UserLogEdge';
  cursor: Scalars['ID'];
  node: GQLUserLog;
};

export type GQLUserLogsPaginatedList = {
  __typename?: 'UserLogsPaginatedList';
  edges: Array<GQLUserLogEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLUserLogsQuery = {
  logTypes: GQLLogTypes;
  userId?: InputMaybe<Scalars['ID']>;
};

export enum GQLUserRole {
  Admin = 'Admin',
  ClassroomManager = 'ClassroomManager',
  Fleet = 'Fleet',
  Fulfillment = 'Fulfillment',
  Instructor = 'Instructor',
  Manager = 'Manager',
  Student = 'Student',
  Support = 'Support',
  SyncCheckAdmin = 'SyncCheckAdmin'
}

export type GQLVehicle = {
  __typename?: 'Vehicle';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instructorIds?: Maybe<Array<Scalars['ID']>>;
  instructors?: Maybe<Array<GQLInstructor>>;
  make?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  opsManagerUserId?: Maybe<Scalars['ID']>;
  parkingHub?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  samsaraId?: Maybe<Scalars['String']>;
  usStateId?: Maybe<GQLUsStateId>;
};

export type GQLVehicleAvail = {
  __typename?: 'VehicleAvail';
  availId: Scalars['ID'];
  date: Scalars['String'];
  endTime: Scalars['DateTime'];
  instructor: GQLVehicleInstructorFields;
  startTime: Scalars['DateTime'];
  vehicleId: Scalars['ID'];
  vehicleName: Scalars['String'];
};

export type GQLVehicleAvailability = {
  __typename?: 'VehicleAvailability';
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  instructorName: Scalars['String'];
  startTime: Scalars['DateTime'];
  vehicleId: Scalars['ID'];
  vehicleName: Scalars['String'];
};

export type GQLVehicleAvailabilityQuery = {
  date: Scalars['String'];
};

export type GQLVehicleEdge = {
  __typename?: 'VehicleEdge';
  cursor: Scalars['ID'];
  node: GQLVehicle;
};

export type GQLVehicleInput = {
  color: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  instructorId: Scalars['ID'];
  make: Scalars['String'];
  model: Scalars['String'];
  name: Scalars['String'];
  number: Scalars['String'];
  opsManagerUserId?: InputMaybe<Scalars['ID']>;
  parkingHub: Scalars['String'];
  plate: Scalars['String'];
  samsaraId: Scalars['String'];
  state: GQLUsStateId;
};

export type GQLVehicleInstructorFields = {
  __typename?: 'VehicleInstructorFields';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  timeZone: Scalars['String'];
};

export type GQLVehicleMapAvailability = {
  __typename?: 'VehicleMapAvailability';
  availId: Scalars['ID'];
  endTime: Scalars['DateTime'];
  instructor: GQLInstructorOnAvail;
  startTime: Scalars['DateTime'];
  vehicleId: Scalars['ID'];
  vehicleName: Scalars['String'];
};

export type GQLVehicleMapAvailabilityQuery = {
  endTime: Scalars['String'];
  startTime: Scalars['String'];
  vehicleIds: Array<Scalars['ID']>;
};

export type GQLVehiclesPaginatedList = {
  __typename?: 'VehiclesPaginatedList';
  edges: Array<GQLVehicleEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLVoidExpirationInput = {
  expirationId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLVoidPurchaseInput = {
  purchaseId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLVoidRefundInput = {
  refundId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type GQLVoucher = {
  __typename?: 'Voucher';
  amountDollars?: Maybe<Scalars['Float']>;
  amountHours?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  isRedeemed: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  timestamp: Scalars['DateTime'];
  zipcode?: Maybe<Scalars['String']>;
};

export type GQLVoucherEdge = {
  __typename?: 'VoucherEdge';
  cursor: Scalars['ID'];
  node: GQLVoucher;
};

export type GQLVoucherRedeemInput = {
  id: Scalars['ID'];
  issuedToId: Scalars['ID'];
};

export type GQLVoucherSource = {
  __typename?: 'VoucherSource';
  key: Scalars['String'];
  label: Scalars['String'];
};

export type GQLVouchersPaginatedList = {
  __typename?: 'VouchersPaginatedList';
  edges: Array<GQLVoucherEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLVouchersRedeemedByStudentQuery = {
  studentId: Scalars['ID'];
};

export type GQLWaitlistComment = {
  __typename?: 'WaitlistComment';
  author: GQLPerson;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type GQLWaitlistCommentInput = {
  text: Scalars['String'];
  waitlistId: Scalars['ID'];
};

export type GQLWaitlistCommentsQuery = {
  waitlistId: Scalars['ID'];
};

export type GQLWaitlistEdge = {
  __typename?: 'WaitlistEdge';
  cursor: Scalars['ID'];
  node: GQLWaitlistStudent;
};

export type GQLWaitlistPaginatedList = {
  __typename?: 'WaitlistPaginatedList';
  edges: Array<GQLWaitlistEdge>;
  pageInfo: GQLPageInfo;
  totalCount: Scalars['Int'];
};

export type GQLWaitlistStudent = {
  __typename?: 'WaitlistStudent';
  availability: Scalars['String'];
  bookingLocation: Scalars['String'];
  comments: Array<GQLWaitlistComment>;
  commentsCount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  expiresAt: Scalars['DateTime'];
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  studentEmail: Scalars['String'];
  studentId?: Maybe<Scalars['ID']>;
  studentName: Scalars['String'];
};

export type GQLWaitlistStudentInput = {
  availability: Scalars['String'];
  bookingLocation: Scalars['String'];
  expiresAt: Scalars['DateTime'];
  id?: InputMaybe<Scalars['ID']>;
  phone?: InputMaybe<Scalars['String']>;
  source: Scalars['String'];
  studentEmail: Scalars['String'];
  studentId?: InputMaybe<Scalars['ID']>;
  studentName: Scalars['String'];
};

export enum GQLWeekDays {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type GQLResolversTypes = ResolversObject<{
  AddMakeUpSessionInformationInput: GQLAddMakeUpSessionInformationInput;
  AddedPurchaseItem: GQLResolversTypes['AddedPurchasePackage'] | GQLResolversTypes['AddedPurchaseProduct'];
  AddedPurchasePackage: ResolverTypeWrapper<Omit<GQLAddedPurchasePackage, 'items'> & { items?: Maybe<Array<GQLResolversTypes['AddedPurchaseItem']>> }>;
  AddedPurchaseProduct: ResolverTypeWrapper<GQLAddedPurchaseProduct>;
  Address: ResolverTypeWrapper<GQLAddress>;
  AddressInput: GQLAddressInput;
  AnvilPdfIds: ResolverTypeWrapper<GQLAnvilPdfIds>;
  Attendance: GQLAttendance;
  AuditLog: ResolverTypeWrapper<Omit<GQLAuditLog, 'changes'> & { changes: Array<GQLResolversTypes['LogChange']> }>;
  AuditLogActions: GQLAuditLogActions;
  AuditLogCollection: ResolverTypeWrapper<GQLAuditLogCollection>;
  AuditLogEdge: ResolverTypeWrapper<GQLAuditLogEdge>;
  AuditLogUserRoles: GQLAuditLogUserRoles;
  AuditLogsPaginatedList: ResolverTypeWrapper<GQLAuditLogsPaginatedList>;
  AuditLogsQuery: GQLAuditLogsQuery;
  AvailabilitiesQuery: GQLAvailabilitiesQuery;
  Availability: ResolverTypeWrapper<StorageOutAvailability>;
  AvailabilityAddFailure: ResolverTypeWrapper<GQLAvailabilityAddFailure>;
  AvailabilityCreateInput: GQLAvailabilityCreateInput;
  AvailabilityInstructorInput: GQLAvailabilityInstructorInput;
  AvailabilityQuery: GQLAvailabilityQuery;
  AvailabilitySlot: ResolverTypeWrapper<GQLAvailabilitySlot>;
  AvailabilitySlotPerDay: ResolverTypeWrapper<GQLAvailabilitySlotPerDay>;
  AvailabilityUpdateFailure: ResolverTypeWrapper<GQLAvailabilityUpdateFailure>;
  AvailabilityUpdateInput: GQLAvailabilityUpdateInput;
  AvailabilityVehicleInput: GQLAvailabilityVehicleInput;
  BalancedQuantity: GQLResolversTypes['ProductBalance'];
  BaseLedgerLog: GQLResolversTypes['LedgerLogCancelLesson'] | GQLResolversTypes['LedgerLogCharge'] | GQLResolversTypes['LedgerLogEditPurchase'] | GQLResolversTypes['LedgerLogExpireProducts'] | GQLResolversTypes['LedgerLogRefund'] | GQLResolversTypes['LedgerLogRemoveStudentFromLesson'] | GQLResolversTypes['LedgerLogScheduleLesson'] | GQLResolversTypes['LedgerLogScheduleMultipleLessons'] | GQLResolversTypes['LedgerLogTransferExpiration'] | GQLResolversTypes['LedgerLogTransferLesson'] | GQLResolversTypes['LedgerLogTransferPurchase'] | GQLResolversTypes['LedgerLogUpdateLesson'] | GQLResolversTypes['LedgerLogVoidExpiration'] | GQLResolversTypes['LedgerLogVoidPurchase'] | GQLResolversTypes['LedgerLogVoidRefund'];
  BatchAddAvailsResult: ResolverTypeWrapper<Omit<GQLBatchAddAvailsResult, 'added'> & { added: Array<GQLResolversTypes['Availability']> }>;
  BatchDeleteAvailsQuery: GQLBatchDeleteAvailsQuery;
  BatchDeleteAvailsResult: ResolverTypeWrapper<GQLBatchDeleteAvailsResult>;
  BatchEditAvailsQuery: GQLBatchEditAvailsQuery;
  BatchEditAvailsResult: ResolverTypeWrapper<Omit<GQLBatchEditAvailsResult, 'updated'> & { updated: Array<GQLResolversTypes['Availability']> }>;
  BatchEditAvailsUpdate: GQLBatchEditAvailsUpdate;
  BookingAlert: ResolverTypeWrapper<GQLBookingAlert>;
  BookingAlertEdge: ResolverTypeWrapper<GQLBookingAlertEdge>;
  BookingAlertInput: GQLBookingAlertInput;
  BookingAlertsPaginatedList: ResolverTypeWrapper<GQLBookingAlertsPaginatedList>;
  BookingAlertsQuery: GQLBookingAlertsQuery;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Cart: ResolverTypeWrapper<StorageOutCart>;
  CartCreateInput: GQLCartCreateInput;
  CartInput: GQLCartInput;
  Center: ResolverTypeWrapper<GQLCenter>;
  CenterInput: GQLCenterInput;
  Certificate: ResolverTypeWrapper<StorageOutCertificate>;
  CertificateEdge: ResolverTypeWrapper<Omit<GQLCertificateEdge, 'node'> & { node: GQLResolversTypes['Certificate'] }>;
  CertificateInput: GQLCertificateInput;
  CertificateType: ResolverTypeWrapper<GQLCertificateType>;
  CertificatesPaginatedList: ResolverTypeWrapper<Omit<GQLCertificatesPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['CertificateEdge']> }>;
  CertificatesQuery: GQLCertificatesQuery;
  ChargeLedgerLog: ResolverTypeWrapper<Omit<GQLChargeLedgerLog, 'items'> & { items: Array<GQLResolversTypes['PurchaseItem']> }>;
  ChargeLedgerLogQueryInput: GQLChargeLedgerLogQueryInput;
  ClaimInput: GQLClaimInput;
  Cohort: ResolverTypeWrapper<GQLCohort>;
  CohortInput: GQLCohortInput;
  CohortSession: ResolverTypeWrapper<GQLCohortSession>;
  CohortSessionInput: GQLCohortSessionInput;
  Collections: GQLCollections;
  CompleteOrderInput: GQLCompleteOrderInput;
  Coupon: ResolverTypeWrapper<StorageOutCoupon>;
  CouponEdge: ResolverTypeWrapper<Omit<GQLCouponEdge, 'node'> & { node: GQLResolversTypes['Coupon'] }>;
  CouponType: GQLCouponType;
  CouponsPaginatedList: ResolverTypeWrapper<Omit<GQLCouponsPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['CouponEdge']> }>;
  CourseCoupon: ResolverTypeWrapper<StorageOutCourseCoupon>;
  CourseCouponInput: GQLCourseCouponInput;
  CourseLecture: ResolverTypeWrapper<StorageOutLecture>;
  CourseLectureContent: ResolverTypeWrapper<Omit<GQLCourseLectureContent, 'parts'> & { parts: Array<GQLResolversTypes['LecturePart']> }>;
  CoursePrepTest: ResolverTypeWrapper<GQLCoursePrepTest>;
  CoursePrepTestChapter: ResolverTypeWrapper<GQLCoursePrepTestChapter>;
  CoursePrepTestChapterScores: ResolverTypeWrapper<GQLCoursePrepTestChapterScores>;
  CourseTest: ResolverTypeWrapper<GQLCourseTest>;
  CourseTestLecture: ResolverTypeWrapper<GQLCourseTestLecture>;
  CourseTopic: ResolverTypeWrapper<Omit<GQLCourseTopic, 'lectures'> & { lectures: Array<GQLResolversTypes['CourseLecture']> }>;
  CoursesQuery: GQLCoursesQuery;
  CreateProductInput: GQLCreateProductInput;
  CreateProductTypeInput: GQLCreateProductTypeInput;
  CreatePurchaseInput: GQLCreatePurchaseInput;
  CreateSessionCookieInput: GQLCreateSessionCookieInput;
  CreateVoucherInput: GQLCreateVoucherInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DeleteBookingAlertInput: GQLDeleteBookingAlertInput;
  DrivingSkill: ResolverTypeWrapper<GQLDrivingSkill>;
  DrivingSkillRating: GQLDrivingSkillRating;
  EditPurchaseInput: GQLEditPurchaseInput;
  EligibleStudent: ResolverTypeWrapper<GQLEligibleStudent>;
  EmailStudentReportInput: GQLEmailStudentReportInput;
  EnrollStudentToCohortInput: GQLEnrollStudentToCohortInput;
  ExistingPurchaseItemUpdate: ResolverTypeWrapper<GQLExistingPurchaseItemUpdate>;
  Expenditure: ResolverTypeWrapper<GQLExpenditure>;
  Expiration: ResolverTypeWrapper<GQLExpiration>;
  ExpirationQuery: GQLExpirationQuery;
  ExpireProductsInput: GQLExpireProductsInput;
  ExpireProductsTypes: GQLExpireProductsTypes;
  FirebaseCourseSessionTime: ResolverTypeWrapper<GQLFirebaseCourseSessionTime>;
  FirebaseLessonReview: ResolverTypeWrapper<GQLFirebaseLessonReview>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  GeoJsonFeature: ResolverTypeWrapper<GQLGeoJsonFeature>;
  GeoJsonFeatureInput: GQLGeoJsonFeatureInput;
  GeoJsonGeometry: ResolverTypeWrapper<GQLGeoJsonGeometry>;
  GeoJsonGeometryInput: GQLGeoJsonGeometryInput;
  GeoJsonProperties: ResolverTypeWrapper<GQLGeoJsonProperties>;
  GeoJsonPropertiesInput: GQLGeoJsonPropertiesInput;
  GetLessonReviewInput: GQLGetLessonReviewInput;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  IDLessonPackageInput: GQLIdLessonPackageInput;
  IdToken: ResolverTypeWrapper<GQLIdToken>;
  ImpersonateUserResponse: ResolverTypeWrapper<GQLImpersonateUserResponse>;
  InitiatedBy: GQLInitiatedBy;
  Instructor: ResolverTypeWrapper<BaseInstructor>;
  InstructorAdminInfo: ResolverTypeWrapper<GQLInstructorAdminInfo>;
  InstructorAlert: ResolverTypeWrapper<GQLInstructorAlert>;
  InstructorAlertCreateInput: GQLInstructorAlertCreateInput;
  InstructorAlertDeleteInput: GQLInstructorAlertDeleteInput;
  InstructorAlertEdge: ResolverTypeWrapper<GQLInstructorAlertEdge>;
  InstructorAlertPaginatedList: ResolverTypeWrapper<GQLInstructorAlertPaginatedList>;
  InstructorAlertSeverity: GQLInstructorAlertSeverity;
  InstructorAlertUpdateInput: GQLInstructorAlertUpdateInput;
  InstructorBadge: ResolverTypeWrapper<GQLInstructorBadge>;
  InstructorBadgeIcon: GQLInstructorBadgeIcon;
  InstructorBadgeInput: GQLInstructorBadgeInput;
  InstructorBadgeQuery: GQLInstructorBadgeQuery;
  InstructorEdge: ResolverTypeWrapper<Omit<GQLInstructorEdge, 'node'> & { node: GQLResolversTypes['Instructor'] }>;
  InstructorLessonUpdateInput: GQLInstructorLessonUpdateInput;
  InstructorOnAvail: ResolverTypeWrapper<GQLInstructorOnAvail>;
  InstructorPrivateInfo: ResolverTypeWrapper<GQLInstructorPrivateInfo>;
  InstructorSchedule: ResolverTypeWrapper<StorageOutInstructorSchedule>;
  InstructorScheduleDayTimes: GQLInstructorScheduleDayTimes;
  InstructorScheduleInput: GQLInstructorScheduleInput;
  InstructorScheduleMutateQuery: GQLInstructorScheduleMutateQuery;
  InstructorScheduleQuery: GQLInstructorScheduleQuery;
  InstructorScheduleUpdateInput: GQLInstructorScheduleUpdateInput;
  InstructorSchedulesQuery: GQLInstructorSchedulesQuery;
  InstructorStatus: GQLInstructorStatus;
  InstructorTimeLog: ResolverTypeWrapper<StorageOutInstructorTimeLog>;
  InstructorTimeLogCreateInput: GQLInstructorTimeLogCreateInput;
  InstructorTimeLogEdge: ResolverTypeWrapper<Omit<GQLInstructorTimeLogEdge, 'node'> & { node: GQLResolversTypes['InstructorTimeLog'] }>;
  InstructorTimeLogListQuery: GQLInstructorTimeLogListQuery;
  InstructorTimeLogPaginatedList: ResolverTypeWrapper<Omit<GQLInstructorTimeLogPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['InstructorTimeLogEdge']> }>;
  InstructorTimeLogQuery: GQLInstructorTimeLogQuery;
  InstructorTimeLogRateType: GQLInstructorTimeLogRateType;
  InstructorTimeLogType: GQLInstructorTimeLogType;
  InstructorTimeLogUpdateInput: GQLInstructorTimeLogUpdateInput;
  InstructorWithSuperRegion: ResolverTypeWrapper<GQLInstructorWithSuperRegion>;
  InstructorZone: ResolverTypeWrapper<StorageOutInstructorZone>;
  InstructorZoneQueryInput: GQLInstructorZoneQueryInput;
  InstructorsCoverage: ResolverTypeWrapper<StorageOutInstructorsCoverage>;
  InstructorsPaginatedList: ResolverTypeWrapper<Omit<GQLInstructorsPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['InstructorEdge']> }>;
  InstructorsQuery: GQLInstructorsQuery;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  ItemInput: GQLItemInput;
  ItemToExpire: ResolverTypeWrapper<GQLItemToExpire>;
  ItemToExpireInput: GQLItemToExpireInput;
  ItemToRefund: ResolverTypeWrapper<GQLItemToRefund>;
  ItemToRefundInput: GQLItemToRefundInput;
  ItemToTransfer: ResolverTypeWrapper<GQLItemToTransfer>;
  ItemToTransferAndExpire: ResolverTypeWrapper<GQLItemToTransferAndExpire>;
  ItemToTransferInput: GQLItemToTransferInput;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']>;
  LastBookedInstructor: ResolverTypeWrapper<GQLLastBookedInstructor>;
  LatLng: ResolverTypeWrapper<GQLLatLng>;
  LectureBlockColumn: GQLResolversTypes['LectureSingleImageBlock'] | GQLResolversTypes['LectureTitleBlock'];
  LectureBlockTextColumn: ResolverTypeWrapper<GQLLectureBlockTextColumn>;
  LectureColumnImage: ResolverTypeWrapper<GQLLectureColumnImage>;
  LectureContentBlock: GQLResolversTypes['LectureSingleImageBlock'] | GQLResolversTypes['LectureSingleVideoBlock'] | GQLResolversTypes['LectureTitleBlock'] | GQLResolversTypes['LectureTwoColumnBlock'];
  LectureContentBlockType: GQLLectureContentBlockType;
  LectureContentImage: ResolverTypeWrapper<GQLLectureContentImage>;
  LectureContentPart: ResolverTypeWrapper<Omit<GQLLectureContentPart, 'blocks'> & { blocks: Array<GQLResolversTypes['LectureContentBlock']> }>;
  LectureContentVideo: ResolverTypeWrapper<GQLLectureContentVideo>;
  LecturePart: GQLResolversTypes['LectureContentPart'] | GQLResolversTypes['LectureQuizPart'];
  LecturePartType: GQLLecturePartType;
  LectureQuestionChoice: ResolverTypeWrapper<GQLLectureQuestionChoice>;
  LectureQuizPart: ResolverTypeWrapper<GQLLectureQuizPart>;
  LectureQuizQuestion: ResolverTypeWrapper<GQLLectureQuizQuestion>;
  LectureQuizQuestionPrepTestScore: ResolverTypeWrapper<GQLLectureQuizQuestionPrepTestScore>;
  LectureQuizQuestionPrepTestScoreInput: GQLLectureQuizQuestionPrepTestScoreInput;
  LectureReport: ResolverTypeWrapper<GQLLectureReport>;
  LectureSessionTime: ResolverTypeWrapper<GQLLectureSessionTime>;
  LectureSingleImageBlock: ResolverTypeWrapper<GQLLectureSingleImageBlock>;
  LectureSingleVideoBlock: ResolverTypeWrapper<GQLLectureSingleVideoBlock>;
  LectureTimeDaily: ResolverTypeWrapper<GQLLectureTimeDaily>;
  LectureTitleBlock: ResolverTypeWrapper<GQLLectureTitleBlock>;
  LectureTwoColumnBlock: ResolverTypeWrapper<Omit<GQLLectureTwoColumnBlock, 'column1'> & { column1: Array<GQLResolversTypes['LectureBlockColumn']> }>;
  LedgerLog: ResolverTypeWrapper<StorageOutLedgerLog>;
  LedgerLogCancelLesson: ResolverTypeWrapper<GQLLedgerLogCancelLesson>;
  LedgerLogCharge: ResolverTypeWrapper<StorageOutLedgerLogCharge>;
  LedgerLogEditPurchase: ResolverTypeWrapper<Omit<GQLLedgerLogEditPurchase, 'itemsUpdates'> & { itemsUpdates?: Maybe<Array<GQLResolversTypes['PurchaseItemUpdate']>> }>;
  LedgerLogExpireProducts: ResolverTypeWrapper<StorageOutLedgerLogExpireProducts>;
  LedgerLogRefund: ResolverTypeWrapper<StorageOutLedgerLogRefund>;
  LedgerLogRemoveStudentFromLesson: ResolverTypeWrapper<StorageOutLedgerLogRemoveStudentFromLesson>;
  LedgerLogScheduleLesson: ResolverTypeWrapper<StorageOutLedgerLogScheduleLesson>;
  LedgerLogScheduleMultipleLessons: ResolverTypeWrapper<GQLLedgerLogScheduleMultipleLessons>;
  LedgerLogTransferExpiration: ResolverTypeWrapper<GQLLedgerLogTransferExpiration>;
  LedgerLogTransferLesson: ResolverTypeWrapper<GQLLedgerLogTransferLesson>;
  LedgerLogTransferPurchase: ResolverTypeWrapper<GQLLedgerLogTransferPurchase>;
  LedgerLogTypes: GQLLedgerLogTypes;
  LedgerLogUpdateLesson: ResolverTypeWrapper<GQLLedgerLogUpdateLesson>;
  LedgerLogVoidExpiration: ResolverTypeWrapper<StorageOutLedgerLogVoidExpiration>;
  LedgerLogVoidPurchase: ResolverTypeWrapper<StorageOutLedgerLogVoidPurchase>;
  LedgerLogVoidRefund: ResolverTypeWrapper<StorageOutLedgerLogVoidRefund>;
  LedgerLogsEdge: ResolverTypeWrapper<Omit<GQLLedgerLogsEdge, 'node'> & { node: GQLResolversTypes['LedgerLog'] }>;
  LedgerLogsPaginatedList: ResolverTypeWrapper<Omit<GQLLedgerLogsPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['LedgerLogsEdge']> }>;
  LedgerLogsQuery: GQLLedgerLogsQuery;
  LegacyLogSummary: ResolverTypeWrapper<GQLLegacyLogSummary>;
  Lesson: ResolverTypeWrapper<StorageOutLesson>;
  LessonCancelQuery: GQLLessonCancelQuery;
  LessonCancellationInfo: GQLLessonCancellationInfo;
  LessonCoupon: ResolverTypeWrapper<StorageOutLessonCoupon>;
  LessonCouponInput: GQLLessonCouponInput;
  LessonDrivingRatingChange: GQLLessonDrivingRatingChange;
  LessonDrivingSkillRating: ResolverTypeWrapper<StorageOutLessonDrivingSkillRating>;
  LessonDrivingSkillRatingInput: GQLLessonDrivingSkillRatingInput;
  LessonDrivingSkillsReport: ResolverTypeWrapper<StorageOutLessonDrivingSkillsReport>;
  LessonDrivingSkillsReportEdge: ResolverTypeWrapper<Omit<GQLLessonDrivingSkillsReportEdge, 'node'> & { node: GQLResolversTypes['LessonDrivingSkillsReport'] }>;
  LessonDrivingSkillsReportPaginatedList: ResolverTypeWrapper<Omit<GQLLessonDrivingSkillsReportPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['LessonDrivingSkillsReportEdge']> }>;
  LessonEdge: ResolverTypeWrapper<Omit<GQLLessonEdge, 'node'> & { node: GQLResolversTypes['Lesson'] }>;
  LessonPackage: ResolverTypeWrapper<GQLLessonPackage>;
  LessonPackageInput: GQLLessonPackageInput;
  LessonQuery: GQLLessonQuery;
  LessonRemoveStudentQuery: GQLLessonRemoveStudentQuery;
  LessonReview: ResolverTypeWrapper<StorageOutLessonReview>;
  LessonReviewCreateInput: GQLLessonReviewCreateInput;
  LessonReviewEdge: ResolverTypeWrapper<Omit<GQLLessonReviewEdge, 'node'> & { node: GQLResolversTypes['LessonReview'] }>;
  LessonStudentDetails: ResolverTypeWrapper<StorageOutLessonStudentDetails>;
  LessonStudentDetailsInput: GQLLessonStudentDetailsInput;
  LessonStudentDetailsUpdateInput: GQLLessonStudentDetailsUpdateInput;
  LessonType: ResolverTypeWrapper<GQLLessonType>;
  LessonTypeCreateInput: GQLLessonTypeCreateInput;
  LessonTypeEdge: ResolverTypeWrapper<GQLLessonTypeEdge>;
  LessonTypeFeature: ResolverTypeWrapper<GQLLessonTypeFeature>;
  LessonTypeFeaturesInput: GQLLessonTypeFeaturesInput;
  LessonTypeForStateConfigInput: GQLLessonTypeForStateConfigInput;
  LessonTypeUpdateInput: GQLLessonTypeUpdateInput;
  LessonTypesPaginatedList: ResolverTypeWrapper<GQLLessonTypesPaginatedList>;
  LessonUpdateAddStudent: GQLLessonUpdateAddStudent;
  LessonUpdateChangeLessonDetails: GQLLessonUpdateChangeLessonDetails;
  LessonUpdateChangeStudentDetails: GQLLessonUpdateChangeStudentDetails;
  LessonUpdateInput: GQLLessonUpdateInput;
  LessonUpdateQuery: GQLLessonUpdateQuery;
  LessonsDrivingSkillsReportsQuery: GQLLessonsDrivingSkillsReportsQuery;
  LessonsPaginatedList: ResolverTypeWrapper<Omit<GQLLessonsPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['LessonEdge']> }>;
  LessonsQuery: GQLLessonsQuery;
  LessonsQueryByInstructorIds: GQLLessonsQueryByInstructorIds;
  LessonsReviewsByInstructorQuery: GQLLessonsReviewsByInstructorQuery;
  LessonsReviewsPaginatedList: ResolverTypeWrapper<Omit<GQLLessonsReviewsPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['LessonReviewEdge']> }>;
  Location: ResolverTypeWrapper<GQLLocation>;
  LogChange: GQLResolversTypes['LogDateTimeChange'] | GQLResolversTypes['LogFloatChange'] | GQLResolversTypes['LogIntChange'] | GQLResolversTypes['LogStringChange'];
  LogChangeBase: GQLResolversTypes['LogDateTimeChange'] | GQLResolversTypes['LogFloatChange'] | GQLResolversTypes['LogIntChange'] | GQLResolversTypes['LogStringChange'];
  LogChangeValueType: GQLLogChangeValueType;
  LogDateTimeChange: ResolverTypeWrapper<GQLLogDateTimeChange>;
  LogFloatChange: ResolverTypeWrapper<GQLLogFloatChange>;
  LogIntChange: ResolverTypeWrapper<GQLLogIntChange>;
  LogStringChange: ResolverTypeWrapper<GQLLogStringChange>;
  LogTypes: GQLLogTypes;
  MigrateOnlineCourseInput: GQLMigrateOnlineCourseInput;
  MigrateStudentAccountInput: GQLMigrateStudentAccountInput;
  MultipleAvailabilityCreateInput: GQLMultipleAvailabilityCreateInput;
  Mutation: ResolverTypeWrapper<{}>;
  Myself: ResolverTypeWrapper<GQLMyself>;
  NewInstructorInput: GQLNewInstructorInput;
  NewStudentInput: GQLNewStudentInput;
  Note: ResolverTypeWrapper<GQLNote>;
  NoteEdge: ResolverTypeWrapper<GQLNoteEdge>;
  NoteInput: GQLNoteInput;
  NotesPaginatedList: ResolverTypeWrapper<GQLNotesPaginatedList>;
  NotesQuery: GQLNotesQuery;
  OnlineCourseContent: ResolverTypeWrapper<StorageOutCourseContent>;
  PackagePrice: ResolverTypeWrapper<GQLPackagePrice>;
  PackagePriceInput: GQLPackagePriceInput;
  PackageWithPrices: ResolverTypeWrapper<GQLPackageWithPrices>;
  PackageWithPricesInput: GQLPackageWithPricesInput;
  PageInfo: ResolverTypeWrapper<GQLPageInfo>;
  PageQuery: GQLPageQuery;
  PaymentCategories: GQLPaymentCategories;
  PaymentStatusInput: GQLPaymentStatusInput;
  Person: ResolverTypeWrapper<GQLPerson>;
  PersonEdge: ResolverTypeWrapper<GQLPersonEdge>;
  PersonPaginatedList: ResolverTypeWrapper<GQLPersonPaginatedList>;
  PriceScheme: ResolverTypeWrapper<StorageOutPriceScheme>;
  PriceSchemeCreateInput: GQLPriceSchemeCreateInput;
  PriceSchemeEdge: ResolverTypeWrapper<Omit<GQLPriceSchemeEdge, 'node'> & { node: GQLResolversTypes['PriceScheme'] }>;
  PriceSchemeFragment: ResolverTypeWrapper<StorageOutPriceSchemeFragment>;
  PriceSchemeFragmentCreateInput: GQLPriceSchemeFragmentCreateInput;
  PriceSchemeFragmentEdge: ResolverTypeWrapper<Omit<GQLPriceSchemeFragmentEdge, 'node'> & { node: GQLResolversTypes['PriceSchemeFragment'] }>;
  PriceSchemeFragmentPaginatedList: ResolverTypeWrapper<Omit<GQLPriceSchemeFragmentPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['PriceSchemeFragmentEdge']> }>;
  PriceSchemeFragmentUpdateInput: GQLPriceSchemeFragmentUpdateInput;
  PriceSchemeFragmentsQuery: GQLPriceSchemeFragmentsQuery;
  PriceSchemeInfo: ResolverTypeWrapper<GQLPriceSchemeInfo>;
  PriceSchemePaginatedList: ResolverTypeWrapper<Omit<GQLPriceSchemePaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['PriceSchemeEdge']> }>;
  PriceSchemeProductLink: ResolverTypeWrapper<StorageOutPriceSchemeProductLink>;
  PriceSchemeUpdateInput: GQLPriceSchemeUpdateInput;
  PriceSchemesQuery: GQLPriceSchemesQuery;
  Product: ResolverTypeWrapper<StorageOutProduct>;
  ProductBalance: ResolverTypeWrapper<GQLProductBalance>;
  ProductEdge: ResolverTypeWrapper<Omit<GQLProductEdge, 'node'> & { node: GQLResolversTypes['Product'] }>;
  ProductLink: ResolverTypeWrapper<StorageOutProductLink>;
  ProductLinkInput: GQLProductLinkInput;
  ProductPrice: ResolverTypeWrapper<StorageOutProductPrice>;
  ProductPriceAlgo: GQLResolversTypes['ProductPriceAlgoDollarDiscount'] | GQLResolversTypes['ProductPriceAlgoDollarThenPercentDiscount'] | GQLResolversTypes['ProductPriceAlgoFixed'] | GQLResolversTypes['ProductPriceAlgoPercentDiscount'] | GQLResolversTypes['ProductPriceAlgoPercentThenDollarDiscount'];
  ProductPriceAlgoBase: GQLResolversTypes['ProductPriceAlgoDollarDiscount'] | GQLResolversTypes['ProductPriceAlgoDollarThenPercentDiscount'] | GQLResolversTypes['ProductPriceAlgoFixed'] | GQLResolversTypes['ProductPriceAlgoPercentDiscount'] | GQLResolversTypes['ProductPriceAlgoPercentThenDollarDiscount'];
  ProductPriceAlgoDollarDiscount: ResolverTypeWrapper<GQLProductPriceAlgoDollarDiscount>;
  ProductPriceAlgoDollarDiscountInput: GQLProductPriceAlgoDollarDiscountInput;
  ProductPriceAlgoDollarThenPercentDiscount: ResolverTypeWrapper<GQLProductPriceAlgoDollarThenPercentDiscount>;
  ProductPriceAlgoDollarThenPercentDiscountInput: GQLProductPriceAlgoDollarThenPercentDiscountInput;
  ProductPriceAlgoFixed: ResolverTypeWrapper<GQLProductPriceAlgoFixed>;
  ProductPriceAlgoFixedPriceInput: GQLProductPriceAlgoFixedPriceInput;
  ProductPriceAlgoInput: GQLProductPriceAlgoInput;
  ProductPriceAlgoPercentDiscount: ResolverTypeWrapper<GQLProductPriceAlgoPercentDiscount>;
  ProductPriceAlgoPercentDiscountInput: GQLProductPriceAlgoPercentDiscountInput;
  ProductPriceAlgoPercentThenDollarDiscount: ResolverTypeWrapper<GQLProductPriceAlgoPercentThenDollarDiscount>;
  ProductPriceAlgoPercentThenDollarDiscountInput: GQLProductPriceAlgoPercentThenDollarDiscountInput;
  ProductPriceAlgoType: GQLProductPriceAlgoType;
  ProductPriceInput: GQLProductPriceInput;
  ProductPriceRounding: ResolverTypeWrapper<GQLProductPriceRounding>;
  ProductPriceRoundingInput: GQLProductPriceRoundingInput;
  ProductPriceRoundingType: GQLProductPriceRoundingType;
  ProductQuantity: ResolverTypeWrapper<GQLProductQuantity>;
  ProductQuantityInput: GQLProductQuantityInput;
  ProductType: GQLProductType;
  ProductsPaginatedList: ResolverTypeWrapper<Omit<GQLProductsPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['ProductEdge']> }>;
  ProductsQuery: GQLProductsQuery;
  Purchase: ResolverTypeWrapper<Omit<GQLPurchase, 'items'> & { items: Array<GQLResolversTypes['AddedPurchaseItem']> }>;
  PurchaseItem: GQLResolversTypes['PurchasePackageItem'] | GQLResolversTypes['PurchaseProductItem'];
  PurchaseItemInput: GQLPurchaseItemInput;
  PurchaseItemUpdate: GQLResolversTypes['AddedPurchasePackage'] | GQLResolversTypes['AddedPurchaseProduct'] | GQLResolversTypes['ExistingPurchaseItemUpdate'];
  PurchasePackageItem: ResolverTypeWrapper<GQLPurchasePackageItem>;
  PurchaseProductItem: ResolverTypeWrapper<GQLPurchaseProductItem>;
  PurchaseQuery: GQLPurchaseQuery;
  PutLessonReviewInput: GQLPutLessonReviewInput;
  PutTestLessonInput: GQLPutTestLessonInput;
  PutTestProductInput: GQLPutTestProductInput;
  PutTestRegionsInput: GQLPutTestRegionsInput;
  QuantityByIndicator: ResolverTypeWrapper<GQLQuantityByIndicator>;
  Query: ResolverTypeWrapper<{}>;
  RedeemVoucherResult: ResolverTypeWrapper<StorageOutRedeemVoucherResult>;
  ReferenceItem: ResolverTypeWrapper<GQLReferenceItem>;
  Refund: ResolverTypeWrapper<GQLRefund>;
  RefundPurchaseInput: GQLRefundPurchaseInput;
  RefundQuery: GQLRefundQuery;
  Region: ResolverTypeWrapper<StorageOutRegion>;
  RegionEdge: ResolverTypeWrapper<Omit<GQLRegionEdge, 'node'> & { node: GQLResolversTypes['Region'] }>;
  RegionPaginatedList: ResolverTypeWrapper<Omit<GQLRegionPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['RegionEdge']> }>;
  RegionScheme: ResolverTypeWrapper<StorageOutRegionScheme>;
  RegionSchemeEdge: ResolverTypeWrapper<Omit<GQLRegionSchemeEdge, 'node'> & { node: GQLResolversTypes['RegionScheme'] }>;
  RegionSchemePaginatedList: ResolverTypeWrapper<Omit<GQLRegionSchemePaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['RegionSchemeEdge']> }>;
  RegionSchemeQuery: GQLRegionSchemeQuery;
  RegionSchemeUpdateInput: GQLRegionSchemeUpdateInput;
  RegionZones: ResolverTypeWrapper<GQLRegionZones>;
  RegionalManagersQuery: GQLRegionalManagersQuery;
  ScheduleDayTimes: ResolverTypeWrapper<GQLScheduleDayTimes>;
  ScheduleLessonInput: GQLScheduleLessonInput;
  SchedulerQueryInput: GQLSchedulerQueryInput;
  ScholarshipApplicationResponse: ResolverTypeWrapper<GQLScholarshipApplicationResponse>;
  SelfBookSharedLessonInput: GQLSelfBookSharedLessonInput;
  SetCouponInput: GQLSetCouponInput;
  SetStudentInput: GQLSetStudentInput;
  SharedLesson: ResolverTypeWrapper<StorageOutSharedLesson>;
  SharedLessonsQuery: GQLSharedLessonsQuery;
  SignInWithEmailInput: GQLSignInWithEmailInput;
  SortDirection: GQLSortDirection;
  SortKeyDirectionPair: GQLSortKeyDirectionPair;
  StateConfig: ResolverTypeWrapper<StorageOutStateConfig>;
  StateConfigCreateInput: GQLStateConfigCreateInput;
  StateConfigEdge: ResolverTypeWrapper<Omit<GQLStateConfigEdge, 'node'> & { node: GQLResolversTypes['StateConfig'] }>;
  StateConfigPaginatedList: ResolverTypeWrapper<Omit<GQLStateConfigPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['StateConfigEdge']> }>;
  StateConfigUpdateInput: GQLStateConfigUpdateInput;
  StateMeetingPoint: ResolverTypeWrapper<GQLStateMeetingPoint>;
  StateMeetingPointCreateInput: GQLStateMeetingPointCreateInput;
  StateMeetingPointEdge: ResolverTypeWrapper<GQLStateMeetingPointEdge>;
  StateMeetingPointPaginatedList: ResolverTypeWrapper<GQLStateMeetingPointPaginatedList>;
  StateMeetingPointUpdateInput: GQLStateMeetingPointUpdateInput;
  StateMeetingPointsQuery: GQLStateMeetingPointsQuery;
  String: ResolverTypeWrapper<Scalars['String']>;
  Student: ResolverTypeWrapper<StorageOutStudent>;
  StudentBalance: ResolverTypeWrapper<GQLStudentBalance>;
  StudentContractStatus: GQLStudentContractStatus;
  StudentCourseMigration: ResolverTypeWrapper<GQLStudentCourseMigration>;
  StudentEdge: ResolverTypeWrapper<Omit<GQLStudentEdge, 'node'> & { node: GQLResolversTypes['Student'] }>;
  StudentId: ResolverTypeWrapper<GQLStudentId>;
  StudentIdProductId: ResolverTypeWrapper<GQLStudentIdProductId>;
  StudentInput: GQLStudentInput;
  StudentLegalAndPermissions: ResolverTypeWrapper<GQLStudentLegalAndPermissions>;
  StudentLegalAndPermissionsInput: GQLStudentLegalAndPermissionsInput;
  StudentObject: ResolverTypeWrapper<GQLStudentObject>;
  StudentOnlineCourse: ResolverTypeWrapper<GQLStudentOnlineCourse>;
  StudentOnlineCourseEdge: ResolverTypeWrapper<GQLStudentOnlineCourseEdge>;
  StudentOnlineCourseInput: GQLStudentOnlineCourseInput;
  StudentOnlineCourseLecture: ResolverTypeWrapper<GQLStudentOnlineCourseLecture>;
  StudentOnlineCourseReport: ResolverTypeWrapper<GQLStudentOnlineCourseReport>;
  StudentOnlineCourseTest: ResolverTypeWrapper<GQLStudentOnlineCourseTest>;
  StudentOnlineCourseTimeProgress: ResolverTypeWrapper<GQLStudentOnlineCourseTimeProgress>;
  StudentOnlineCoursesPaginatedList: ResolverTypeWrapper<GQLStudentOnlineCoursesPaginatedList>;
  StudentSessionAttendance: ResolverTypeWrapper<GQLStudentSessionAttendance>;
  StudentTransferStatus: GQLStudentTransferStatus;
  StudentTransferType: GQLStudentTransferType;
  StudentsPaginatedList: ResolverTypeWrapper<Omit<GQLStudentsPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['StudentEdge']> }>;
  StudentsQuery: GQLStudentsQuery;
  SubItemInput: GQLSubItemInput;
  SubmitCoursePrepTestChapterScoresInput: GQLSubmitCoursePrepTestChapterScoresInput;
  SuperRegion: ResolverTypeWrapper<StorageOutSuperRegion>;
  SuperRegionCreateInput: GQLSuperRegionCreateInput;
  SuperRegionEdge: ResolverTypeWrapper<Omit<GQLSuperRegionEdge, 'node'> & { node: GQLResolversTypes['SuperRegion'] }>;
  SuperRegionPaginatedList: ResolverTypeWrapper<Omit<GQLSuperRegionPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['SuperRegionEdge']> }>;
  SuperRegionQuery: GQLSuperRegionQuery;
  SuperRegionUpdateInput: GQLSuperRegionUpdateInput;
  SyncCheck: ResolverTypeWrapper<StorageOutSyncCheck>;
  SyncCheckEdge: ResolverTypeWrapper<Omit<GQLSyncCheckEdge, 'node'> & { node: GQLResolversTypes['SyncCheck'] }>;
  SyncCheckError: ResolverTypeWrapper<GQLSyncCheckError>;
  SyncCheckLogTypes: GQLSyncCheckLogTypes;
  SyncChecksPaginatedList: ResolverTypeWrapper<Omit<GQLSyncChecksPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['SyncCheckEdge']> }>;
  SyncConflict: ResolverTypeWrapper<StorageOutSyncConflict>;
  SyncConflictDayBreakdown: ResolverTypeWrapper<Omit<GQLSyncConflictDayBreakdown, 'nextLogs'> & { nextLogs: Array<GQLResolversTypes['LedgerLog']> }>;
  SyncConflictDetails: ResolverTypeWrapper<Omit<GQLSyncConflictDetails, 'dayByDayBreakdown'> & { dayByDayBreakdown: Array<GQLResolversTypes['SyncConflictDayBreakdown']> }>;
  SyncConflictEdge: ResolverTypeWrapper<Omit<GQLSyncConflictEdge, 'node'> & { node: GQLResolversTypes['SyncConflict'] }>;
  SyncConflictsPaginatedList: ResolverTypeWrapper<StorageOutSyncConflictsPaginatedList>;
  SyncConflictsQuery: GQLSyncConflictsQuery;
  SyncIndicator: GQLSyncIndicator;
  SyncStudentExpiredMinutesConflict: ResolverTypeWrapper<Omit<GQLSyncStudentExpiredMinutesConflict, 'details'> & { details: GQLResolversTypes['SyncConflictDetails'] }>;
  SyncStudentExpiredMinutesEntityValue: ResolverTypeWrapper<GQLSyncStudentExpiredMinutesEntityValue>;
  SyncStudentFundsBalanceConflict: ResolverTypeWrapper<Omit<GQLSyncStudentFundsBalanceConflict, 'details'> & { details: GQLResolversTypes['SyncConflictDetails'] }>;
  SyncStudentFundsBalanceConflictEntityValue: ResolverTypeWrapper<GQLSyncStudentFundsBalanceConflictEntityValue>;
  SyncStudentProductBalanceConflict: ResolverTypeWrapper<Omit<GQLSyncStudentProductBalanceConflict, 'details'> & { details: GQLResolversTypes['SyncConflictDetails'] }>;
  SyncStudentProductBalanceConflictEntityValue: ResolverTypeWrapper<GQLSyncStudentProductBalanceConflictEntityValue>;
  TestChargeLedgerLogInput: GQLTestChargeLedgerLogInput;
  TestUserInput: GQLTestUserInput;
  TransferAccountCheckResult: ResolverTypeWrapper<GQLTransferAccountCheckResult>;
  TransferAccountInput: GQLTransferAccountInput;
  TransferExpirationInput: GQLTransferExpirationInput;
  TransferLessonInput: GQLTransferLessonInput;
  TransferPurchaseInput: GQLTransferPurchaseInput;
  TransientSlot: ResolverTypeWrapper<StorageOutTransientSlot>;
  TransientSlotInput: GQLTransientSlotInput;
  TransientSlotsQuery: GQLTransientSlotsQuery;
  USState: ResolverTypeWrapper<GQLUsState>;
  USStateId: GQLUsStateId;
  UnenrollStudentFromCohortInput: GQLUnenrollStudentFromCohortInput;
  UpdateCourseSessionInput: GQLUpdateCourseSessionInput;
  UpdateInstructorInput: GQLUpdateInstructorInput;
  UpdateInstructorQuery: GQLUpdateInstructorQuery;
  UpdateProductInput: GQLUpdateProductInput;
  UpdateStudentCourseMetaInput: GQLUpdateStudentCourseMetaInput;
  UpdateStudentSessionAttendanceInput: GQLUpdateStudentSessionAttendanceInput;
  UpdateStudentsSessionAttendanceInput: GQLUpdateStudentsSessionAttendanceInput;
  UpdateVoucherInput: GQLUpdateVoucherInput;
  UpdatedBy: ResolverTypeWrapper<GQLUpdatedBy>;
  UpsertCouponInput: GQLUpsertCouponInput;
  UserLog: ResolverTypeWrapper<Omit<GQLUserLog, 'changes' | 'instructor'> & { changes: Array<GQLResolversTypes['LogChange']>, instructor?: Maybe<GQLResolversTypes['Instructor']> }>;
  UserLogEdge: ResolverTypeWrapper<Omit<GQLUserLogEdge, 'node'> & { node: GQLResolversTypes['UserLog'] }>;
  UserLogsPaginatedList: ResolverTypeWrapper<Omit<GQLUserLogsPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['UserLogEdge']> }>;
  UserLogsQuery: GQLUserLogsQuery;
  UserRole: GQLUserRole;
  Vehicle: ResolverTypeWrapper<Omit<GQLVehicle, 'instructors'> & { instructors?: Maybe<Array<GQLResolversTypes['Instructor']>> }>;
  VehicleAvail: ResolverTypeWrapper<GQLVehicleAvail>;
  VehicleAvailability: ResolverTypeWrapper<GQLVehicleAvailability>;
  VehicleAvailabilityQuery: GQLVehicleAvailabilityQuery;
  VehicleEdge: ResolverTypeWrapper<Omit<GQLVehicleEdge, 'node'> & { node: GQLResolversTypes['Vehicle'] }>;
  VehicleInput: GQLVehicleInput;
  VehicleInstructorFields: ResolverTypeWrapper<GQLVehicleInstructorFields>;
  VehicleMapAvailability: ResolverTypeWrapper<GQLVehicleMapAvailability>;
  VehicleMapAvailabilityQuery: GQLVehicleMapAvailabilityQuery;
  VehiclesPaginatedList: ResolverTypeWrapper<Omit<GQLVehiclesPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['VehicleEdge']> }>;
  VoidExpirationInput: GQLVoidExpirationInput;
  VoidPurchaseInput: GQLVoidPurchaseInput;
  VoidRefundInput: GQLVoidRefundInput;
  Voucher: ResolverTypeWrapper<StorageOutVoucher>;
  VoucherEdge: ResolverTypeWrapper<Omit<GQLVoucherEdge, 'node'> & { node: GQLResolversTypes['Voucher'] }>;
  VoucherRedeemInput: GQLVoucherRedeemInput;
  VoucherSource: ResolverTypeWrapper<GQLVoucherSource>;
  VouchersPaginatedList: ResolverTypeWrapper<Omit<GQLVouchersPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['VoucherEdge']> }>;
  VouchersRedeemedByStudentQuery: GQLVouchersRedeemedByStudentQuery;
  WaitlistComment: ResolverTypeWrapper<GQLWaitlistComment>;
  WaitlistCommentInput: GQLWaitlistCommentInput;
  WaitlistCommentsQuery: GQLWaitlistCommentsQuery;
  WaitlistEdge: ResolverTypeWrapper<Omit<GQLWaitlistEdge, 'node'> & { node: GQLResolversTypes['WaitlistStudent'] }>;
  WaitlistPaginatedList: ResolverTypeWrapper<Omit<GQLWaitlistPaginatedList, 'edges'> & { edges: Array<GQLResolversTypes['WaitlistEdge']> }>;
  WaitlistStudent: ResolverTypeWrapper<StorageOutWaitlist>;
  WaitlistStudentInput: GQLWaitlistStudentInput;
  WeekDays: GQLWeekDays;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type GQLResolversParentTypes = ResolversObject<{
  AddMakeUpSessionInformationInput: GQLAddMakeUpSessionInformationInput;
  AddedPurchaseItem: GQLResolversParentTypes['AddedPurchasePackage'] | GQLResolversParentTypes['AddedPurchaseProduct'];
  AddedPurchasePackage: Omit<GQLAddedPurchasePackage, 'items'> & { items?: Maybe<Array<GQLResolversParentTypes['AddedPurchaseItem']>> };
  AddedPurchaseProduct: GQLAddedPurchaseProduct;
  Address: GQLAddress;
  AddressInput: GQLAddressInput;
  AnvilPdfIds: GQLAnvilPdfIds;
  AuditLog: Omit<GQLAuditLog, 'changes'> & { changes: Array<GQLResolversParentTypes['LogChange']> };
  AuditLogCollection: GQLAuditLogCollection;
  AuditLogEdge: GQLAuditLogEdge;
  AuditLogsPaginatedList: GQLAuditLogsPaginatedList;
  AuditLogsQuery: GQLAuditLogsQuery;
  AvailabilitiesQuery: GQLAvailabilitiesQuery;
  Availability: StorageOutAvailability;
  AvailabilityAddFailure: GQLAvailabilityAddFailure;
  AvailabilityCreateInput: GQLAvailabilityCreateInput;
  AvailabilityInstructorInput: GQLAvailabilityInstructorInput;
  AvailabilityQuery: GQLAvailabilityQuery;
  AvailabilitySlot: GQLAvailabilitySlot;
  AvailabilitySlotPerDay: GQLAvailabilitySlotPerDay;
  AvailabilityUpdateFailure: GQLAvailabilityUpdateFailure;
  AvailabilityUpdateInput: GQLAvailabilityUpdateInput;
  AvailabilityVehicleInput: GQLAvailabilityVehicleInput;
  BalancedQuantity: GQLResolversParentTypes['ProductBalance'];
  BaseLedgerLog: GQLResolversParentTypes['LedgerLogCancelLesson'] | GQLResolversParentTypes['LedgerLogCharge'] | GQLResolversParentTypes['LedgerLogEditPurchase'] | GQLResolversParentTypes['LedgerLogExpireProducts'] | GQLResolversParentTypes['LedgerLogRefund'] | GQLResolversParentTypes['LedgerLogRemoveStudentFromLesson'] | GQLResolversParentTypes['LedgerLogScheduleLesson'] | GQLResolversParentTypes['LedgerLogScheduleMultipleLessons'] | GQLResolversParentTypes['LedgerLogTransferExpiration'] | GQLResolversParentTypes['LedgerLogTransferLesson'] | GQLResolversParentTypes['LedgerLogTransferPurchase'] | GQLResolversParentTypes['LedgerLogUpdateLesson'] | GQLResolversParentTypes['LedgerLogVoidExpiration'] | GQLResolversParentTypes['LedgerLogVoidPurchase'] | GQLResolversParentTypes['LedgerLogVoidRefund'];
  BatchAddAvailsResult: Omit<GQLBatchAddAvailsResult, 'added'> & { added: Array<GQLResolversParentTypes['Availability']> };
  BatchDeleteAvailsQuery: GQLBatchDeleteAvailsQuery;
  BatchDeleteAvailsResult: GQLBatchDeleteAvailsResult;
  BatchEditAvailsQuery: GQLBatchEditAvailsQuery;
  BatchEditAvailsResult: Omit<GQLBatchEditAvailsResult, 'updated'> & { updated: Array<GQLResolversParentTypes['Availability']> };
  BatchEditAvailsUpdate: GQLBatchEditAvailsUpdate;
  BookingAlert: GQLBookingAlert;
  BookingAlertEdge: GQLBookingAlertEdge;
  BookingAlertInput: GQLBookingAlertInput;
  BookingAlertsPaginatedList: GQLBookingAlertsPaginatedList;
  BookingAlertsQuery: GQLBookingAlertsQuery;
  Boolean: Scalars['Boolean'];
  Cart: StorageOutCart;
  CartCreateInput: GQLCartCreateInput;
  CartInput: GQLCartInput;
  Center: GQLCenter;
  CenterInput: GQLCenterInput;
  Certificate: StorageOutCertificate;
  CertificateEdge: Omit<GQLCertificateEdge, 'node'> & { node: GQLResolversParentTypes['Certificate'] };
  CertificateInput: GQLCertificateInput;
  CertificateType: GQLCertificateType;
  CertificatesPaginatedList: Omit<GQLCertificatesPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['CertificateEdge']> };
  CertificatesQuery: GQLCertificatesQuery;
  ChargeLedgerLog: Omit<GQLChargeLedgerLog, 'items'> & { items: Array<GQLResolversParentTypes['PurchaseItem']> };
  ChargeLedgerLogQueryInput: GQLChargeLedgerLogQueryInput;
  ClaimInput: GQLClaimInput;
  Cohort: GQLCohort;
  CohortInput: GQLCohortInput;
  CohortSession: GQLCohortSession;
  CohortSessionInput: GQLCohortSessionInput;
  CompleteOrderInput: GQLCompleteOrderInput;
  Coupon: StorageOutCoupon;
  CouponEdge: Omit<GQLCouponEdge, 'node'> & { node: GQLResolversParentTypes['Coupon'] };
  CouponsPaginatedList: Omit<GQLCouponsPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['CouponEdge']> };
  CourseCoupon: StorageOutCourseCoupon;
  CourseCouponInput: GQLCourseCouponInput;
  CourseLecture: StorageOutLecture;
  CourseLectureContent: Omit<GQLCourseLectureContent, 'parts'> & { parts: Array<GQLResolversParentTypes['LecturePart']> };
  CoursePrepTest: GQLCoursePrepTest;
  CoursePrepTestChapter: GQLCoursePrepTestChapter;
  CoursePrepTestChapterScores: GQLCoursePrepTestChapterScores;
  CourseTest: GQLCourseTest;
  CourseTestLecture: GQLCourseTestLecture;
  CourseTopic: Omit<GQLCourseTopic, 'lectures'> & { lectures: Array<GQLResolversParentTypes['CourseLecture']> };
  CoursesQuery: GQLCoursesQuery;
  CreateProductInput: GQLCreateProductInput;
  CreatePurchaseInput: GQLCreatePurchaseInput;
  CreateSessionCookieInput: GQLCreateSessionCookieInput;
  CreateVoucherInput: GQLCreateVoucherInput;
  DateTime: Scalars['DateTime'];
  DeleteBookingAlertInput: GQLDeleteBookingAlertInput;
  DrivingSkill: GQLDrivingSkill;
  EditPurchaseInput: GQLEditPurchaseInput;
  EligibleStudent: GQLEligibleStudent;
  EmailStudentReportInput: GQLEmailStudentReportInput;
  EnrollStudentToCohortInput: GQLEnrollStudentToCohortInput;
  ExistingPurchaseItemUpdate: GQLExistingPurchaseItemUpdate;
  Expenditure: GQLExpenditure;
  Expiration: GQLExpiration;
  ExpirationQuery: GQLExpirationQuery;
  ExpireProductsInput: GQLExpireProductsInput;
  FirebaseCourseSessionTime: GQLFirebaseCourseSessionTime;
  FirebaseLessonReview: GQLFirebaseLessonReview;
  Float: Scalars['Float'];
  GeoJsonFeature: GQLGeoJsonFeature;
  GeoJsonFeatureInput: GQLGeoJsonFeatureInput;
  GeoJsonGeometry: GQLGeoJsonGeometry;
  GeoJsonGeometryInput: GQLGeoJsonGeometryInput;
  GeoJsonProperties: GQLGeoJsonProperties;
  GeoJsonPropertiesInput: GQLGeoJsonPropertiesInput;
  GetLessonReviewInput: GQLGetLessonReviewInput;
  ID: Scalars['ID'];
  IDLessonPackageInput: GQLIdLessonPackageInput;
  IdToken: GQLIdToken;
  ImpersonateUserResponse: GQLImpersonateUserResponse;
  Instructor: BaseInstructor;
  InstructorAdminInfo: GQLInstructorAdminInfo;
  InstructorAlert: GQLInstructorAlert;
  InstructorAlertCreateInput: GQLInstructorAlertCreateInput;
  InstructorAlertDeleteInput: GQLInstructorAlertDeleteInput;
  InstructorAlertEdge: GQLInstructorAlertEdge;
  InstructorAlertPaginatedList: GQLInstructorAlertPaginatedList;
  InstructorAlertUpdateInput: GQLInstructorAlertUpdateInput;
  InstructorBadge: GQLInstructorBadge;
  InstructorBadgeInput: GQLInstructorBadgeInput;
  InstructorBadgeQuery: GQLInstructorBadgeQuery;
  InstructorEdge: Omit<GQLInstructorEdge, 'node'> & { node: GQLResolversParentTypes['Instructor'] };
  InstructorLessonUpdateInput: GQLInstructorLessonUpdateInput;
  InstructorOnAvail: GQLInstructorOnAvail;
  InstructorPrivateInfo: GQLInstructorPrivateInfo;
  InstructorSchedule: StorageOutInstructorSchedule;
  InstructorScheduleDayTimes: GQLInstructorScheduleDayTimes;
  InstructorScheduleInput: GQLInstructorScheduleInput;
  InstructorScheduleMutateQuery: GQLInstructorScheduleMutateQuery;
  InstructorScheduleQuery: GQLInstructorScheduleQuery;
  InstructorScheduleUpdateInput: GQLInstructorScheduleUpdateInput;
  InstructorSchedulesQuery: GQLInstructorSchedulesQuery;
  InstructorTimeLog: StorageOutInstructorTimeLog;
  InstructorTimeLogCreateInput: GQLInstructorTimeLogCreateInput;
  InstructorTimeLogEdge: Omit<GQLInstructorTimeLogEdge, 'node'> & { node: GQLResolversParentTypes['InstructorTimeLog'] };
  InstructorTimeLogListQuery: GQLInstructorTimeLogListQuery;
  InstructorTimeLogPaginatedList: Omit<GQLInstructorTimeLogPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['InstructorTimeLogEdge']> };
  InstructorTimeLogQuery: GQLInstructorTimeLogQuery;
  InstructorTimeLogUpdateInput: GQLInstructorTimeLogUpdateInput;
  InstructorWithSuperRegion: GQLInstructorWithSuperRegion;
  InstructorZone: StorageOutInstructorZone;
  InstructorZoneQueryInput: GQLInstructorZoneQueryInput;
  InstructorsCoverage: StorageOutInstructorsCoverage;
  InstructorsPaginatedList: Omit<GQLInstructorsPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['InstructorEdge']> };
  InstructorsQuery: GQLInstructorsQuery;
  Int: Scalars['Int'];
  ItemInput: GQLItemInput;
  ItemToExpire: GQLItemToExpire;
  ItemToExpireInput: GQLItemToExpireInput;
  ItemToRefund: GQLItemToRefund;
  ItemToRefundInput: GQLItemToRefundInput;
  ItemToTransfer: GQLItemToTransfer;
  ItemToTransferAndExpire: GQLItemToTransferAndExpire;
  ItemToTransferInput: GQLItemToTransferInput;
  JSONObject: Scalars['JSONObject'];
  LastBookedInstructor: GQLLastBookedInstructor;
  LatLng: GQLLatLng;
  LectureBlockColumn: GQLResolversParentTypes['LectureSingleImageBlock'] | GQLResolversParentTypes['LectureTitleBlock'];
  LectureBlockTextColumn: GQLLectureBlockTextColumn;
  LectureColumnImage: GQLLectureColumnImage;
  LectureContentBlock: GQLResolversParentTypes['LectureSingleImageBlock'] | GQLResolversParentTypes['LectureSingleVideoBlock'] | GQLResolversParentTypes['LectureTitleBlock'] | GQLResolversParentTypes['LectureTwoColumnBlock'];
  LectureContentImage: GQLLectureContentImage;
  LectureContentPart: Omit<GQLLectureContentPart, 'blocks'> & { blocks: Array<GQLResolversParentTypes['LectureContentBlock']> };
  LectureContentVideo: GQLLectureContentVideo;
  LecturePart: GQLResolversParentTypes['LectureContentPart'] | GQLResolversParentTypes['LectureQuizPart'];
  LectureQuestionChoice: GQLLectureQuestionChoice;
  LectureQuizPart: GQLLectureQuizPart;
  LectureQuizQuestion: GQLLectureQuizQuestion;
  LectureQuizQuestionPrepTestScore: GQLLectureQuizQuestionPrepTestScore;
  LectureQuizQuestionPrepTestScoreInput: GQLLectureQuizQuestionPrepTestScoreInput;
  LectureReport: GQLLectureReport;
  LectureSessionTime: GQLLectureSessionTime;
  LectureSingleImageBlock: GQLLectureSingleImageBlock;
  LectureSingleVideoBlock: GQLLectureSingleVideoBlock;
  LectureTimeDaily: GQLLectureTimeDaily;
  LectureTitleBlock: GQLLectureTitleBlock;
  LectureTwoColumnBlock: Omit<GQLLectureTwoColumnBlock, 'column1'> & { column1: Array<GQLResolversParentTypes['LectureBlockColumn']> };
  LedgerLog: StorageOutLedgerLog;
  LedgerLogCancelLesson: GQLLedgerLogCancelLesson;
  LedgerLogCharge: StorageOutLedgerLogCharge;
  LedgerLogEditPurchase: Omit<GQLLedgerLogEditPurchase, 'itemsUpdates'> & { itemsUpdates?: Maybe<Array<GQLResolversParentTypes['PurchaseItemUpdate']>> };
  LedgerLogExpireProducts: StorageOutLedgerLogExpireProducts;
  LedgerLogRefund: StorageOutLedgerLogRefund;
  LedgerLogRemoveStudentFromLesson: StorageOutLedgerLogRemoveStudentFromLesson;
  LedgerLogScheduleLesson: StorageOutLedgerLogScheduleLesson;
  LedgerLogScheduleMultipleLessons: GQLLedgerLogScheduleMultipleLessons;
  LedgerLogTransferExpiration: GQLLedgerLogTransferExpiration;
  LedgerLogTransferLesson: GQLLedgerLogTransferLesson;
  LedgerLogTransferPurchase: GQLLedgerLogTransferPurchase;
  LedgerLogUpdateLesson: GQLLedgerLogUpdateLesson;
  LedgerLogVoidExpiration: StorageOutLedgerLogVoidExpiration;
  LedgerLogVoidPurchase: StorageOutLedgerLogVoidPurchase;
  LedgerLogVoidRefund: StorageOutLedgerLogVoidRefund;
  LedgerLogsEdge: Omit<GQLLedgerLogsEdge, 'node'> & { node: GQLResolversParentTypes['LedgerLog'] };
  LedgerLogsPaginatedList: Omit<GQLLedgerLogsPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['LedgerLogsEdge']> };
  LedgerLogsQuery: GQLLedgerLogsQuery;
  LegacyLogSummary: GQLLegacyLogSummary;
  Lesson: StorageOutLesson;
  LessonCancelQuery: GQLLessonCancelQuery;
  LessonCancellationInfo: GQLLessonCancellationInfo;
  LessonCoupon: StorageOutLessonCoupon;
  LessonCouponInput: GQLLessonCouponInput;
  LessonDrivingRatingChange: GQLLessonDrivingRatingChange;
  LessonDrivingSkillRating: StorageOutLessonDrivingSkillRating;
  LessonDrivingSkillRatingInput: GQLLessonDrivingSkillRatingInput;
  LessonDrivingSkillsReport: StorageOutLessonDrivingSkillsReport;
  LessonDrivingSkillsReportEdge: Omit<GQLLessonDrivingSkillsReportEdge, 'node'> & { node: GQLResolversParentTypes['LessonDrivingSkillsReport'] };
  LessonDrivingSkillsReportPaginatedList: Omit<GQLLessonDrivingSkillsReportPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['LessonDrivingSkillsReportEdge']> };
  LessonEdge: Omit<GQLLessonEdge, 'node'> & { node: GQLResolversParentTypes['Lesson'] };
  LessonPackage: GQLLessonPackage;
  LessonPackageInput: GQLLessonPackageInput;
  LessonQuery: GQLLessonQuery;
  LessonRemoveStudentQuery: GQLLessonRemoveStudentQuery;
  LessonReview: StorageOutLessonReview;
  LessonReviewCreateInput: GQLLessonReviewCreateInput;
  LessonReviewEdge: Omit<GQLLessonReviewEdge, 'node'> & { node: GQLResolversParentTypes['LessonReview'] };
  LessonStudentDetails: StorageOutLessonStudentDetails;
  LessonStudentDetailsInput: GQLLessonStudentDetailsInput;
  LessonStudentDetailsUpdateInput: GQLLessonStudentDetailsUpdateInput;
  LessonType: GQLLessonType;
  LessonTypeCreateInput: GQLLessonTypeCreateInput;
  LessonTypeEdge: GQLLessonTypeEdge;
  LessonTypeFeature: GQLLessonTypeFeature;
  LessonTypeFeaturesInput: GQLLessonTypeFeaturesInput;
  LessonTypeForStateConfigInput: GQLLessonTypeForStateConfigInput;
  LessonTypeUpdateInput: GQLLessonTypeUpdateInput;
  LessonTypesPaginatedList: GQLLessonTypesPaginatedList;
  LessonUpdateAddStudent: GQLLessonUpdateAddStudent;
  LessonUpdateChangeLessonDetails: GQLLessonUpdateChangeLessonDetails;
  LessonUpdateChangeStudentDetails: GQLLessonUpdateChangeStudentDetails;
  LessonUpdateInput: GQLLessonUpdateInput;
  LessonUpdateQuery: GQLLessonUpdateQuery;
  LessonsDrivingSkillsReportsQuery: GQLLessonsDrivingSkillsReportsQuery;
  LessonsPaginatedList: Omit<GQLLessonsPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['LessonEdge']> };
  LessonsQuery: GQLLessonsQuery;
  LessonsQueryByInstructorIds: GQLLessonsQueryByInstructorIds;
  LessonsReviewsByInstructorQuery: GQLLessonsReviewsByInstructorQuery;
  LessonsReviewsPaginatedList: Omit<GQLLessonsReviewsPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['LessonReviewEdge']> };
  Location: GQLLocation;
  LogChange: GQLResolversParentTypes['LogDateTimeChange'] | GQLResolversParentTypes['LogFloatChange'] | GQLResolversParentTypes['LogIntChange'] | GQLResolversParentTypes['LogStringChange'];
  LogChangeBase: GQLResolversParentTypes['LogDateTimeChange'] | GQLResolversParentTypes['LogFloatChange'] | GQLResolversParentTypes['LogIntChange'] | GQLResolversParentTypes['LogStringChange'];
  LogDateTimeChange: GQLLogDateTimeChange;
  LogFloatChange: GQLLogFloatChange;
  LogIntChange: GQLLogIntChange;
  LogStringChange: GQLLogStringChange;
  MigrateOnlineCourseInput: GQLMigrateOnlineCourseInput;
  MigrateStudentAccountInput: GQLMigrateStudentAccountInput;
  MultipleAvailabilityCreateInput: GQLMultipleAvailabilityCreateInput;
  Mutation: {};
  Myself: GQLMyself;
  NewInstructorInput: GQLNewInstructorInput;
  NewStudentInput: GQLNewStudentInput;
  Note: GQLNote;
  NoteEdge: GQLNoteEdge;
  NoteInput: GQLNoteInput;
  NotesPaginatedList: GQLNotesPaginatedList;
  NotesQuery: GQLNotesQuery;
  OnlineCourseContent: StorageOutCourseContent;
  PackagePrice: GQLPackagePrice;
  PackagePriceInput: GQLPackagePriceInput;
  PackageWithPrices: GQLPackageWithPrices;
  PackageWithPricesInput: GQLPackageWithPricesInput;
  PageInfo: GQLPageInfo;
  PageQuery: GQLPageQuery;
  PaymentStatusInput: GQLPaymentStatusInput;
  Person: GQLPerson;
  PersonEdge: GQLPersonEdge;
  PersonPaginatedList: GQLPersonPaginatedList;
  PriceScheme: StorageOutPriceScheme;
  PriceSchemeCreateInput: GQLPriceSchemeCreateInput;
  PriceSchemeEdge: Omit<GQLPriceSchemeEdge, 'node'> & { node: GQLResolversParentTypes['PriceScheme'] };
  PriceSchemeFragment: StorageOutPriceSchemeFragment;
  PriceSchemeFragmentCreateInput: GQLPriceSchemeFragmentCreateInput;
  PriceSchemeFragmentEdge: Omit<GQLPriceSchemeFragmentEdge, 'node'> & { node: GQLResolversParentTypes['PriceSchemeFragment'] };
  PriceSchemeFragmentPaginatedList: Omit<GQLPriceSchemeFragmentPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['PriceSchemeFragmentEdge']> };
  PriceSchemeFragmentUpdateInput: GQLPriceSchemeFragmentUpdateInput;
  PriceSchemeFragmentsQuery: GQLPriceSchemeFragmentsQuery;
  PriceSchemeInfo: GQLPriceSchemeInfo;
  PriceSchemePaginatedList: Omit<GQLPriceSchemePaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['PriceSchemeEdge']> };
  PriceSchemeProductLink: StorageOutPriceSchemeProductLink;
  PriceSchemeUpdateInput: GQLPriceSchemeUpdateInput;
  PriceSchemesQuery: GQLPriceSchemesQuery;
  Product: StorageOutProduct;
  ProductBalance: GQLProductBalance;
  ProductEdge: Omit<GQLProductEdge, 'node'> & { node: GQLResolversParentTypes['Product'] };
  ProductLink: StorageOutProductLink;
  ProductLinkInput: GQLProductLinkInput;
  ProductPrice: StorageOutProductPrice;
  ProductPriceAlgo: GQLResolversParentTypes['ProductPriceAlgoDollarDiscount'] | GQLResolversParentTypes['ProductPriceAlgoDollarThenPercentDiscount'] | GQLResolversParentTypes['ProductPriceAlgoFixed'] | GQLResolversParentTypes['ProductPriceAlgoPercentDiscount'] | GQLResolversParentTypes['ProductPriceAlgoPercentThenDollarDiscount'];
  ProductPriceAlgoBase: GQLResolversParentTypes['ProductPriceAlgoDollarDiscount'] | GQLResolversParentTypes['ProductPriceAlgoDollarThenPercentDiscount'] | GQLResolversParentTypes['ProductPriceAlgoFixed'] | GQLResolversParentTypes['ProductPriceAlgoPercentDiscount'] | GQLResolversParentTypes['ProductPriceAlgoPercentThenDollarDiscount'];
  ProductPriceAlgoDollarDiscount: GQLProductPriceAlgoDollarDiscount;
  ProductPriceAlgoDollarDiscountInput: GQLProductPriceAlgoDollarDiscountInput;
  ProductPriceAlgoDollarThenPercentDiscount: GQLProductPriceAlgoDollarThenPercentDiscount;
  ProductPriceAlgoDollarThenPercentDiscountInput: GQLProductPriceAlgoDollarThenPercentDiscountInput;
  ProductPriceAlgoFixed: GQLProductPriceAlgoFixed;
  ProductPriceAlgoFixedPriceInput: GQLProductPriceAlgoFixedPriceInput;
  ProductPriceAlgoInput: GQLProductPriceAlgoInput;
  ProductPriceAlgoPercentDiscount: GQLProductPriceAlgoPercentDiscount;
  ProductPriceAlgoPercentDiscountInput: GQLProductPriceAlgoPercentDiscountInput;
  ProductPriceAlgoPercentThenDollarDiscount: GQLProductPriceAlgoPercentThenDollarDiscount;
  ProductPriceAlgoPercentThenDollarDiscountInput: GQLProductPriceAlgoPercentThenDollarDiscountInput;
  ProductPriceInput: GQLProductPriceInput;
  ProductPriceRounding: GQLProductPriceRounding;
  ProductPriceRoundingInput: GQLProductPriceRoundingInput;
  ProductQuantity: GQLProductQuantity;
  ProductQuantityInput: GQLProductQuantityInput;
  ProductsPaginatedList: Omit<GQLProductsPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['ProductEdge']> };
  ProductsQuery: GQLProductsQuery;
  Purchase: Omit<GQLPurchase, 'items'> & { items: Array<GQLResolversParentTypes['AddedPurchaseItem']> };
  PurchaseItem: GQLResolversParentTypes['PurchasePackageItem'] | GQLResolversParentTypes['PurchaseProductItem'];
  PurchaseItemInput: GQLPurchaseItemInput;
  PurchaseItemUpdate: GQLResolversParentTypes['AddedPurchasePackage'] | GQLResolversParentTypes['AddedPurchaseProduct'] | GQLResolversParentTypes['ExistingPurchaseItemUpdate'];
  PurchasePackageItem: GQLPurchasePackageItem;
  PurchaseProductItem: GQLPurchaseProductItem;
  PurchaseQuery: GQLPurchaseQuery;
  PutLessonReviewInput: GQLPutLessonReviewInput;
  PutTestLessonInput: GQLPutTestLessonInput;
  PutTestProductInput: GQLPutTestProductInput;
  PutTestRegionsInput: GQLPutTestRegionsInput;
  QuantityByIndicator: GQLQuantityByIndicator;
  Query: {};
  RedeemVoucherResult: StorageOutRedeemVoucherResult;
  ReferenceItem: GQLReferenceItem;
  Refund: GQLRefund;
  RefundPurchaseInput: GQLRefundPurchaseInput;
  RefundQuery: GQLRefundQuery;
  Region: StorageOutRegion;
  RegionEdge: Omit<GQLRegionEdge, 'node'> & { node: GQLResolversParentTypes['Region'] };
  RegionPaginatedList: Omit<GQLRegionPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['RegionEdge']> };
  RegionScheme: StorageOutRegionScheme;
  RegionSchemeEdge: Omit<GQLRegionSchemeEdge, 'node'> & { node: GQLResolversParentTypes['RegionScheme'] };
  RegionSchemePaginatedList: Omit<GQLRegionSchemePaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['RegionSchemeEdge']> };
  RegionSchemeQuery: GQLRegionSchemeQuery;
  RegionSchemeUpdateInput: GQLRegionSchemeUpdateInput;
  RegionZones: GQLRegionZones;
  RegionalManagersQuery: GQLRegionalManagersQuery;
  ScheduleDayTimes: GQLScheduleDayTimes;
  ScheduleLessonInput: GQLScheduleLessonInput;
  SchedulerQueryInput: GQLSchedulerQueryInput;
  ScholarshipApplicationResponse: GQLScholarshipApplicationResponse;
  SelfBookSharedLessonInput: GQLSelfBookSharedLessonInput;
  SetCouponInput: GQLSetCouponInput;
  SetStudentInput: GQLSetStudentInput;
  SharedLesson: StorageOutSharedLesson;
  SharedLessonsQuery: GQLSharedLessonsQuery;
  SignInWithEmailInput: GQLSignInWithEmailInput;
  SortKeyDirectionPair: GQLSortKeyDirectionPair;
  StateConfig: StorageOutStateConfig;
  StateConfigCreateInput: GQLStateConfigCreateInput;
  StateConfigEdge: Omit<GQLStateConfigEdge, 'node'> & { node: GQLResolversParentTypes['StateConfig'] };
  StateConfigPaginatedList: Omit<GQLStateConfigPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['StateConfigEdge']> };
  StateConfigUpdateInput: GQLStateConfigUpdateInput;
  StateMeetingPoint: GQLStateMeetingPoint;
  StateMeetingPointCreateInput: GQLStateMeetingPointCreateInput;
  StateMeetingPointEdge: GQLStateMeetingPointEdge;
  StateMeetingPointPaginatedList: GQLStateMeetingPointPaginatedList;
  StateMeetingPointUpdateInput: GQLStateMeetingPointUpdateInput;
  StateMeetingPointsQuery: GQLStateMeetingPointsQuery;
  String: Scalars['String'];
  Student: StorageOutStudent;
  StudentBalance: GQLStudentBalance;
  StudentCourseMigration: GQLStudentCourseMigration;
  StudentEdge: Omit<GQLStudentEdge, 'node'> & { node: GQLResolversParentTypes['Student'] };
  StudentId: GQLStudentId;
  StudentIdProductId: GQLStudentIdProductId;
  StudentInput: GQLStudentInput;
  StudentLegalAndPermissions: GQLStudentLegalAndPermissions;
  StudentLegalAndPermissionsInput: GQLStudentLegalAndPermissionsInput;
  StudentObject: GQLStudentObject;
  StudentOnlineCourse: GQLStudentOnlineCourse;
  StudentOnlineCourseEdge: GQLStudentOnlineCourseEdge;
  StudentOnlineCourseInput: GQLStudentOnlineCourseInput;
  StudentOnlineCourseLecture: GQLStudentOnlineCourseLecture;
  StudentOnlineCourseReport: GQLStudentOnlineCourseReport;
  StudentOnlineCourseTest: GQLStudentOnlineCourseTest;
  StudentOnlineCourseTimeProgress: GQLStudentOnlineCourseTimeProgress;
  StudentOnlineCoursesPaginatedList: GQLStudentOnlineCoursesPaginatedList;
  StudentSessionAttendance: GQLStudentSessionAttendance;
  StudentsPaginatedList: Omit<GQLStudentsPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['StudentEdge']> };
  StudentsQuery: GQLStudentsQuery;
  SubItemInput: GQLSubItemInput;
  SubmitCoursePrepTestChapterScoresInput: GQLSubmitCoursePrepTestChapterScoresInput;
  SuperRegion: StorageOutSuperRegion;
  SuperRegionCreateInput: GQLSuperRegionCreateInput;
  SuperRegionEdge: Omit<GQLSuperRegionEdge, 'node'> & { node: GQLResolversParentTypes['SuperRegion'] };
  SuperRegionPaginatedList: Omit<GQLSuperRegionPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['SuperRegionEdge']> };
  SuperRegionQuery: GQLSuperRegionQuery;
  SuperRegionUpdateInput: GQLSuperRegionUpdateInput;
  SyncCheck: StorageOutSyncCheck;
  SyncCheckEdge: Omit<GQLSyncCheckEdge, 'node'> & { node: GQLResolversParentTypes['SyncCheck'] };
  SyncCheckError: GQLSyncCheckError;
  SyncChecksPaginatedList: Omit<GQLSyncChecksPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['SyncCheckEdge']> };
  SyncConflict: StorageOutSyncConflict;
  SyncConflictDayBreakdown: Omit<GQLSyncConflictDayBreakdown, 'nextLogs'> & { nextLogs: Array<GQLResolversParentTypes['LedgerLog']> };
  SyncConflictDetails: Omit<GQLSyncConflictDetails, 'dayByDayBreakdown'> & { dayByDayBreakdown: Array<GQLResolversParentTypes['SyncConflictDayBreakdown']> };
  SyncConflictEdge: Omit<GQLSyncConflictEdge, 'node'> & { node: GQLResolversParentTypes['SyncConflict'] };
  SyncConflictsPaginatedList: StorageOutSyncConflictsPaginatedList;
  SyncConflictsQuery: GQLSyncConflictsQuery;
  SyncStudentExpiredMinutesConflict: Omit<GQLSyncStudentExpiredMinutesConflict, 'details'> & { details: GQLResolversParentTypes['SyncConflictDetails'] };
  SyncStudentExpiredMinutesEntityValue: GQLSyncStudentExpiredMinutesEntityValue;
  SyncStudentFundsBalanceConflict: Omit<GQLSyncStudentFundsBalanceConflict, 'details'> & { details: GQLResolversParentTypes['SyncConflictDetails'] };
  SyncStudentFundsBalanceConflictEntityValue: GQLSyncStudentFundsBalanceConflictEntityValue;
  SyncStudentProductBalanceConflict: Omit<GQLSyncStudentProductBalanceConflict, 'details'> & { details: GQLResolversParentTypes['SyncConflictDetails'] };
  SyncStudentProductBalanceConflictEntityValue: GQLSyncStudentProductBalanceConflictEntityValue;
  TestChargeLedgerLogInput: GQLTestChargeLedgerLogInput;
  TestUserInput: GQLTestUserInput;
  TransferAccountCheckResult: GQLTransferAccountCheckResult;
  TransferAccountInput: GQLTransferAccountInput;
  TransferExpirationInput: GQLTransferExpirationInput;
  TransferLessonInput: GQLTransferLessonInput;
  TransferPurchaseInput: GQLTransferPurchaseInput;
  TransientSlot: StorageOutTransientSlot;
  TransientSlotInput: GQLTransientSlotInput;
  TransientSlotsQuery: GQLTransientSlotsQuery;
  USState: GQLUsState;
  UnenrollStudentFromCohortInput: GQLUnenrollStudentFromCohortInput;
  UpdateCourseSessionInput: GQLUpdateCourseSessionInput;
  UpdateInstructorInput: GQLUpdateInstructorInput;
  UpdateInstructorQuery: GQLUpdateInstructorQuery;
  UpdateProductInput: GQLUpdateProductInput;
  UpdateStudentCourseMetaInput: GQLUpdateStudentCourseMetaInput;
  UpdateStudentSessionAttendanceInput: GQLUpdateStudentSessionAttendanceInput;
  UpdateStudentsSessionAttendanceInput: GQLUpdateStudentsSessionAttendanceInput;
  UpdateVoucherInput: GQLUpdateVoucherInput;
  UpdatedBy: GQLUpdatedBy;
  UpsertCouponInput: GQLUpsertCouponInput;
  UserLog: Omit<GQLUserLog, 'changes' | 'instructor'> & { changes: Array<GQLResolversParentTypes['LogChange']>, instructor?: Maybe<GQLResolversParentTypes['Instructor']> };
  UserLogEdge: Omit<GQLUserLogEdge, 'node'> & { node: GQLResolversParentTypes['UserLog'] };
  UserLogsPaginatedList: Omit<GQLUserLogsPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['UserLogEdge']> };
  UserLogsQuery: GQLUserLogsQuery;
  Vehicle: Omit<GQLVehicle, 'instructors'> & { instructors?: Maybe<Array<GQLResolversParentTypes['Instructor']>> };
  VehicleAvail: GQLVehicleAvail;
  VehicleAvailability: GQLVehicleAvailability;
  VehicleAvailabilityQuery: GQLVehicleAvailabilityQuery;
  VehicleEdge: Omit<GQLVehicleEdge, 'node'> & { node: GQLResolversParentTypes['Vehicle'] };
  VehicleInput: GQLVehicleInput;
  VehicleInstructorFields: GQLVehicleInstructorFields;
  VehicleMapAvailability: GQLVehicleMapAvailability;
  VehicleMapAvailabilityQuery: GQLVehicleMapAvailabilityQuery;
  VehiclesPaginatedList: Omit<GQLVehiclesPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['VehicleEdge']> };
  VoidExpirationInput: GQLVoidExpirationInput;
  VoidPurchaseInput: GQLVoidPurchaseInput;
  VoidRefundInput: GQLVoidRefundInput;
  Voucher: StorageOutVoucher;
  VoucherEdge: Omit<GQLVoucherEdge, 'node'> & { node: GQLResolversParentTypes['Voucher'] };
  VoucherRedeemInput: GQLVoucherRedeemInput;
  VoucherSource: GQLVoucherSource;
  VouchersPaginatedList: Omit<GQLVouchersPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['VoucherEdge']> };
  VouchersRedeemedByStudentQuery: GQLVouchersRedeemedByStudentQuery;
  WaitlistComment: GQLWaitlistComment;
  WaitlistCommentInput: GQLWaitlistCommentInput;
  WaitlistCommentsQuery: GQLWaitlistCommentsQuery;
  WaitlistEdge: Omit<GQLWaitlistEdge, 'node'> & { node: GQLResolversParentTypes['WaitlistStudent'] };
  WaitlistPaginatedList: Omit<GQLWaitlistPaginatedList, 'edges'> & { edges: Array<GQLResolversParentTypes['WaitlistEdge']> };
  WaitlistStudent: StorageOutWaitlist;
  WaitlistStudentInput: GQLWaitlistStudentInput;
}>;

export type GQLAddedPurchaseItemResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AddedPurchaseItem'] = GQLResolversParentTypes['AddedPurchaseItem']> = ResolversObject<{
  __resolveType: TypeResolveFn<'AddedPurchasePackage' | 'AddedPurchaseProduct', ParentType, ContextType>;
}>;

export type GQLAddedPurchasePackageResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AddedPurchasePackage'] = GQLResolversParentTypes['AddedPurchasePackage']> = ResolversObject<{
  itemId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  items?: Resolver<Maybe<Array<GQLResolversTypes['AddedPurchaseItem']>>, ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  priceDollars?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAddedPurchaseProductResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AddedPurchaseProduct'] = GQLResolversParentTypes['AddedPurchaseProduct']> = ResolversObject<{
  basePriceDollars?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  itemId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAddressResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Address'] = GQLResolversParentTypes['Address']> = ResolversObject<{
  address?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  phone?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  secondaryPhone?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  zipcode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAnvilPdfIdsResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AnvilPdfIds'] = GQLResolversParentTypes['AnvilPdfIds']> = ResolversObject<{
  admin?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  insurance?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  student?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAuditLogResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AuditLog'] = GQLResolversParentTypes['AuditLog']> = ResolversObject<{
  action?: Resolver<GQLResolversTypes['AuditLogActions'], ParentType, ContextType>;
  changes?: Resolver<Array<GQLResolversTypes['LogChange']>, ParentType, ContextType>;
  collection?: Resolver<GQLResolversTypes['AuditLogCollection'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  updatedBy?: Resolver<GQLResolversTypes['UpdatedBy'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAuditLogCollectionResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AuditLogCollection'] = GQLResolversParentTypes['AuditLogCollection']> = ResolversObject<{
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAuditLogEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AuditLogEdge'] = GQLResolversParentTypes['AuditLogEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['AuditLog'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAuditLogsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AuditLogsPaginatedList'] = GQLResolversParentTypes['AuditLogsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['AuditLogEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAvailabilityResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Availability'] = GQLResolversParentTypes['Availability']> = ResolversObject<{
  center?: Resolver<GQLResolversTypes['Center'], ParentType, ContextType>;
  date?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  endTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<GQLResolversTypes['Instructor'], ParentType, ContextType>;
  instructorId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isPrivate?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  maxLessons?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  minLeadHours?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  note?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  vehicle?: Resolver<GQLResolversTypes['Vehicle'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAvailabilityAddFailureResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AvailabilityAddFailure'] = GQLResolversParentTypes['AvailabilityAddFailure']> = ResolversObject<{
  date?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  error?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  weekDay?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAvailabilitySlotResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AvailabilitySlot'] = GQLResolversParentTypes['AvailabilitySlot']> = ResolversObject<{
  endTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  instructorId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructorName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  instructorTimezone?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  isPrivate?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  location?: Resolver<GQLResolversTypes['Location'], ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAvailabilitySlotPerDayResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AvailabilitySlotPerDay'] = GQLResolversParentTypes['AvailabilitySlotPerDay']> = ResolversObject<{
  date?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  timeSlots?: Resolver<Array<GQLResolversTypes['AvailabilitySlot']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLAvailabilityUpdateFailureResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['AvailabilityUpdateFailure'] = GQLResolversParentTypes['AvailabilityUpdateFailure']> = ResolversObject<{
  availabilityId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  error?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLBalancedQuantityResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['BalancedQuantity'] = GQLResolversParentTypes['BalancedQuantity']> = ResolversObject<{
  __resolveType: TypeResolveFn<'ProductBalance', ParentType, ContextType>;
  availableQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  expiredQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  purchasedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  receivedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  refundedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  sentQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  usedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  voidedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
}>;

export type GQLBaseLedgerLogResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['BaseLedgerLog'] = GQLResolversParentTypes['BaseLedgerLog']> = ResolversObject<{
  __resolveType: TypeResolveFn<'LedgerLogCancelLesson' | 'LedgerLogCharge' | 'LedgerLogEditPurchase' | 'LedgerLogExpireProducts' | 'LedgerLogRefund' | 'LedgerLogRemoveStudentFromLesson' | 'LedgerLogScheduleLesson' | 'LedgerLogScheduleMultipleLessons' | 'LedgerLogTransferExpiration' | 'LedgerLogTransferLesson' | 'LedgerLogTransferPurchase' | 'LedgerLogUpdateLesson' | 'LedgerLogVoidExpiration' | 'LedgerLogVoidPurchase' | 'LedgerLogVoidRefund', ParentType, ContextType>;
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLBaseLedgerLogSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
}>;

export type GQLBatchAddAvailsResultResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['BatchAddAvailsResult'] = GQLResolversParentTypes['BatchAddAvailsResult']> = ResolversObject<{
  added?: Resolver<Array<GQLResolversTypes['Availability']>, ParentType, ContextType>;
  failed?: Resolver<Array<GQLResolversTypes['AvailabilityAddFailure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLBatchDeleteAvailsResultResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['BatchDeleteAvailsResult'] = GQLResolversParentTypes['BatchDeleteAvailsResult']> = ResolversObject<{
  deleted?: Resolver<Array<GQLResolversTypes['ID']>, ParentType, ContextType>;
  failed?: Resolver<Array<GQLResolversTypes['AvailabilityUpdateFailure']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLBatchEditAvailsResultResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['BatchEditAvailsResult'] = GQLResolversParentTypes['BatchEditAvailsResult']> = ResolversObject<{
  failed?: Resolver<Array<GQLResolversTypes['AvailabilityUpdateFailure']>, ParentType, ContextType>;
  updated?: Resolver<Array<GQLResolversTypes['Availability']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLBookingAlertResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['BookingAlert'] = GQLResolversParentTypes['BookingAlert']> = ResolversObject<{
  createdAt?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<GQLResolversTypes['Person'], ParentType, ContextType>;
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  text?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLBookingAlertEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['BookingAlertEdge'] = GQLResolversParentTypes['BookingAlertEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['BookingAlert'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLBookingAlertsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['BookingAlertsPaginatedList'] = GQLResolversParentTypes['BookingAlertsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['BookingAlertEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCartResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Cart'] = GQLResolversParentTypes['Cart']> = ResolversObject<{
  bookedSlotIds?: Resolver<Maybe<Array<GQLResolversTypes['ID']>>, ParentType, ContextType>;
  coupon?: Resolver<Maybe<GQLResolversTypes['Coupon']>, ParentType, ContextType>;
  createdAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isOrderComplete?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  items?: Resolver<Array<GQLResolversTypes['PackageWithPrices']>, ParentType, ContextType>;
  paymentStatus?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  studentId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  usState?: Resolver<Maybe<GQLResolversTypes['USStateId']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCenterResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Center'] = GQLResolversParentTypes['Center']> = ResolversObject<{
  location?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCertificateResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Certificate'] = GQLResolversParentTypes['Certificate']> = ResolversObject<{
  certNumber?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  certType?: Resolver<GQLResolversTypes['CertificateType'], ParentType, ContextType>;
  certTypeId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<GQLResolversTypes['Person'], ParentType, ContextType>;
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  issuedAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  issuedTo?: Resolver<Maybe<GQLResolversTypes['Person']>, ParentType, ContextType>;
  issuedToId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  usState?: Resolver<Maybe<GQLResolversTypes['USState']>, ParentType, ContextType>;
  usStateId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCertificateEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CertificateEdge'] = GQLResolversParentTypes['CertificateEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Certificate'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCertificateTypeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CertificateType'] = GQLResolversParentTypes['CertificateType']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  prefix?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  usStateId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  valMax?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  valMin?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCertificatesPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CertificatesPaginatedList'] = GQLResolversParentTypes['CertificatesPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['CertificateEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLChargeLedgerLogResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ChargeLedgerLog'] = GQLResolversParentTypes['ChargeLedgerLog']> = ResolversObject<{
  categoryId?: Resolver<GQLResolversTypes['PaymentCategories'], ParentType, ContextType>;
  couponCode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  items?: Resolver<Array<GQLResolversTypes['PurchaseItem']>, ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLChargeLedgerLogSummaryArgs, 'studentId'>>;
  voucherCode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCohortResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Cohort'] = GQLResolversParentTypes['Cohort']> = ResolversObject<{
  courseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  endDate?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  enrolledUserIds?: Resolver<Maybe<Array<Maybe<GQLResolversTypes['ID']>>>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  isEnrollmentOpen?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  location?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  maxEnrollees?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  numEnrolled?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  sessions?: Resolver<Maybe<Array<GQLResolversTypes['CohortSession']>>, ParentType, ContextType>;
  startDate?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<GQLResolversTypes['USStateId'], ParentType, ContextType>;
  stateId?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  students?: Resolver<Maybe<Array<GQLResolversTypes['StudentObject']>>, ParentType, ContextType>;
  timeZone?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCohortSessionResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CohortSession'] = GQLResolversParentTypes['CohortSession']> = ResolversObject<{
  attendance?: Resolver<Maybe<Array<GQLResolversTypes['StudentSessionAttendance']>>, ParentType, ContextType>;
  cohortId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  endTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCouponResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Coupon'] = GQLResolversParentTypes['Coupon']> = ResolversObject<{
  __resolveType: TypeResolveFn<'CourseCoupon' | 'LessonCoupon', ParentType, ContextType>;
}>;

export type GQLCouponEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CouponEdge'] = GQLResolversParentTypes['CouponEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Coupon'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCouponsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CouponsPaginatedList'] = GQLResolversParentTypes['CouponsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['CouponEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCourseCouponResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CourseCoupon'] = GQLResolversParentTypes['CourseCoupon']> = ResolversObject<{
  code?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  courseId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  courseSlug?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  discount?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  expiration?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isDailyCoupon?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  state?: Resolver<GQLResolversTypes['USStateId'], ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['CouponType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCourseLectureResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CourseLecture'] = GQLResolversParentTypes['CourseLecture']> = ResolversObject<{
  content?: Resolver<GQLResolversTypes['CourseLectureContent'], ParentType, ContextType, RequireFields<GQLCourseLectureContentArgs, 'courseId'>>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  includesAQuiz?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  slug?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  timeSpentByStudent?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType, RequireFields<GQLCourseLectureTimeSpentByStudentArgs, 'courseId' | 'studentId'>>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCourseLectureContentResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CourseLectureContent'] = GQLResolversParentTypes['CourseLectureContent']> = ResolversObject<{
  minimumDuration?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  parts?: Resolver<Array<GQLResolversTypes['LecturePart']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCoursePrepTestResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CoursePrepTest'] = GQLResolversParentTypes['CoursePrepTest']> = ResolversObject<{
  chapters?: Resolver<Array<GQLResolversTypes['CoursePrepTestChapter']>, ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCoursePrepTestChapterResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CoursePrepTestChapter'] = GQLResolversParentTypes['CoursePrepTestChapter']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  questions?: Resolver<Array<GQLResolversTypes['LectureQuizQuestion']>, ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCoursePrepTestChapterScoresResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CoursePrepTestChapterScores'] = GQLResolversParentTypes['CoursePrepTestChapterScores']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  questionScores?: Resolver<Array<GQLResolversTypes['LectureQuizQuestionPrepTestScore']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCourseTestResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CourseTest'] = GQLResolversParentTypes['CourseTest']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCourseTestLectureResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CourseTestLecture'] = GQLResolversParentTypes['CourseTestLecture']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLCourseTopicResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['CourseTopic'] = GQLResolversParentTypes['CourseTopic']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lectures?: Resolver<Array<GQLResolversTypes['CourseLecture']>, ParentType, ContextType>;
  slug?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface GQLDateTimeScalarConfig extends GraphQLScalarTypeConfig<GQLResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type GQLDrivingSkillResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['DrivingSkill'] = GQLResolversParentTypes['DrivingSkill']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLEligibleStudentResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['EligibleStudent'] = GQLResolversParentTypes['EligibleStudent']> = ResolversObject<{
  displayName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  distanceMeters?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  dob?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  drivingMinutesRemaining?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  licenseExpirationDate?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  licenseIssueDate?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  licenseNumber?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  observationMinutesRemaining?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  userId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLExistingPurchaseItemUpdateResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ExistingPurchaseItemUpdate'] = GQLResolversParentTypes['ExistingPurchaseItemUpdate']> = ResolversObject<{
  itemId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  priceUpdate?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  quantityUpdate?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLExpenditureResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Expenditure'] = GQLResolversParentTypes['Expenditure']> = ResolversObject<{
  itemId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLExpirationResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Expiration'] = GQLResolversParentTypes['Expiration']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  expirationType?: Resolver<GQLResolversTypes['ExpireProductsTypes'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isVoid?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  itemsToExpire?: Resolver<Array<GQLResolversTypes['ItemToExpire']>, ParentType, ContextType>;
  purchaseIds?: Resolver<Array<GQLResolversTypes['ID']>, ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  versionId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLFirebaseCourseSessionTimeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['FirebaseCourseSessionTime'] = GQLResolversParentTypes['FirebaseCourseSessionTime']> = ResolversObject<{
  sessionEnd?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  sessionStart?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLFirebaseLessonReviewResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['FirebaseLessonReview'] = GQLResolversParentTypes['FirebaseLessonReview']> = ResolversObject<{
  date?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  rating?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  source?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLGeoJsonFeatureResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['GeoJsonFeature'] = GQLResolversParentTypes['GeoJsonFeature']> = ResolversObject<{
  geometry?: Resolver<GQLResolversTypes['GeoJsonGeometry'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  properties?: Resolver<GQLResolversTypes['GeoJsonProperties'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLGeoJsonGeometryResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['GeoJsonGeometry'] = GQLResolversParentTypes['GeoJsonGeometry']> = ResolversObject<{
  coordinates?: Resolver<Array<Array<Array<GQLResolversTypes['Float']>>>, ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLGeoJsonPropertiesResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['GeoJsonProperties'] = GQLResolversParentTypes['GeoJsonProperties']> = ResolversObject<{
  label?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLIdTokenResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['IdToken'] = GQLResolversParentTypes['IdToken']> = ResolversObject<{
  expiresIn?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  idToken?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  refreshToken?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLImpersonateUserResponseResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ImpersonateUserResponse'] = GQLResolversParentTypes['ImpersonateUserResponse']> = ResolversObject<{
  token?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Instructor'] = GQLResolversParentTypes['Instructor']> = ResolversObject<{
  about?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  adminInfo?: Resolver<GQLResolversTypes['InstructorAdminInfo'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  languages?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  portraitImage?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  privateInfo?: Resolver<GQLResolversTypes['InstructorPrivateInfo'], ParentType, ContextType>;
  region?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  signInEmail?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  superRegion?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  timeZone?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  usState?: Resolver<Maybe<GQLResolversTypes['USState']>, ParentType, ContextType>;
  usStateId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorAdminInfoResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorAdminInfo'] = GQLResolversParentTypes['InstructorAdminInfo']> = ResolversObject<{
  badges?: Resolver<Maybe<Array<GQLResolversTypes['InstructorBadge']>>, ParentType, ContextType>;
  payrollFirstName?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  payrollLastName?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  payrollTitle?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  regionalManager?: Resolver<Maybe<GQLResolversTypes['Person']>, ParentType, ContextType>;
  regionalManagerId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  userId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorAlertResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorAlert'] = GQLResolversParentTypes['InstructorAlert']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  message?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  readMoreLink?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  severity?: Resolver<GQLResolversTypes['InstructorAlertSeverity'], ParentType, ContextType>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorAlertEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorAlertEdge'] = GQLResolversParentTypes['InstructorAlertEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['InstructorAlert'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorAlertPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorAlertPaginatedList'] = GQLResolversParentTypes['InstructorAlertPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['InstructorAlertEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorBadgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorBadge'] = GQLResolversParentTypes['InstructorBadge']> = ResolversObject<{
  icon?: Resolver<GQLResolversTypes['InstructorBadgeIcon'], ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorEdge'] = GQLResolversParentTypes['InstructorEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Instructor'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorOnAvailResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorOnAvail'] = GQLResolversParentTypes['InstructorOnAvail']> = ResolversObject<{
  displayName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  timeZone?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorPrivateInfoResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorPrivateInfo'] = GQLResolversParentTypes['InstructorPrivateInfo']> = ResolversObject<{
  baseAddress?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  breakTimes?: Resolver<Array<GQLResolversTypes['Int']>, ParentType, ContextType>;
  email?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  licenseExpirationDate?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  licenseNumber?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  managerName?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  maxBaseTravelMinutes?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  maxBetweenTravelMinutes?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  nonTeachingPay?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  phoneNumber?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  slackUsername?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<GQLResolversTypes['InstructorStatus'], ParentType, ContextType>;
  teachingPay?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorScheduleResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorSchedule'] = GQLResolversParentTypes['InstructorSchedule']> = ResolversObject<{
  createdAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  dayTimesAvailable?: Resolver<Array<GQLResolversTypes['ScheduleDayTimes']>, ParentType, ContextType>;
  daysPerWeek?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  endDate?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructorId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructorName?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  isApproved?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  managerName?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  vacationRequests?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorTimeLogResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorTimeLog'] = GQLResolversParentTypes['InstructorTimeLog']> = ResolversObject<{
  description?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  endTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<GQLResolversTypes['Instructor'], ParentType, ContextType>;
  instructorId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  logType?: Resolver<GQLResolversTypes['InstructorTimeLogType'], ParentType, ContextType>;
  rateType?: Resolver<GQLResolversTypes['InstructorTimeLogRateType'], ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorTimeLogEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorTimeLogEdge'] = GQLResolversParentTypes['InstructorTimeLogEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['InstructorTimeLog'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorTimeLogPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorTimeLogPaginatedList'] = GQLResolversParentTypes['InstructorTimeLogPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['InstructorTimeLogEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorWithSuperRegionResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorWithSuperRegion'] = GQLResolversParentTypes['InstructorWithSuperRegion']> = ResolversObject<{
  about?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  displayName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isInactive?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  isRetired?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  languages?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  portraitImage?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  slackUsername?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  superRegion?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  timeZone?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  usStateId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  userId?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  wallpaperImage?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorZoneResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorZone'] = GQLResolversParentTypes['InstructorZone']> = ResolversObject<{
  center?: Resolver<GQLResolversTypes['LatLng'], ParentType, ContextType>;
  features?: Resolver<Array<GQLResolversTypes['GeoJsonFeature']>, ParentType, ContextType>;
  instructorId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructorName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  isStatic?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorsCoverageResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorsCoverage'] = GQLResolversParentTypes['InstructorsCoverage']> = ResolversObject<{
  center?: Resolver<GQLResolversTypes['LatLng'], ParentType, ContextType>;
  instructorZones?: Resolver<Array<GQLResolversTypes['InstructorZone']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLInstructorsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['InstructorsPaginatedList'] = GQLResolversParentTypes['InstructorsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['InstructorEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLItemToExpireResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ItemToExpire'] = GQLResolversParentTypes['ItemToExpire']> = ResolversObject<{
  expiredQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  itemId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLItemToRefundResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ItemToRefund'] = GQLResolversParentTypes['ItemToRefund']> = ResolversObject<{
  itemId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  refundedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLItemToTransferResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ItemToTransfer'] = GQLResolversParentTypes['ItemToTransfer']> = ResolversObject<{
  itemId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  transferredQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLItemToTransferAndExpireResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ItemToTransferAndExpire'] = GQLResolversParentTypes['ItemToTransferAndExpire']> = ResolversObject<{
  itemId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface GQLJsonObjectScalarConfig extends GraphQLScalarTypeConfig<GQLResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type GQLLastBookedInstructorResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LastBookedInstructor'] = GQLResolversParentTypes['LastBookedInstructor']> = ResolversObject<{
  displayName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLatLngResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LatLng'] = GQLResolversParentTypes['LatLng']> = ResolversObject<{
  lat?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  lng?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureBlockColumnResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureBlockColumn'] = GQLResolversParentTypes['LectureBlockColumn']> = ResolversObject<{
  __resolveType: TypeResolveFn<'LectureSingleImageBlock' | 'LectureTitleBlock', ParentType, ContextType>;
}>;

export type GQLLectureBlockTextColumnResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureBlockTextColumn'] = GQLResolversParentTypes['LectureBlockTextColumn']> = ResolversObject<{
  structuredText?: Resolver<GQLResolversTypes['JSONObject'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureColumnImageResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureColumnImage'] = GQLResolversParentTypes['LectureColumnImage']> = ResolversObject<{
  image?: Resolver<GQLResolversTypes['LectureContentImage'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureContentBlockResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureContentBlock'] = GQLResolversParentTypes['LectureContentBlock']> = ResolversObject<{
  __resolveType: TypeResolveFn<'LectureSingleImageBlock' | 'LectureSingleVideoBlock' | 'LectureTitleBlock' | 'LectureTwoColumnBlock', ParentType, ContextType>;
}>;

export type GQLLectureContentImageResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureContentImage'] = GQLResolversParentTypes['LectureContentImage']> = ResolversObject<{
  alt?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  aspectRatio?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  base64?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  bgColor?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  sizes?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  src?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  srcSet?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  webpSrcSet?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  width?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureContentPartResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureContentPart'] = GQLResolversParentTypes['LectureContentPart']> = ResolversObject<{
  blocks?: Resolver<Array<GQLResolversTypes['LectureContentBlock']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  structuredText?: Resolver<Maybe<GQLResolversTypes['JSONObject']>, ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LecturePartType'], ParentType, ContextType>;
  video?: Resolver<Maybe<GQLResolversTypes['LectureContentVideo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureContentVideoResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureContentVideo'] = GQLResolversParentTypes['LectureContentVideo']> = ResolversObject<{
  thumbnailUrl?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLecturePartResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LecturePart'] = GQLResolversParentTypes['LecturePart']> = ResolversObject<{
  __resolveType: TypeResolveFn<'LectureContentPart' | 'LectureQuizPart', ParentType, ContextType>;
}>;

export type GQLLectureQuestionChoiceResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureQuestionChoice'] = GQLResolversParentTypes['LectureQuestionChoice']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  option?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureQuizPartResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureQuizPart'] = GQLResolversParentTypes['LectureQuizPart']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  questions?: Resolver<Array<GQLResolversTypes['LectureQuizQuestion']>, ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LecturePartType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureQuizQuestionResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureQuizQuestion'] = GQLResolversParentTypes['LectureQuizQuestion']> = ResolversObject<{
  answer?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  choices?: Resolver<Maybe<Array<GQLResolversTypes['LectureQuestionChoice']>>, ParentType, ContextType>;
  doShuffle?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<Maybe<GQLResolversTypes['LectureContentImage']>, ParentType, ContextType>;
  question?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureQuizQuestionPrepTestScoreResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureQuizQuestionPrepTestScore'] = GQLResolversParentTypes['LectureQuizQuestionPrepTestScore']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isCorrect?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureReportResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureReport'] = GQLResolversParentTypes['LectureReport']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  testScore?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  totalTimeSeconds?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureSessionTimeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureSessionTime'] = GQLResolversParentTypes['LectureSessionTime']> = ResolversObject<{
  lectureId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  session?: Resolver<Maybe<GQLResolversTypes['FirebaseCourseSessionTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureSingleImageBlockResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureSingleImageBlock'] = GQLResolversParentTypes['LectureSingleImageBlock']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  image?: Resolver<GQLResolversTypes['LectureContentImage'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LectureContentBlockType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureSingleVideoBlockResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureSingleVideoBlock'] = GQLResolversParentTypes['LectureSingleVideoBlock']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LectureContentBlockType'], ParentType, ContextType>;
  video?: Resolver<Maybe<GQLResolversTypes['LectureContentVideo']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureTimeDailyResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureTimeDaily'] = GQLResolversParentTypes['LectureTimeDaily']> = ResolversObject<{
  date?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  totalTimeSeconds?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureTitleBlockResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureTitleBlock'] = GQLResolversParentTypes['LectureTitleBlock']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LectureContentBlockType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLectureTwoColumnBlockResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LectureTwoColumnBlock'] = GQLResolversParentTypes['LectureTwoColumnBlock']> = ResolversObject<{
  column1?: Resolver<Array<GQLResolversTypes['LectureBlockColumn']>, ParentType, ContextType>;
  column2?: Resolver<GQLResolversTypes['LectureBlockTextColumn'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LectureContentBlockType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLog'] = GQLResolversParentTypes['LedgerLog']> = ResolversObject<{
  __resolveType: TypeResolveFn<'LedgerLogCancelLesson' | 'LedgerLogCharge' | 'LedgerLogEditPurchase' | 'LedgerLogExpireProducts' | 'LedgerLogRefund' | 'LedgerLogRemoveStudentFromLesson' | 'LedgerLogScheduleLesson' | 'LedgerLogScheduleMultipleLessons' | 'LedgerLogTransferExpiration' | 'LedgerLogTransferLesson' | 'LedgerLogTransferPurchase' | 'LedgerLogUpdateLesson' | 'LedgerLogVoidExpiration' | 'LedgerLogVoidPurchase' | 'LedgerLogVoidRefund', ParentType, ContextType>;
}>;

export type GQLLedgerLogCancelLessonResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogCancelLesson'] = GQLResolversParentTypes['LedgerLogCancelLesson']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lessonId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  purchaseIds?: Resolver<Array<GQLResolversTypes['ID']>, ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogCancelLessonSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogChargeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogCharge'] = GQLResolversParentTypes['LedgerLogCharge']> = ResolversObject<{
  amountDollars?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  categoryId?: Resolver<GQLResolversTypes['PaymentCategories'], ParentType, ContextType>;
  couponCode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  couponDiscount?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  items?: Resolver<Array<GQLResolversTypes['PurchaseItem']>, ParentType, ContextType>;
  paymentCaptureDate?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogChargeSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  usStateId?: Resolver<GQLResolversTypes['USStateId'], ParentType, ContextType>;
  voucherCode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogEditPurchaseResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogEditPurchase'] = GQLResolversParentTypes['LedgerLogEditPurchase']> = ResolversObject<{
  categoryId?: Resolver<Maybe<GQLResolversTypes['PaymentCategories']>, ParentType, ContextType>;
  couponCode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  couponDiscount?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  itemsUpdates?: Resolver<Maybe<Array<GQLResolversTypes['PurchaseItemUpdate']>>, ParentType, ContextType>;
  paymentCaptureDate?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  stripeChargeId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  stripeCustomerId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogEditPurchaseSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  usStateId?: Resolver<Maybe<GQLResolversTypes['USStateId']>, ParentType, ContextType>;
  voucherCode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogExpireProductsResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogExpireProducts'] = GQLResolversParentTypes['LedgerLogExpireProducts']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  expirationType?: Resolver<GQLResolversTypes['ExpireProductsTypes'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isVoid?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  itemsToExpire?: Resolver<Array<GQLResolversTypes['ItemToExpire']>, ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogExpireProductsSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogRefundResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogRefund'] = GQLResolversParentTypes['LedgerLogRefund']> = ResolversObject<{
  amountToRefund?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  categoryId?: Resolver<GQLResolversTypes['PaymentCategories'], ParentType, ContextType>;
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  itemsToRefund?: Resolver<Maybe<Array<GQLResolversTypes['ItemToRefund']>>, ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogRefundSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogRemoveStudentFromLessonResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogRemoveStudentFromLesson'] = GQLResolversParentTypes['LedgerLogRemoveStudentFromLesson']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  expenditures?: Resolver<Array<GQLResolversTypes['Expenditure']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  initiatedBy?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lessonId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  reason?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogRemoveStudentFromLessonSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogScheduleLessonResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogScheduleLesson'] = GQLResolversParentTypes['LedgerLogScheduleLesson']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  expenditures?: Resolver<Array<GQLResolversTypes['Expenditure']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lessonId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogScheduleLessonSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogScheduleMultipleLessonsResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogScheduleMultipleLessons'] = GQLResolversParentTypes['LedgerLogScheduleMultipleLessons']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogScheduleMultipleLessonsSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogTransferExpirationResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogTransferExpiration'] = GQLResolversParentTypes['LedgerLogTransferExpiration']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  destinationStudentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  expirationId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isVoid?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  itemsToTransferAndExpire?: Resolver<Array<GQLResolversTypes['ItemToTransferAndExpire']>, ParentType, ContextType>;
  purchaseIds?: Resolver<Array<GQLResolversTypes['ID']>, ParentType, ContextType>;
  sourceStudentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogTransferExpirationSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogTransferLessonResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogTransferLesson'] = GQLResolversParentTypes['LedgerLogTransferLesson']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  destinationStudentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lessonId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  purchaseIds?: Resolver<Array<GQLResolversTypes['ID']>, ParentType, ContextType>;
  sourceStudentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogTransferLessonSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogTransferPurchaseResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogTransferPurchase'] = GQLResolversParentTypes['LedgerLogTransferPurchase']> = ResolversObject<{
  amountToTransfer?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  destinationStudentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  itemsToTransfer?: Resolver<Array<GQLResolversTypes['ItemToTransfer']>, ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  sourceStudentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogTransferPurchaseSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogUpdateLessonResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogUpdateLesson'] = GQLResolversParentTypes['LedgerLogUpdateLesson']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lessonId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentIds?: Resolver<Array<GQLResolversTypes['ID']>, ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogUpdateLessonSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogVoidExpirationResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogVoidExpiration'] = GQLResolversParentTypes['LedgerLogVoidExpiration']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  expirationId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogVoidExpirationSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogVoidPurchaseResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogVoidPurchase'] = GQLResolversParentTypes['LedgerLogVoidPurchase']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogVoidPurchaseSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogVoidRefundResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogVoidRefund'] = GQLResolversParentTypes['LedgerLogVoidRefund']> = ResolversObject<{
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  refundId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  summary?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLLedgerLogVoidRefundSummaryArgs, 'studentId'>>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['LedgerLogTypes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogsEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogsEdge'] = GQLResolversParentTypes['LedgerLogsEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['LedgerLog'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLedgerLogsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LedgerLogsPaginatedList'] = GQLResolversParentTypes['LedgerLogsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['LedgerLogsEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLegacyLogSummaryResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LegacyLogSummary'] = GQLResolversParentTypes['LegacyLogSummary']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Lesson'] = GQLResolversParentTypes['Lesson']> = ResolversObject<{
  actualDurationMinutes?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  actualDurationNote?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  bookedAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  comments?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  durationMinutes?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  endTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  hasHomePickup?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<GQLResolversTypes['Instructor'], ParentType, ContextType>;
  instructorId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isCancelled?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  isObserverAllowed?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  isShareable?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  locationId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  meetingPointId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  pickupLocation?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  pickupLocationCoords?: Resolver<Maybe<GQLResolversTypes['LatLng']>, ParentType, ContextType>;
  price?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  studentDetails?: Resolver<GQLResolversTypes['LessonStudentDetails'], ParentType, ContextType, RequireFields<GQLLessonStudentDetailsArgs, 'studentId'>>;
  studentDetailsList?: Resolver<Array<GQLResolversTypes['LessonStudentDetails']>, ParentType, ContextType>;
  studentIds?: Resolver<Array<GQLResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonCouponResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonCoupon'] = GQLResolversParentTypes['LessonCoupon']> = ResolversObject<{
  code?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  discount?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  displayInLandingPages?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  expiration?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  regionId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  regions?: Resolver<Array<GQLResolversTypes['Region']>, ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['CouponType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonDrivingSkillRatingResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonDrivingSkillRating'] = GQLResolversParentTypes['LessonDrivingSkillRating']> = ResolversObject<{
  drivingSkill?: Resolver<GQLResolversTypes['DrivingSkill'], ParentType, ContextType>;
  drivingSkillId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType>;
  lessonId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  rating?: Resolver<GQLResolversTypes['DrivingSkillRating'], ParentType, ContextType>;
  student?: Resolver<GQLResolversTypes['Student'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonDrivingSkillsReportResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonDrivingSkillsReport'] = GQLResolversParentTypes['LessonDrivingSkillsReport']> = ResolversObject<{
  drivingSkillsRatings?: Resolver<Array<GQLResolversTypes['LessonDrivingSkillRating']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lastEmailSent?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  lesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonDrivingSkillsReportEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonDrivingSkillsReportEdge'] = GQLResolversParentTypes['LessonDrivingSkillsReportEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['LessonDrivingSkillsReport'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonDrivingSkillsReportPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonDrivingSkillsReportPaginatedList'] = GQLResolversParentTypes['LessonDrivingSkillsReportPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['LessonDrivingSkillsReportEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonEdge'] = GQLResolversParentTypes['LessonEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonPackageResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonPackage'] = GQLResolversParentTypes['LessonPackage']> = ResolversObject<{
  drivingMinutes?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  isSharedLessons?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  numLessons?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  numRoadTests?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  observationMinutes?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  totalMinutes?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonReviewResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonReview'] = GQLResolversParentTypes['LessonReview']> = ResolversObject<{
  date?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<GQLResolversTypes['Instructor'], ParentType, ContextType>;
  instructorId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  rating?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  source?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  student?: Resolver<GQLResolversTypes['Student'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  text?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonReviewEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonReviewEdge'] = GQLResolversParentTypes['LessonReviewEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['LessonReview'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonStudentDetailsResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonStudentDetails'] = GQLResolversParentTypes['LessonStudentDetails']> = ResolversObject<{
  dmvConfirmationCode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  dmvLocation?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  hasReview?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  hasSentReportToStudent?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  isObserverAllowed?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  lessonId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  pickupDetails?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  productQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType, RequireFields<GQLLessonStudentDetailsProductQuantityArgs, 'productId'>>;
  productQuantityList?: Resolver<Array<GQLResolversTypes['ProductQuantity']>, ParentType, ContextType>;
  student?: Resolver<GQLResolversTypes['Student'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonTypeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonType'] = GQLResolversParentTypes['LessonType']> = ResolversObject<{
  description?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  durationMinutes?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  features?: Resolver<Array<GQLResolversTypes['LessonTypeFeature']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonTypeEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonTypeEdge'] = GQLResolversParentTypes['LessonTypeEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['LessonType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonTypeFeatureResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonTypeFeature'] = GQLResolversParentTypes['LessonTypeFeature']> = ResolversObject<{
  content?: Resolver<Array<GQLResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<GQLResolversTypes['USState'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonTypesPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonTypesPaginatedList'] = GQLResolversParentTypes['LessonTypesPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['LessonTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonsPaginatedList'] = GQLResolversParentTypes['LessonsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['LessonEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLessonsReviewsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LessonsReviewsPaginatedList'] = GQLResolversParentTypes['LessonsReviewsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['LessonReviewEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLocationResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Location'] = GQLResolversParentTypes['Location']> = ResolversObject<{
  address?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  lat?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  lng?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  neighborhood?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  neighborhoodLat?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  neighborhoodLng?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLogChangeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LogChange'] = GQLResolversParentTypes['LogChange']> = ResolversObject<{
  __resolveType: TypeResolveFn<'LogDateTimeChange' | 'LogFloatChange' | 'LogIntChange' | 'LogStringChange', ParentType, ContextType>;
}>;

export type GQLLogChangeBaseResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LogChangeBase'] = GQLResolversParentTypes['LogChangeBase']> = ResolversObject<{
  __resolveType: TypeResolveFn<'LogDateTimeChange' | 'LogFloatChange' | 'LogIntChange' | 'LogStringChange', ParentType, ContextType>;
  path?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  valueType?: Resolver<GQLResolversTypes['LogChangeValueType'], ParentType, ContextType>;
}>;

export type GQLLogDateTimeChangeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LogDateTimeChange'] = GQLResolversParentTypes['LogDateTimeChange']> = ResolversObject<{
  newDateTime?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  oldDateTime?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  path?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  valueType?: Resolver<GQLResolversTypes['LogChangeValueType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLogFloatChangeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LogFloatChange'] = GQLResolversParentTypes['LogFloatChange']> = ResolversObject<{
  newFloat?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  oldFloat?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  path?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  valueType?: Resolver<GQLResolversTypes['LogChangeValueType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLogIntChangeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LogIntChange'] = GQLResolversParentTypes['LogIntChange']> = ResolversObject<{
  newInt?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  oldInt?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  path?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  valueType?: Resolver<GQLResolversTypes['LogChangeValueType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLLogStringChangeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['LogStringChange'] = GQLResolversParentTypes['LogStringChange']> = ResolversObject<{
  newString?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  oldString?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  path?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  valueType?: Resolver<GQLResolversTypes['LogChangeValueType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLMutationResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Mutation'] = GQLResolversParentTypes['Mutation']> = ResolversObject<{
  addInstructor?: Resolver<GQLResolversTypes['Instructor'], ParentType, ContextType, RequireFields<GQLMutationAddInstructorArgs, 'instructor'>>;
  addMakeUpSessionInformation?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationAddMakeUpSessionInformationArgs, 'input'>>;
  addStudent?: Resolver<GQLResolversTypes['Student'], ParentType, ContextType, RequireFields<GQLMutationAddStudentArgs, 'student'>>;
  addToCart?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLMutationAddToCartArgs, 'input'>>;
  addWaitlistComment?: Resolver<GQLResolversTypes['WaitlistComment'], ParentType, ContextType, RequireFields<GQLMutationAddWaitlistCommentArgs, 'comment'>>;
  applyForScholarship?: Resolver<GQLResolversTypes['ScholarshipApplicationResponse'], ParentType, ContextType, RequireFields<GQLMutationApplyForScholarshipArgs, 'courseId' | 'dob' | 'email' | 'scholarshipAnswer' | 'userName'>>;
  batchDeleteAvails?: Resolver<GQLResolversTypes['BatchDeleteAvailsResult'], ParentType, ContextType, RequireFields<GQLMutationBatchDeleteAvailsArgs, 'query'>>;
  batchEditAvails?: Resolver<GQLResolversTypes['BatchEditAvailsResult'], ParentType, ContextType, RequireFields<GQLMutationBatchEditAvailsArgs, 'query' | 'update'>>;
  cancelLesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType, RequireFields<GQLMutationCancelLessonArgs, 'input' | 'query'>>;
  cancelSyncCheck?: Resolver<Maybe<GQLResolversTypes['SyncCheck']>, ParentType, ContextType, RequireFields<GQLMutationCancelSyncCheckArgs, 'id'>>;
  clearCoupon?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLMutationClearCouponArgs, 'input'>>;
  clearPackages?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLMutationClearPackagesArgs, 'input'>>;
  completeOrder?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLMutationCompleteOrderArgs, 'input'>>;
  completeOrderWithSlots?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLMutationCompleteOrderWithSlotsArgs, 'input'>>;
  createAvailability?: Resolver<GQLResolversTypes['Availability'], ParentType, ContextType, RequireFields<GQLMutationCreateAvailabilityArgs, 'input'>>;
  createCart?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLMutationCreateCartArgs, 'input'>>;
  createCoupon?: Resolver<GQLResolversTypes['Coupon'], ParentType, ContextType, RequireFields<GQLMutationCreateCouponArgs, 'input'>>;
  createInstructorAlert?: Resolver<GQLResolversTypes['InstructorAlert'], ParentType, ContextType, RequireFields<GQLMutationCreateInstructorAlertArgs, 'input'>>;
  createInstructorSchedule?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType, RequireFields<GQLMutationCreateInstructorScheduleArgs, 'input'>>;
  createInstructorTimeLog?: Resolver<GQLResolversTypes['InstructorTimeLog'], ParentType, ContextType, RequireFields<GQLMutationCreateInstructorTimeLogArgs, 'input'>>;
  createLessonReview?: Resolver<GQLResolversTypes['LessonReview'], ParentType, ContextType, RequireFields<GQLMutationCreateLessonReviewArgs, 'input'>>;
  createLessonType?: Resolver<GQLResolversTypes['LessonType'], ParentType, ContextType, RequireFields<GQLMutationCreateLessonTypeArgs, 'input'>>;
  createMultipleAvailabilities?: Resolver<GQLResolversTypes['BatchAddAvailsResult'], ParentType, ContextType, RequireFields<GQLMutationCreateMultipleAvailabilitiesArgs, 'input'>>;
  createPriceScheme?: Resolver<GQLResolversTypes['PriceScheme'], ParentType, ContextType, RequireFields<GQLMutationCreatePriceSchemeArgs, 'input'>>;
  createPriceSchemeFragment?: Resolver<GQLResolversTypes['PriceSchemeFragment'], ParentType, ContextType, RequireFields<GQLMutationCreatePriceSchemeFragmentArgs, 'input'>>;
  createProduct?: Resolver<GQLResolversTypes['Product'], ParentType, ContextType, RequireFields<GQLMutationCreateProductArgs, 'product'>>;
  createPurchase?: Resolver<GQLResolversTypes['Purchase'], ParentType, ContextType, RequireFields<GQLMutationCreatePurchaseArgs, 'input'>>;
  createSessionCookie?: Resolver<GQLResolversTypes['String'], ParentType, ContextType, RequireFields<GQLMutationCreateSessionCookieArgs, 'session'>>;
  createStateConfig?: Resolver<GQLResolversTypes['StateConfig'], ParentType, ContextType, RequireFields<GQLMutationCreateStateConfigArgs, 'input'>>;
  createStateMeetingPoint?: Resolver<GQLResolversTypes['StateMeetingPoint'], ParentType, ContextType, RequireFields<GQLMutationCreateStateMeetingPointArgs, 'input'>>;
  createSuperRegion?: Resolver<GQLResolversTypes['SuperRegion'], ParentType, ContextType, RequireFields<GQLMutationCreateSuperRegionArgs, 'input'>>;
  createTransientSlot?: Resolver<GQLResolversTypes['TransientSlot'], ParentType, ContextType, RequireFields<GQLMutationCreateTransientSlotArgs, 'input'>>;
  createVoucher?: Resolver<GQLResolversTypes['Voucher'], ParentType, ContextType, RequireFields<GQLMutationCreateVoucherArgs, 'voucher'>>;
  deleteCoupon?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeleteCouponArgs, 'id'>>;
  deleteInstructorAlert?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeleteInstructorAlertArgs, 'input'>>;
  deleteInstructorSchedule?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType, RequireFields<GQLMutationDeleteInstructorScheduleArgs, 'query'>>;
  deleteInstructorTimeLog?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeleteInstructorTimeLogArgs, 'query'>>;
  deleteLessonType?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationDeleteLessonTypeArgs, 'id'>>;
  deleteNote?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeleteNoteArgs, 'id' | 'userId'>>;
  deletePriceSchemeFragment?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeletePriceSchemeFragmentArgs, 'id'>>;
  deleteProduct?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeleteProductArgs, 'id'>>;
  deleteStateMeetingPoint?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeleteStateMeetingPointArgs, 'id' | 'state'>>;
  deleteSuperRegion?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeleteSuperRegionArgs, 'id'>>;
  deleteTestFirebaseUser?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationDeleteTestFirebaseUserArgs, 'id'>>;
  deleteTransientSlot?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeleteTransientSlotArgs, 'transientSlotId'>>;
  deleteVehicle?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeleteVehicleArgs, 'id'>>;
  deleteVoucher?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationDeleteVoucherArgs, 'id'>>;
  disableStudentAccount?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationDisableStudentAccountArgs, 'studentId'>>;
  editPurchase?: Resolver<GQLResolversTypes['Purchase'], ParentType, ContextType, RequireFields<GQLMutationEditPurchaseArgs, 'input'>>;
  emailStudentReport?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationEmailStudentReportArgs, 'input'>>;
  enrollStudentToCohort?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationEnrollStudentToCohortArgs, 'input'>>;
  enrollStudentToOnlineCourse?: Resolver<GQLResolversTypes['StudentOnlineCourse'], ParentType, ContextType, RequireFields<GQLMutationEnrollStudentToOnlineCourseArgs, 'input'>>;
  expireProducts?: Resolver<GQLResolversTypes['Expiration'], ParentType, ContextType, RequireFields<GQLMutationExpireProductsArgs, 'input'>>;
  impersonateUser?: Resolver<GQLResolversTypes['ImpersonateUserResponse'], ParentType, ContextType, RequireFields<GQLMutationImpersonateUserArgs, 'userId'>>;
  instructorUpdateLesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType, RequireFields<GQLMutationInstructorUpdateLessonArgs, 'query' | 'update'>>;
  migrateStudentAccount?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationMigrateStudentAccountArgs, 'input'>>;
  migrateStudentOnlineCourse?: Resolver<GQLResolversTypes['StudentCourseMigration'], ParentType, ContextType, RequireFields<GQLMutationMigrateStudentOnlineCourseArgs, 'input'>>;
  putBookingAlert?: Resolver<GQLResolversTypes['BookingAlert'], ParentType, ContextType, RequireFields<GQLMutationPutBookingAlertArgs, 'bookingAlert'>>;
  putCertificate?: Resolver<GQLResolversTypes['Certificate'], ParentType, ContextType, RequireFields<GQLMutationPutCertificateArgs, 'certificate'>>;
  putNote?: Resolver<GQLResolversTypes['Note'], ParentType, ContextType, RequireFields<GQLMutationPutNoteArgs, 'note'>>;
  putReview?: Resolver<GQLResolversTypes['FirebaseLessonReview'], ParentType, ContextType, RequireFields<GQLMutationPutReviewArgs, 'query'>>;
  putTestChargeLedgerLog?: Resolver<GQLResolversTypes['LedgerLog'], ParentType, ContextType, RequireFields<GQLMutationPutTestChargeLedgerLogArgs, 'input'>>;
  putTestLesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType, RequireFields<GQLMutationPutTestLessonArgs, 'input'>>;
  putTestProduct?: Resolver<GQLResolversTypes['Product'], ParentType, ContextType, RequireFields<GQLMutationPutTestProductArgs, 'product'>>;
  putTestRegions?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationPutTestRegionsArgs, 'input'>>;
  putTestUser?: Resolver<GQLResolversTypes['Student'], ParentType, ContextType, RequireFields<GQLMutationPutTestUserArgs, 'input'>>;
  putVehicle?: Resolver<GQLResolversTypes['Vehicle'], ParentType, ContextType, RequireFields<GQLMutationPutVehicleArgs, 'vehicle'>>;
  putWaitlistStudent?: Resolver<GQLResolversTypes['WaitlistStudent'], ParentType, ContextType, RequireFields<GQLMutationPutWaitlistStudentArgs, 'waitlistStudent'>>;
  redeemVoucher?: Resolver<GQLResolversTypes['RedeemVoucherResult'], ParentType, ContextType, RequireFields<GQLMutationRedeemVoucherArgs, 'voucher'>>;
  refreshTransientSlot?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationRefreshTransientSlotArgs, 'transientSlotId'>>;
  refundPurchase?: Resolver<GQLResolversTypes['Refund'], ParentType, ContextType, RequireFields<GQLMutationRefundPurchaseArgs, 'input'>>;
  removeBookingAlert?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType, RequireFields<GQLMutationRemoveBookingAlertArgs, 'bookingAlert'>>;
  removeFromCart?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLMutationRemoveFromCartArgs, 'input'>>;
  removeStudentFromLesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType, RequireFields<GQLMutationRemoveStudentFromLessonArgs, 'input' | 'query'>>;
  removeWaitlist?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationRemoveWaitlistArgs, 'id'>>;
  resetTestDb?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  resetTestLedgerLogs?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  saveCohort?: Resolver<GQLResolversTypes['Cohort'], ParentType, ContextType, RequireFields<GQLMutationSaveCohortArgs, 'input'>>;
  saveCohortSession?: Resolver<GQLResolversTypes['CohortSession'], ParentType, ContextType, RequireFields<GQLMutationSaveCohortSessionArgs, 'input'>>;
  scheduleLesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType, RequireFields<GQLMutationScheduleLessonArgs, 'input'>>;
  scheduleMultipleLessons?: Resolver<Array<GQLResolversTypes['Lesson']>, ParentType, ContextType, RequireFields<GQLMutationScheduleMultipleLessonsArgs, 'input'>>;
  selfBookSharedLesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType, RequireFields<GQLMutationSelfBookSharedLessonArgs, 'input'>>;
  setCoupon?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLMutationSetCouponArgs, 'input'>>;
  setPaymentStatus?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLMutationSetPaymentStatusArgs, 'input'>>;
  setStudentId?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLMutationSetStudentIdArgs, 'input'>>;
  signOut?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  startSyncCheck?: Resolver<Maybe<GQLResolversTypes['SyncCheck']>, ParentType, ContextType>;
  submitCoursePrepTestChapterScores?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationSubmitCoursePrepTestChapterScoresArgs, 'input'>>;
  transferAccount?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationTransferAccountArgs, 'input'>>;
  transferExpiration?: Resolver<GQLResolversTypes['Expiration'], ParentType, ContextType, RequireFields<GQLMutationTransferExpirationArgs, 'input'>>;
  transferLesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType, RequireFields<GQLMutationTransferLessonArgs, 'input'>>;
  transferPurchase?: Resolver<GQLResolversTypes['Purchase'], ParentType, ContextType, RequireFields<GQLMutationTransferPurchaseArgs, 'input'>>;
  unenrollStudentFromCohort?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationUnenrollStudentFromCohortArgs, 'input'>>;
  unenrollStudentFromOnlineCourse?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationUnenrollStudentFromOnlineCourseArgs, 'input'>>;
  updateAvailability?: Resolver<GQLResolversTypes['Availability'], ParentType, ContextType, RequireFields<GQLMutationUpdateAvailabilityArgs, 'input' | 'query'>>;
  updateCoupon?: Resolver<GQLResolversTypes['Coupon'], ParentType, ContextType, RequireFields<GQLMutationUpdateCouponArgs, 'id' | 'input'>>;
  updateInstructor?: Resolver<GQLResolversTypes['Instructor'], ParentType, ContextType, RequireFields<GQLMutationUpdateInstructorArgs, 'query' | 'update'>>;
  updateInstructorAlert?: Resolver<GQLResolversTypes['InstructorAlert'], ParentType, ContextType, RequireFields<GQLMutationUpdateInstructorAlertArgs, 'input'>>;
  updateInstructorSchedule?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType, RequireFields<GQLMutationUpdateInstructorScheduleArgs, 'input' | 'query'>>;
  updateInstructorTimeLog?: Resolver<GQLResolversTypes['InstructorTimeLog'], ParentType, ContextType, RequireFields<GQLMutationUpdateInstructorTimeLogArgs, 'input' | 'query'>>;
  updateLesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType, RequireFields<GQLMutationUpdateLessonArgs, 'query' | 'update'>>;
  updateLessonReportForStudent?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationUpdateLessonReportForStudentArgs, 'input'>>;
  updateLessonType?: Resolver<GQLResolversTypes['LessonType'], ParentType, ContextType, RequireFields<GQLMutationUpdateLessonTypeArgs, 'input'>>;
  updatePriceScheme?: Resolver<GQLResolversTypes['PriceScheme'], ParentType, ContextType, RequireFields<GQLMutationUpdatePriceSchemeArgs, 'input'>>;
  updatePriceSchemeFragment?: Resolver<GQLResolversTypes['PriceSchemeFragment'], ParentType, ContextType, RequireFields<GQLMutationUpdatePriceSchemeFragmentArgs, 'input'>>;
  updateProduct?: Resolver<GQLResolversTypes['Product'], ParentType, ContextType, RequireFields<GQLMutationUpdateProductArgs, 'product'>>;
  updateRegionScheme?: Resolver<GQLResolversTypes['RegionScheme'], ParentType, ContextType, RequireFields<GQLMutationUpdateRegionSchemeArgs, 'input'>>;
  updateReview?: Resolver<GQLResolversTypes['FirebaseLessonReview'], ParentType, ContextType, RequireFields<GQLMutationUpdateReviewArgs, 'query'>>;
  updateStateConfig?: Resolver<GQLResolversTypes['StateConfig'], ParentType, ContextType, RequireFields<GQLMutationUpdateStateConfigArgs, 'input'>>;
  updateStateMeetingPoint?: Resolver<GQLResolversTypes['StateMeetingPoint'], ParentType, ContextType, RequireFields<GQLMutationUpdateStateMeetingPointArgs, 'input'>>;
  updateStudent?: Resolver<GQLResolversTypes['Student'], ParentType, ContextType, RequireFields<GQLMutationUpdateStudentArgs, 'student'>>;
  updateStudentCourseLectureSession?: Resolver<GQLResolversTypes['FirebaseCourseSessionTime'], ParentType, ContextType, RequireFields<GQLMutationUpdateStudentCourseLectureSessionArgs, 'input'>>;
  updateStudentCourseMeta?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationUpdateStudentCourseMetaArgs, 'input'>>;
  updateStudentCourseSession?: Resolver<GQLResolversTypes['FirebaseCourseSessionTime'], ParentType, ContextType, RequireFields<GQLMutationUpdateStudentCourseSessionArgs, 'input'>>;
  updateStudentSessionAttendance?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationUpdateStudentSessionAttendanceArgs, 'input'>>;
  updateStudentsSessionAttendance?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType, RequireFields<GQLMutationUpdateStudentsSessionAttendanceArgs, 'input'>>;
  updateSuperRegion?: Resolver<GQLResolversTypes['SuperRegion'], ParentType, ContextType, RequireFields<GQLMutationUpdateSuperRegionArgs, 'input'>>;
  updateUserRoles?: Resolver<Array<GQLResolversTypes['UserRole']>, ParentType, ContextType, RequireFields<GQLMutationUpdateUserRolesArgs, 'userId' | 'userRoles'>>;
  updateVoucher?: Resolver<GQLResolversTypes['Voucher'], ParentType, ContextType, RequireFields<GQLMutationUpdateVoucherArgs, 'voucher'>>;
  voidExpiration?: Resolver<GQLResolversTypes['Expiration'], ParentType, ContextType, RequireFields<GQLMutationVoidExpirationArgs, 'input'>>;
  voidPurchase?: Resolver<GQLResolversTypes['Purchase'], ParentType, ContextType, RequireFields<GQLMutationVoidPurchaseArgs, 'input'>>;
  voidRefund?: Resolver<GQLResolversTypes['Refund'], ParentType, ContextType, RequireFields<GQLMutationVoidRefundArgs, 'input'>>;
}>;

export type GQLMyselfResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Myself'] = GQLResolversParentTypes['Myself']> = ResolversObject<{
  email?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Array<GQLResolversTypes['String']>, ParentType, ContextType>;
  signInEmail?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLNoteResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Note'] = GQLResolversParentTypes['Note']> = ResolversObject<{
  createdAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<GQLResolversTypes['Person'], ParentType, ContextType>;
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isHiddenFromInstructorView?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  isPinned?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  text?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLNoteEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['NoteEdge'] = GQLResolversParentTypes['NoteEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Note'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLNotesPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['NotesPaginatedList'] = GQLResolversParentTypes['NotesPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['NoteEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLOnlineCourseContentResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['OnlineCourseContent'] = GQLResolversParentTypes['OnlineCourseContent']> = ResolversObject<{
  anvilPdfIds?: Resolver<GQLResolversTypes['AnvilPdfIds'], ParentType, ContextType>;
  anvilPdfTemplateId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  branchLicenseNumber?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  certificateCourseName?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  certificateIncludeMeetsRequirements?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  certificateInstructor?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  challengeRequired?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  completionFlow?: Resolver<Array<GQLResolversTypes['String']>, ParentType, ContextType>;
  courseAgeMinimum?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  courseTypeId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  dailyTimeLimit?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  datoStateId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  dmvPreRegistrationLink?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  externalCourseId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  failTwiceReview?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  hasCertificate?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  hasControlNumber?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  hasDrivingLessons?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  hasShipping?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  hasTimer?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  hasWeakSecurityMeasures?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  inactivityCheck?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  instructionsText?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  isClassroomCourse?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  isEnrollmentOpen?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  isExternalCourse?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  isGuideRequired?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  lectureIds?: Resolver<Array<GQLResolversTypes['ID']>, ParentType, ContextType>;
  licenseNumber?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  link?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  locationId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  maxEnrollees?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  minPercentCorrect?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  notarizedFormLink?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  numLectures?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  numTests?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  requireOrder?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  requiredHours?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  requiresDmvPreRegistration?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  requiresNotarizedForm?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  requiresSampleCertificateCheck?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  slug?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  stateLongName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  stateShortName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  testLectures?: Resolver<Array<GQLResolversTypes['CourseTestLecture']>, ParentType, ContextType>;
  timerLockedLectureId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  topics?: Resolver<Array<GQLResolversTypes['CourseTopic']>, ParentType, ContextType>;
  verifyAddressText?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPackagePriceResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PackagePrice'] = GQLResolversParentTypes['PackagePrice']> = ResolversObject<{
  banner?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  couponDiscountAmount?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  discountAmount?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  extras?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  hasCourse?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  hasLessons?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  lessonPackage?: Resolver<Maybe<GQLResolversTypes['LessonPackage']>, ParentType, ContextType>;
  msrp?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  price?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  state?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPackageWithPricesResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PackageWithPrices'] = GQLResolversParentTypes['PackageWithPrices']> = ResolversObject<{
  banner?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  couponDiscountAmount?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  courseId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  courseType?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  didApplyCoupon?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  discountAmount?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  extras?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  hasCertificateShipping?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  hasCourse?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  hasLessons?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  lessonPackage?: Resolver<Maybe<GQLResolversTypes['LessonPackage']>, ParentType, ContextType>;
  msrp?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  price?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  state?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPageInfoResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PageInfo'] = GQLResolversParentTypes['PageInfo']> = ResolversObject<{
  endCursor?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  hasNextPage?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPersonResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Person'] = GQLResolversParentTypes['Person']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPersonEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PersonEdge'] = GQLResolversParentTypes['PersonEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Person'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPersonPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PersonPaginatedList'] = GQLResolversParentTypes['PersonPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['PersonEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPriceSchemeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PriceScheme'] = GQLResolversParentTypes['PriceScheme']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  priceSchemeFragments?: Resolver<Array<GQLResolversTypes['PriceSchemeFragment']>, ParentType, ContextType>;
  products?: Resolver<Array<GQLResolversTypes['PriceSchemeProductLink']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPriceSchemeEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PriceSchemeEdge'] = GQLResolversParentTypes['PriceSchemeEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['PriceScheme'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPriceSchemeFragmentResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PriceSchemeFragment'] = GQLResolversParentTypes['PriceSchemeFragment']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  priceSchemeIds?: Resolver<Array<GQLResolversTypes['ID']>, ParentType, ContextType>;
  priceSchemes?: Resolver<Array<GQLResolversTypes['PriceSchemeInfo']>, ParentType, ContextType>;
  productPrices?: Resolver<Array<GQLResolversTypes['ProductPrice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPriceSchemeFragmentEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PriceSchemeFragmentEdge'] = GQLResolversParentTypes['PriceSchemeFragmentEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['PriceSchemeFragment'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPriceSchemeFragmentPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PriceSchemeFragmentPaginatedList'] = GQLResolversParentTypes['PriceSchemeFragmentPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['PriceSchemeFragmentEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPriceSchemeInfoResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PriceSchemeInfo'] = GQLResolversParentTypes['PriceSchemeInfo']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPriceSchemePaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PriceSchemePaginatedList'] = GQLResolversParentTypes['PriceSchemePaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['PriceSchemeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPriceSchemeProductLinkResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PriceSchemeProductLink'] = GQLResolversParentTypes['PriceSchemeProductLink']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  productLinks?: Resolver<Array<GQLResolversTypes['ProductLink']>, ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Product'] = GQLResolversParentTypes['Product']> = ResolversObject<{
  description?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType, RequireFields<GQLProductPriceArgs, 'address'>>;
  products?: Resolver<Maybe<Array<GQLResolversTypes['ProductLink']>>, ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductBalanceResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductBalance'] = GQLResolversParentTypes['ProductBalance']> = ResolversObject<{
  availableQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  expiredQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  purchasedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  receivedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  refundedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  sentQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  usedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  voidedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductEdge'] = GQLResolversParentTypes['ProductEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Product'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductLinkResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductLink'] = GQLResolversParentTypes['ProductLink']> = ResolversObject<{
  product?: Resolver<GQLResolversTypes['Product'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  productName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductPriceResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductPrice'] = GQLResolversParentTypes['ProductPrice']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  priceAlgo?: Resolver<GQLResolversTypes['ProductPriceAlgo'], ParentType, ContextType>;
  priceSchemeFragmentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  product?: Resolver<GQLResolversTypes['Product'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductPriceAlgoResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductPriceAlgo'] = GQLResolversParentTypes['ProductPriceAlgo']> = ResolversObject<{
  __resolveType: TypeResolveFn<'ProductPriceAlgoDollarDiscount' | 'ProductPriceAlgoDollarThenPercentDiscount' | 'ProductPriceAlgoFixed' | 'ProductPriceAlgoPercentDiscount' | 'ProductPriceAlgoPercentThenDollarDiscount', ParentType, ContextType>;
}>;

export type GQLProductPriceAlgoBaseResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductPriceAlgoBase'] = GQLResolversParentTypes['ProductPriceAlgoBase']> = ResolversObject<{
  __resolveType: TypeResolveFn<'ProductPriceAlgoDollarDiscount' | 'ProductPriceAlgoDollarThenPercentDiscount' | 'ProductPriceAlgoFixed' | 'ProductPriceAlgoPercentDiscount' | 'ProductPriceAlgoPercentThenDollarDiscount', ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductPriceAlgoType'], ParentType, ContextType>;
}>;

export type GQLProductPriceAlgoDollarDiscountResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductPriceAlgoDollarDiscount'] = GQLResolversParentTypes['ProductPriceAlgoDollarDiscount']> = ResolversObject<{
  dollarDiscount?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  rounding?: Resolver<GQLResolversTypes['ProductPriceRounding'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductPriceAlgoType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductPriceAlgoDollarThenPercentDiscountResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductPriceAlgoDollarThenPercentDiscount'] = GQLResolversParentTypes['ProductPriceAlgoDollarThenPercentDiscount']> = ResolversObject<{
  dollarDiscount?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  percentDiscount?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  rounding?: Resolver<GQLResolversTypes['ProductPriceRounding'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductPriceAlgoType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductPriceAlgoFixedResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductPriceAlgoFixed'] = GQLResolversParentTypes['ProductPriceAlgoFixed']> = ResolversObject<{
  fixedPrice?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductPriceAlgoType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductPriceAlgoPercentDiscountResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductPriceAlgoPercentDiscount'] = GQLResolversParentTypes['ProductPriceAlgoPercentDiscount']> = ResolversObject<{
  percentDiscount?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  rounding?: Resolver<GQLResolversTypes['ProductPriceRounding'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductPriceAlgoType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductPriceAlgoPercentThenDollarDiscountResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductPriceAlgoPercentThenDollarDiscount'] = GQLResolversParentTypes['ProductPriceAlgoPercentThenDollarDiscount']> = ResolversObject<{
  dollarDiscount?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  percentDiscount?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  rounding?: Resolver<GQLResolversTypes['ProductPriceRounding'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductPriceAlgoType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductPriceRoundingResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductPriceRounding'] = GQLResolversParentTypes['ProductPriceRounding']> = ResolversObject<{
  type?: Resolver<GQLResolversTypes['ProductPriceRoundingType'], ParentType, ContextType>;
  value?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductQuantityResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductQuantity'] = GQLResolversParentTypes['ProductQuantity']> = ResolversObject<{
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLProductsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ProductsPaginatedList'] = GQLResolversParentTypes['ProductsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['ProductEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPurchaseResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Purchase'] = GQLResolversParentTypes['Purchase']> = ResolversObject<{
  categoryId?: Resolver<GQLResolversTypes['PaymentCategories'], ParentType, ContextType>;
  couponCode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  couponDiscount?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  items?: Resolver<Array<GQLResolversTypes['AddedPurchaseItem']>, ParentType, ContextType>;
  paymentCaptureDate?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  stripeChargeId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  stripeCustomerId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  stripePaymentIntentId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  usStateId?: Resolver<GQLResolversTypes['USStateId'], ParentType, ContextType>;
  versionId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  voucherCode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPurchaseItemResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PurchaseItem'] = GQLResolversParentTypes['PurchaseItem']> = ResolversObject<{
  __resolveType: TypeResolveFn<'PurchasePackageItem' | 'PurchaseProductItem', ParentType, ContextType>;
}>;

export type GQLPurchaseItemUpdateResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PurchaseItemUpdate'] = GQLResolversParentTypes['PurchaseItemUpdate']> = ResolversObject<{
  __resolveType: TypeResolveFn<'AddedPurchasePackage' | 'AddedPurchaseProduct' | 'ExistingPurchaseItemUpdate', ParentType, ContextType>;
}>;

export type GQLPurchasePackageItemResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PurchasePackageItem'] = GQLResolversParentTypes['PurchasePackageItem']> = ResolversObject<{
  itemId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  items?: Resolver<Maybe<Array<GQLResolversTypes['PurchaseProductItem']>>, ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  priceDollars?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLPurchaseProductItemResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['PurchaseProductItem'] = GQLResolversParentTypes['PurchaseProductItem']> = ResolversObject<{
  basePriceDollars?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  itemId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  quantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<GQLResolversTypes['ProductType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLQuantityByIndicatorResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['QuantityByIndicator'] = GQLResolversParentTypes['QuantityByIndicator']> = ResolversObject<{
  quantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  syncIndicator?: Resolver<GQLResolversTypes['SyncIndicator'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLQueryResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Query'] = GQLResolversParentTypes['Query']> = ResolversObject<{
  activeInstructors?: Resolver<Array<GQLResolversTypes['Instructor']>, ParentType, ContextType>;
  auditLogs?: Resolver<Maybe<GQLResolversTypes['AuditLogsPaginatedList']>, ParentType, ContextType, RequireFields<GQLQueryAuditLogsArgs, 'query'>>;
  availabilities?: Resolver<Array<GQLResolversTypes['Availability']>, ParentType, ContextType, RequireFields<GQLQueryAvailabilitiesArgs, 'query'>>;
  availableLessonSlots?: Resolver<Array<GQLResolversTypes['AvailabilitySlotPerDay']>, ParentType, ContextType, RequireFields<GQLQueryAvailableLessonSlotsArgs, 'query'>>;
  bookingAlerts?: Resolver<GQLResolversTypes['BookingAlertsPaginatedList'], ParentType, ContextType, RequireFields<GQLQueryBookingAlertsArgs, 'query'>>;
  cart?: Resolver<GQLResolversTypes['Cart'], ParentType, ContextType, RequireFields<GQLQueryCartArgs, 'id'>>;
  certificateTypes?: Resolver<Array<GQLResolversTypes['CertificateType']>, ParentType, ContextType>;
  certificates?: Resolver<GQLResolversTypes['CertificatesPaginatedList'], ParentType, ContextType, Partial<GQLQueryCertificatesArgs>>;
  cohort?: Resolver<GQLResolversTypes['Cohort'], ParentType, ContextType, RequireFields<GQLQueryCohortArgs, 'id'>>;
  cohorts?: Resolver<Array<GQLResolversTypes['Cohort']>, ParentType, ContextType, RequireFields<GQLQueryCohortsArgs, 'courseId'>>;
  cohortsForStudent?: Resolver<Array<GQLResolversTypes['Cohort']>, ParentType, ContextType, RequireFields<GQLQueryCohortsForStudentArgs, 'courseId' | 'studentId'>>;
  coupons?: Resolver<GQLResolversTypes['CouponsPaginatedList'], ParentType, ContextType, Partial<GQLQueryCouponsArgs>>;
  courseById?: Resolver<GQLResolversTypes['OnlineCourseContent'], ParentType, ContextType, RequireFields<GQLQueryCourseByIdArgs, 'id'>>;
  courseBySlug?: Resolver<GQLResolversTypes['OnlineCourseContent'], ParentType, ContextType, RequireFields<GQLQueryCourseBySlugArgs, 'slug'>>;
  courseCoupons?: Resolver<Array<Maybe<GQLResolversTypes['CourseCoupon']>>, ParentType, ContextType, RequireFields<GQLQueryCourseCouponsArgs, 'courseId'>>;
  coursePrepTest?: Resolver<GQLResolversTypes['CoursePrepTest'], ParentType, ContextType, RequireFields<GQLQueryCoursePrepTestArgs, 'courseId'>>;
  coursePrepTestScores?: Resolver<Array<GQLResolversTypes['CoursePrepTestChapterScores']>, ParentType, ContextType, RequireFields<GQLQueryCoursePrepTestScoresArgs, 'courseId'>>;
  courses?: Resolver<Array<GQLResolversTypes['OnlineCourseContent']>, ParentType, ContextType, Partial<GQLQueryCoursesArgs>>;
  coursesWithIds?: Resolver<Array<GQLResolversTypes['OnlineCourseContent']>, ParentType, ContextType, RequireFields<GQLQueryCoursesWithIdsArgs, 'ids'>>;
  drivingSkills?: Resolver<Array<GQLResolversTypes['DrivingSkill']>, ParentType, ContextType>;
  expiration?: Resolver<GQLResolversTypes['Expiration'], ParentType, ContextType, RequireFields<GQLQueryExpirationArgs, 'query'>>;
  getReview?: Resolver<GQLResolversTypes['FirebaseLessonReview'], ParentType, ContextType, RequireFields<GQLQueryGetReviewArgs, 'input'>>;
  getStudentBalance?: Resolver<GQLResolversTypes['StudentBalance'], ParentType, ContextType, RequireFields<GQLQueryGetStudentBalanceArgs, 'studentId'>>;
  getTestStudent?: Resolver<Maybe<GQLResolversTypes['Student']>, ParentType, ContextType, RequireFields<GQLQueryGetTestStudentArgs, 'studentId'>>;
  instructor?: Resolver<GQLResolversTypes['Instructor'], ParentType, ContextType, RequireFields<GQLQueryInstructorArgs, 'instructorId'>>;
  instructorAlerts?: Resolver<GQLResolversTypes['InstructorAlertPaginatedList'], ParentType, ContextType, Partial<GQLQueryInstructorAlertsArgs>>;
  instructorBadges?: Resolver<Array<GQLResolversTypes['InstructorBadge']>, ParentType, ContextType, RequireFields<GQLQueryInstructorBadgesArgs, 'query'>>;
  instructorSchedule?: Resolver<GQLResolversTypes['InstructorSchedule'], ParentType, ContextType, RequireFields<GQLQueryInstructorScheduleArgs, 'query'>>;
  instructorSchedules?: Resolver<Maybe<Array<GQLResolversTypes['InstructorSchedule']>>, ParentType, ContextType, RequireFields<GQLQueryInstructorSchedulesArgs, 'query'>>;
  instructorTimeLogs?: Resolver<GQLResolversTypes['InstructorTimeLogPaginatedList'], ParentType, ContextType, RequireFields<GQLQueryInstructorTimeLogsArgs, 'query'>>;
  instructorZoneGeoJson?: Resolver<GQLResolversTypes['InstructorZone'], ParentType, ContextType, RequireFields<GQLQueryInstructorZoneGeoJsonArgs, 'query'>>;
  instructors?: Resolver<GQLResolversTypes['InstructorsPaginatedList'], ParentType, ContextType, Partial<GQLQueryInstructorsArgs>>;
  instructorsCoverageGeoJson?: Resolver<GQLResolversTypes['InstructorsCoverage'], ParentType, ContextType>;
  instructorsInSuperRegionForUser?: Resolver<Array<GQLResolversTypes['InstructorWithSuperRegion']>, ParentType, ContextType>;
  instructorsSchedules?: Resolver<Maybe<Array<GQLResolversTypes['InstructorSchedule']>>, ParentType, ContextType, RequireFields<GQLQueryInstructorsSchedulesArgs, 'query'>>;
  lastBookedInstructor?: Resolver<Maybe<GQLResolversTypes['LastBookedInstructor']>, ParentType, ContextType, RequireFields<GQLQueryLastBookedInstructorArgs, 'studentId'>>;
  lastCompletedSyncCheck?: Resolver<Maybe<GQLResolversTypes['SyncCheck']>, ParentType, ContextType>;
  lectureById?: Resolver<GQLResolversTypes['CourseLecture'], ParentType, ContextType, RequireFields<GQLQueryLectureByIdArgs, 'courseId' | 'lectureId'>>;
  ledgerLog?: Resolver<GQLResolversTypes['LedgerLog'], ParentType, ContextType, RequireFields<GQLQueryLedgerLogArgs, 'id'>>;
  ledgerLogs?: Resolver<GQLResolversTypes['LedgerLogsPaginatedList'], ParentType, ContextType, RequireFields<GQLQueryLedgerLogsArgs, 'query'>>;
  lesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType, RequireFields<GQLQueryLessonArgs, 'query'>>;
  lessonDrivingSkillsReportByStudentAndLesson?: Resolver<GQLResolversTypes['LessonDrivingSkillsReport'], ParentType, ContextType, RequireFields<GQLQueryLessonDrivingSkillsReportByStudentAndLessonArgs, 'query'>>;
  lessonTypeById?: Resolver<GQLResolversTypes['LessonType'], ParentType, ContextType, RequireFields<GQLQueryLessonTypeByIdArgs, 'lessonTypeId'>>;
  lessonTypes?: Resolver<GQLResolversTypes['LessonTypesPaginatedList'], ParentType, ContextType, Partial<GQLQueryLessonTypesArgs>>;
  lessonTypesForAddress?: Resolver<Array<GQLResolversTypes['LessonType']>, ParentType, ContextType, RequireFields<GQLQueryLessonTypesForAddressArgs, 'address'>>;
  lessons?: Resolver<GQLResolversTypes['LessonsPaginatedList'], ParentType, ContextType, RequireFields<GQLQueryLessonsArgs, 'query'>>;
  lessonsByInstructorIds?: Resolver<Array<GQLResolversTypes['Lesson']>, ParentType, ContextType, RequireFields<GQLQueryLessonsByInstructorIdsArgs, 'query'>>;
  lessonsDrivingSkillsReportsByStudent?: Resolver<GQLResolversTypes['LessonDrivingSkillsReportPaginatedList'], ParentType, ContextType, RequireFields<GQLQueryLessonsDrivingSkillsReportsByStudentArgs, 'query'>>;
  lessonsReviewsByInstructor?: Resolver<GQLResolversTypes['LessonsReviewsPaginatedList'], ParentType, ContextType, RequireFields<GQLQueryLessonsReviewsByInstructorArgs, 'query'>>;
  me?: Resolver<GQLResolversTypes['Myself'], ParentType, ContextType>;
  notes?: Resolver<GQLResolversTypes['NotesPaginatedList'], ParentType, ContextType, RequireFields<GQLQueryNotesArgs, 'query'>>;
  priceScheme?: Resolver<GQLResolversTypes['PriceScheme'], ParentType, ContextType, RequireFields<GQLQueryPriceSchemeArgs, 'id'>>;
  priceSchemeForAddress?: Resolver<Maybe<GQLResolversTypes['PriceScheme']>, ParentType, ContextType, RequireFields<GQLQueryPriceSchemeForAddressArgs, 'address'>>;
  priceSchemeFragment?: Resolver<GQLResolversTypes['PriceSchemeFragment'], ParentType, ContextType, RequireFields<GQLQueryPriceSchemeFragmentArgs, 'id'>>;
  priceSchemeFragments?: Resolver<GQLResolversTypes['PriceSchemeFragmentPaginatedList'], ParentType, ContextType, Partial<GQLQueryPriceSchemeFragmentsArgs>>;
  priceSchemes?: Resolver<GQLResolversTypes['PriceSchemePaginatedList'], ParentType, ContextType, Partial<GQLQueryPriceSchemesArgs>>;
  product?: Resolver<GQLResolversTypes['Product'], ParentType, ContextType, RequireFields<GQLQueryProductArgs, 'productId'>>;
  products?: Resolver<GQLResolversTypes['ProductsPaginatedList'], ParentType, ContextType, Partial<GQLQueryProductsArgs>>;
  purchase?: Resolver<GQLResolversTypes['Purchase'], ParentType, ContextType, RequireFields<GQLQueryPurchaseArgs, 'query'>>;
  reference?: Resolver<Array<GQLResolversTypes['ReferenceItem']>, ParentType, ContextType>;
  refund?: Resolver<GQLResolversTypes['Refund'], ParentType, ContextType, RequireFields<GQLQueryRefundArgs, 'query'>>;
  regionSchemes?: Resolver<GQLResolversTypes['RegionSchemePaginatedList'], ParentType, ContextType, Partial<GQLQueryRegionSchemesArgs>>;
  regionalManagers?: Resolver<GQLResolversTypes['PersonPaginatedList'], ParentType, ContextType, Partial<GQLQueryRegionalManagersArgs>>;
  regions?: Resolver<GQLResolversTypes['RegionPaginatedList'], ParentType, ContextType, Partial<GQLQueryRegionsArgs>>;
  regionsGeoJson?: Resolver<GQLResolversTypes['RegionZones'], ParentType, ContextType>;
  sharedLessons?: Resolver<Array<GQLResolversTypes['SharedLesson']>, ParentType, ContextType, RequireFields<GQLQuerySharedLessonsArgs, 'query'>>;
  stateConfigs?: Resolver<GQLResolversTypes['StateConfigPaginatedList'], ParentType, ContextType, Partial<GQLQueryStateConfigsArgs>>;
  stateMeetingPoints?: Resolver<GQLResolversTypes['StateMeetingPointPaginatedList'], ParentType, ContextType, RequireFields<GQLQueryStateMeetingPointsArgs, 'query'>>;
  student?: Resolver<Maybe<GQLResolversTypes['Student']>, ParentType, ContextType, RequireFields<GQLQueryStudentArgs, 'studentId'>>;
  studentOnlineCourse?: Resolver<GQLResolversTypes['StudentOnlineCoursesPaginatedList'], ParentType, ContextType, RequireFields<GQLQueryStudentOnlineCourseArgs, 'pageQuery' | 'studentId'>>;
  studentOnlineCourseReport?: Resolver<GQLResolversTypes['StudentOnlineCourseReport'], ParentType, ContextType, RequireFields<GQLQueryStudentOnlineCourseReportArgs, 'courseId' | 'studentId'>>;
  studentOnlineCourseTimeProgress?: Resolver<GQLResolversTypes['StudentOnlineCourseTimeProgress'], ParentType, ContextType, RequireFields<GQLQueryStudentOnlineCourseTimeProgressArgs, 'onlineCourseId' | 'studentId'>>;
  students?: Resolver<GQLResolversTypes['StudentsPaginatedList'], ParentType, ContextType, Partial<GQLQueryStudentsArgs>>;
  superRegions?: Resolver<GQLResolversTypes['SuperRegionPaginatedList'], ParentType, ContextType, Partial<GQLQuerySuperRegionsArgs>>;
  syncCheck?: Resolver<GQLResolversTypes['SyncCheck'], ParentType, ContextType, RequireFields<GQLQuerySyncCheckArgs, 'id'>>;
  syncChecks?: Resolver<GQLResolversTypes['SyncChecksPaginatedList'], ParentType, ContextType, Partial<GQLQuerySyncChecksArgs>>;
  syncConflict?: Resolver<GQLResolversTypes['SyncConflict'], ParentType, ContextType, RequireFields<GQLQuerySyncConflictArgs, 'id'>>;
  syncConflicts?: Resolver<GQLResolversTypes['SyncConflictsPaginatedList'], ParentType, ContextType, Partial<GQLQuerySyncConflictsArgs>>;
  transferAccountCheck?: Resolver<GQLResolversTypes['TransferAccountCheckResult'], ParentType, ContextType, RequireFields<GQLQueryTransferAccountCheckArgs, 'query'>>;
  transientSlots?: Resolver<Array<GQLResolversTypes['TransientSlot']>, ParentType, ContextType, RequireFields<GQLQueryTransientSlotsArgs, 'query'>>;
  usStates?: Resolver<Array<GQLResolversTypes['USState']>, ParentType, ContextType>;
  userLogs?: Resolver<Maybe<GQLResolversTypes['UserLogsPaginatedList']>, ParentType, ContextType, RequireFields<GQLQueryUserLogsArgs, 'query'>>;
  userRoles?: Resolver<Array<GQLResolversTypes['UserRole']>, ParentType, ContextType, RequireFields<GQLQueryUserRolesArgs, 'userId'>>;
  vehicleAvailabilities?: Resolver<Array<GQLResolversTypes['VehicleAvailability']>, ParentType, ContextType, RequireFields<GQLQueryVehicleAvailabilitiesArgs, 'query'>>;
  vehicleAvails?: Resolver<Array<GQLResolversTypes['VehicleAvail']>, ParentType, ContextType, RequireFields<GQLQueryVehicleAvailsArgs, 'startTime' | 'vehicleId'>>;
  vehicleMapAvailabilities?: Resolver<Array<GQLResolversTypes['VehicleMapAvailability']>, ParentType, ContextType, RequireFields<GQLQueryVehicleMapAvailabilitiesArgs, 'query'>>;
  vehicles?: Resolver<Maybe<GQLResolversTypes['VehiclesPaginatedList']>, ParentType, ContextType, Partial<GQLQueryVehiclesArgs>>;
  voucherSources?: Resolver<Array<GQLResolversTypes['VoucherSource']>, ParentType, ContextType>;
  vouchers?: Resolver<Maybe<GQLResolversTypes['VouchersPaginatedList']>, ParentType, ContextType, Partial<GQLQueryVouchersArgs>>;
  vouchersRedeemedByStudent?: Resolver<Maybe<GQLResolversTypes['VouchersPaginatedList']>, ParentType, ContextType, RequireFields<GQLQueryVouchersRedeemedByStudentArgs, 'query'>>;
  waitlist?: Resolver<GQLResolversTypes['WaitlistPaginatedList'], ParentType, ContextType, Partial<GQLQueryWaitlistArgs>>;
  waitlistComments?: Resolver<Array<GQLResolversTypes['WaitlistComment']>, ParentType, ContextType, RequireFields<GQLQueryWaitlistCommentsArgs, 'query'>>;
}>;

export type GQLRedeemVoucherResultResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['RedeemVoucherResult'] = GQLResolversParentTypes['RedeemVoucherResult']> = ResolversObject<{
  purchase?: Resolver<GQLResolversTypes['Purchase'], ParentType, ContextType>;
  voucher?: Resolver<GQLResolversTypes['Voucher'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLReferenceItemResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ReferenceItem'] = GQLResolversParentTypes['ReferenceItem']> = ResolversObject<{
  category?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  subLabel?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLRefundResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Refund'] = GQLResolversParentTypes['Refund']> = ResolversObject<{
  amountToRefund?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  categoryId?: Resolver<GQLResolversTypes['PaymentCategories'], ParentType, ContextType>;
  createdById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  description?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isVoid?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  itemsToRefund?: Resolver<Maybe<Array<GQLResolversTypes['ItemToRefund']>>, ParentType, ContextType>;
  purchaseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  stripeChargeId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  stripeCustomerId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  versionId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLRegionResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Region'] = GQLResolversParentTypes['Region']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<Maybe<GQLResolversTypes['USStateId']>, ParentType, ContextType>;
  superRegion?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLRegionEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['RegionEdge'] = GQLResolversParentTypes['RegionEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Region'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLRegionPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['RegionPaginatedList'] = GQLResolversParentTypes['RegionPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['RegionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLRegionSchemeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['RegionScheme'] = GQLResolversParentTypes['RegionScheme']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  priceScheme?: Resolver<Maybe<GQLResolversTypes['PriceScheme']>, ParentType, ContextType>;
  priceSchemeId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  superRegion?: Resolver<Maybe<GQLResolversTypes['SuperRegion']>, ParentType, ContextType>;
  superRegionId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLRegionSchemeEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['RegionSchemeEdge'] = GQLResolversParentTypes['RegionSchemeEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['RegionScheme'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLRegionSchemePaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['RegionSchemePaginatedList'] = GQLResolversParentTypes['RegionSchemePaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['RegionSchemeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLRegionZonesResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['RegionZones'] = GQLResolversParentTypes['RegionZones']> = ResolversObject<{
  center?: Resolver<GQLResolversTypes['LatLng'], ParentType, ContextType>;
  features?: Resolver<Array<GQLResolversTypes['GeoJsonFeature']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLScheduleDayTimesResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ScheduleDayTimes'] = GQLResolversParentTypes['ScheduleDayTimes']> = ResolversObject<{
  dayOfTheWeek?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  endTime?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLScholarshipApplicationResponseResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['ScholarshipApplicationResponse'] = GQLResolversParentTypes['ScholarshipApplicationResponse']> = ResolversObject<{
  error?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSharedLessonResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SharedLesson'] = GQLResolversParentTypes['SharedLesson']> = ResolversObject<{
  eligibleStudents?: Resolver<Array<GQLResolversTypes['EligibleStudent']>, ParentType, ContextType>;
  lesson?: Resolver<GQLResolversTypes['Lesson'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStateConfigResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StateConfig'] = GQLResolversParentTypes['StateConfig']> = ResolversObject<{
  hasHomePickup?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  hasMeetingPoints?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isShareableByDefault?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  lessonTypeIds?: Resolver<Maybe<Array<GQLResolversTypes['ID']>>, ParentType, ContextType>;
  lessonTypes?: Resolver<Maybe<Array<GQLResolversTypes['LessonType']>>, ParentType, ContextType>;
  longName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStateConfigEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StateConfigEdge'] = GQLResolversParentTypes['StateConfigEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['StateConfig'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStateConfigPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StateConfigPaginatedList'] = GQLResolversParentTypes['StateConfigPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['StateConfigEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStateMeetingPointResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StateMeetingPoint'] = GQLResolversParentTypes['StateMeetingPoint']> = ResolversObject<{
  address?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  coords?: Resolver<Maybe<GQLResolversTypes['LatLng']>, ParentType, ContextType>;
  description?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStateMeetingPointEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StateMeetingPointEdge'] = GQLResolversParentTypes['StateMeetingPointEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['StateMeetingPoint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStateMeetingPointPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StateMeetingPointPaginatedList'] = GQLResolversParentTypes['StateMeetingPointPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['StateMeetingPointEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Student'] = GQLResolversParentTypes['Student']> = ResolversObject<{
  acceptTOS?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  address?: Resolver<GQLResolversTypes['Address'], ParentType, ContextType>;
  age?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  dmvPreRegNumber?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  dob?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  drivingHistory?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isDeleted?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  legalAndPermissions?: Resolver<Maybe<GQLResolversTypes['StudentLegalAndPermissions']>, ParentType, ContextType>;
  licenseExpirationDate?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  licenseIssueDate?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  licenseNumber?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  parentEmail?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  parentName?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  preferredPronoun?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  previousHours?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  previousSchoolLessons?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  productBalance?: Resolver<GQLResolversTypes['ProductBalance'], ParentType, ContextType, RequireFields<GQLStudentProductBalanceArgs, 'productId'>>;
  productBalanceList?: Resolver<Array<GQLResolversTypes['ProductBalance']>, ParentType, ContextType>;
  referral?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  signInEmail?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  signupTimestamp?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  smsOptIn?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  stripeCustomerId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  timeZone?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  usStateId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentBalanceResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentBalance'] = GQLResolversParentTypes['StudentBalance']> = ResolversObject<{
  drivingMinutesPurchased?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  drivingMinutesRemaining?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  drivingMinutesUsed?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  expirationDrivingMinutesUsed?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  homePickupsExpired?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  homePickupsPurchased?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  homePickupsRemaining?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  homePickupsUsed?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  observationMinutesPurchased?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  observationMinutesRemaining?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  observationMinutesUsed?: Resolver<GQLResolversTypes['Float'], ParentType, ContextType>;
  userId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentCourseMigrationResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentCourseMigration'] = GQLResolversParentTypes['StudentCourseMigration']> = ResolversObject<{
  destinationStudentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  onlineCourseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  sourceStudentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentEdge'] = GQLResolversParentTypes['StudentEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Student'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentIdResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentId'] = GQLResolversParentTypes['StudentId']> = ResolversObject<{
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentIdProductIdResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentIdProductId'] = GQLResolversParentTypes['StudentIdProductId']> = ResolversObject<{
  productId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentLegalAndPermissionsResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentLegalAndPermissions'] = GQLResolversParentTypes['StudentLegalAndPermissions']> = ResolversObject<{
  contractStatus?: Resolver<GQLResolversTypes['StudentContractStatus'], ParentType, ContextType>;
  observerApproved?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  privateApproved?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  transferStatus?: Resolver<GQLResolversTypes['StudentTransferStatus'], ParentType, ContextType>;
  transferType?: Resolver<Maybe<GQLResolversTypes['StudentTransferType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentObjectResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentObject'] = GQLResolversParentTypes['StudentObject']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentOnlineCourseResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentOnlineCourse'] = GQLResolversParentTypes['StudentOnlineCourse']> = ResolversObject<{
  courseId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  currentLecture?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  enrolled?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lectures?: Resolver<Maybe<Array<GQLResolversTypes['StudentOnlineCourseLecture']>>, ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  percentComplete?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  percentTestsPassed?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  tests?: Resolver<Maybe<Array<GQLResolversTypes['StudentOnlineCourseTest']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentOnlineCourseEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentOnlineCourseEdge'] = GQLResolversParentTypes['StudentOnlineCourseEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['StudentOnlineCourse'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentOnlineCourseLectureResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentOnlineCourseLecture'] = GQLResolversParentTypes['StudentOnlineCourseLecture']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentOnlineCourseReportResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentOnlineCourseReport'] = GQLResolversParentTypes['StudentOnlineCourseReport']> = ResolversObject<{
  address?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  completionDate?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  courseTotalTimeSeconds?: Resolver<Maybe<GQLResolversTypes['Int']>, ParentType, ContextType>;
  dmvPreRegNumber?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  dob?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  lectureReports?: Resolver<Maybe<Array<GQLResolversTypes['LectureReport']>>, ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentOnlineCourseTestResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentOnlineCourseTest'] = GQLResolversParentTypes['StudentOnlineCourseTest']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  numCorrect?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  totalQuestions?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentOnlineCourseTimeProgressResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentOnlineCourseTimeProgress'] = GQLResolversParentTypes['StudentOnlineCourseTimeProgress']> = ResolversObject<{
  courseTotalTime?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  totalTimePerDay?: Resolver<Maybe<Array<GQLResolversTypes['LectureTimeDaily']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentOnlineCoursesPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentOnlineCoursesPaginatedList'] = GQLResolversParentTypes['StudentOnlineCoursesPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['StudentOnlineCourseEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentSessionAttendanceResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentSessionAttendance'] = GQLResolversParentTypes['StudentSessionAttendance']> = ResolversObject<{
  attendance?: Resolver<GQLResolversTypes['Attendance'], ParentType, ContextType>;
  cohortSessionId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  makeUpCohortId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  makeUpSessionId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  studentId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLStudentsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['StudentsPaginatedList'] = GQLResolversParentTypes['StudentsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['StudentEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSuperRegionResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SuperRegion'] = GQLResolversParentTypes['SuperRegion']> = ResolversObject<{
  googleBusinessPageUrl?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<Maybe<GQLResolversTypes['USStateId']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSuperRegionEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SuperRegionEdge'] = GQLResolversParentTypes['SuperRegionEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['SuperRegion'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSuperRegionPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SuperRegionPaginatedList'] = GQLResolversParentTypes['SuperRegionPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['SuperRegionEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncCheckResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncCheck'] = GQLResolversParentTypes['SyncCheck']> = ResolversObject<{
  checkedStudentsCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  conflicts?: Resolver<GQLResolversTypes['SyncConflictsPaginatedList'], ParentType, ContextType, Partial<GQLSyncCheckConflictsArgs>>;
  conflictsByIndicator?: Resolver<Array<GQLResolversTypes['QuantityByIndicator']>, ParentType, ContextType>;
  conflictsCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  endTime?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  errors?: Resolver<Array<GQLResolversTypes['SyncCheckError']>, ParentType, ContextType>;
  errorsCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  ignoredStudentsCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  isCancelled?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  ledgerStudentsCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  outOfSyncStudentsCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  versionId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncCheckEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncCheckEdge'] = GQLResolversParentTypes['SyncCheckEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['SyncCheck'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncCheckErrorResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncCheckError'] = GQLResolversParentTypes['SyncCheckError']> = ResolversObject<{
  message?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  stack?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncChecksPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncChecksPaginatedList'] = GQLResolversParentTypes['SyncChecksPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['SyncCheckEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncConflictResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncConflict'] = GQLResolversParentTypes['SyncConflict']> = ResolversObject<{
  __resolveType: TypeResolveFn<'SyncStudentExpiredMinutesConflict' | 'SyncStudentFundsBalanceConflict' | 'SyncStudentProductBalanceConflict', ParentType, ContextType>;
  details?: Resolver<GQLResolversTypes['SyncConflictDetails'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lastSyncCheckId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  syncIndicator?: Resolver<GQLResolversTypes['SyncIndicator'], ParentType, ContextType>;
}>;

export type GQLSyncConflictDayBreakdownResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncConflictDayBreakdown'] = GQLResolversParentTypes['SyncConflictDayBreakdown']> = ResolversObject<{
  date?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  hasConflict?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  legacyLogs?: Resolver<Array<GQLResolversTypes['LegacyLogSummary']>, ParentType, ContextType>;
  nextLogs?: Resolver<Array<GQLResolversTypes['LedgerLog']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncConflictDetailsResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncConflictDetails'] = GQLResolversParentTypes['SyncConflictDetails']> = ResolversObject<{
  dayByDayBreakdown?: Resolver<Array<GQLResolversTypes['SyncConflictDayBreakdown']>, ParentType, ContextType>;
  timeZone?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncConflictEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncConflictEdge'] = GQLResolversParentTypes['SyncConflictEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['SyncConflict'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncConflictsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncConflictsPaginatedList'] = GQLResolversParentTypes['SyncConflictsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['SyncConflictEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncStudentExpiredMinutesConflictResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncStudentExpiredMinutesConflict'] = GQLResolversParentTypes['SyncStudentExpiredMinutesConflict']> = ResolversObject<{
  details?: Resolver<GQLResolversTypes['SyncConflictDetails'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lastSyncCheckId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  legacyId?: Resolver<GQLResolversTypes['StudentId'], ParentType, ContextType>;
  legacyValue?: Resolver<GQLResolversTypes['SyncStudentExpiredMinutesEntityValue'], ParentType, ContextType>;
  nextId?: Resolver<GQLResolversTypes['StudentId'], ParentType, ContextType>;
  nextValue?: Resolver<GQLResolversTypes['SyncStudentExpiredMinutesEntityValue'], ParentType, ContextType>;
  syncIndicator?: Resolver<GQLResolversTypes['SyncIndicator'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncStudentExpiredMinutesEntityValueResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncStudentExpiredMinutesEntityValue'] = GQLResolversParentTypes['SyncStudentExpiredMinutesEntityValue']> = ResolversObject<{
  expiredMinutesTotal?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncStudentFundsBalanceConflictResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncStudentFundsBalanceConflict'] = GQLResolversParentTypes['SyncStudentFundsBalanceConflict']> = ResolversObject<{
  details?: Resolver<GQLResolversTypes['SyncConflictDetails'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lastSyncCheckId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  legacyId?: Resolver<GQLResolversTypes['StudentId'], ParentType, ContextType>;
  legacyValue?: Resolver<GQLResolversTypes['SyncStudentFundsBalanceConflictEntityValue'], ParentType, ContextType>;
  nextId?: Resolver<GQLResolversTypes['StudentId'], ParentType, ContextType>;
  nextValue?: Resolver<GQLResolversTypes['SyncStudentFundsBalanceConflictEntityValue'], ParentType, ContextType>;
  syncIndicator?: Resolver<GQLResolversTypes['SyncIndicator'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncStudentFundsBalanceConflictEntityValueResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncStudentFundsBalanceConflictEntityValue'] = GQLResolversParentTypes['SyncStudentFundsBalanceConflictEntityValue']> = ResolversObject<{
  paidCents?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  refundedCents?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  sentCents?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncStudentProductBalanceConflictResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncStudentProductBalanceConflict'] = GQLResolversParentTypes['SyncStudentProductBalanceConflict']> = ResolversObject<{
  details?: Resolver<GQLResolversTypes['SyncConflictDetails'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  lastSyncCheckId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  legacyId?: Resolver<GQLResolversTypes['StudentIdProductId'], ParentType, ContextType>;
  legacyValue?: Resolver<GQLResolversTypes['SyncStudentProductBalanceConflictEntityValue'], ParentType, ContextType>;
  nextId?: Resolver<GQLResolversTypes['StudentIdProductId'], ParentType, ContextType>;
  nextValue?: Resolver<GQLResolversTypes['SyncStudentProductBalanceConflictEntityValue'], ParentType, ContextType>;
  syncIndicator?: Resolver<GQLResolversTypes['SyncIndicator'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLSyncStudentProductBalanceConflictEntityValueResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['SyncStudentProductBalanceConflictEntityValue'] = GQLResolversParentTypes['SyncStudentProductBalanceConflictEntityValue']> = ResolversObject<{
  purchasedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  refundedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  sentQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  usedQuantity?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLTransferAccountCheckResultResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['TransferAccountCheckResult'] = GQLResolversParentTypes['TransferAccountCheckResult']> = ResolversObject<{
  canTransfer?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  errors?: Resolver<Array<GQLResolversTypes['String']>, ParentType, ContextType>;
  fromId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  toId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  warnings?: Resolver<Array<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLTransientSlotResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['TransientSlot'] = GQLResolversParentTypes['TransientSlot']> = ResolversObject<{
  endTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructor?: Resolver<GQLResolversTypes['Instructor'], ParentType, ContextType>;
  instructorId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isBooked?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  isConfirmed?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  isCustom?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  isReserved?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  isShareable?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  isShared?: Resolver<Maybe<GQLResolversTypes['Boolean']>, ParentType, ContextType>;
  lessonType?: Resolver<GQLResolversTypes['LessonType'], ParentType, ContextType>;
  lessonTypeId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  locationId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  packagePrices?: Resolver<Maybe<Array<GQLResolversTypes['PackagePrice']>>, ParentType, ContextType>;
  pickupAddress?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  reservedBy?: Resolver<Maybe<GQLResolversTypes['Person']>, ParentType, ContextType>;
  reservedFor?: Resolver<Maybe<GQLResolversTypes['Person']>, ParentType, ContextType>;
  searchAddress?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  searchCity?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  searchRegion?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLUsStateResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['USState'] = GQLResolversParentTypes['USState']> = ResolversObject<{
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLUpdatedByResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['UpdatedBy'] = GQLResolversParentTypes['UpdatedBy']> = ResolversObject<{
  id?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  role?: Resolver<GQLResolversTypes['AuditLogUserRoles'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLUserLogResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['UserLog'] = GQLResolversParentTypes['UserLog']> = ResolversObject<{
  action?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  changedBy?: Resolver<Maybe<GQLResolversTypes['Person']>, ParentType, ContextType>;
  changedById?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  changes?: Resolver<Array<GQLResolversTypes['LogChange']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  initiatedBy?: Resolver<Maybe<GQLResolversTypes['InitiatedBy']>, ParentType, ContextType>;
  instructor?: Resolver<Maybe<GQLResolversTypes['Instructor']>, ParentType, ContextType>;
  instructorId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  lessonStartTime?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  note?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<GQLResolversTypes['DateTime']>, ParentType, ContextType>;
  type?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<GQLResolversTypes['Person']>, ParentType, ContextType>;
  userId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLUserLogEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['UserLogEdge'] = GQLResolversParentTypes['UserLogEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['UserLog'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLUserLogsPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['UserLogsPaginatedList'] = GQLResolversParentTypes['UserLogsPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['UserLogEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVehicleResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Vehicle'] = GQLResolversParentTypes['Vehicle']> = ResolversObject<{
  color?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructorIds?: Resolver<Maybe<Array<GQLResolversTypes['ID']>>, ParentType, ContextType>;
  instructors?: Resolver<Maybe<Array<GQLResolversTypes['Instructor']>>, ParentType, ContextType>;
  make?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  model?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  opsManagerUserId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  parkingHub?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  plate?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  samsaraId?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  usStateId?: Resolver<Maybe<GQLResolversTypes['USStateId']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVehicleAvailResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['VehicleAvail'] = GQLResolversParentTypes['VehicleAvail']> = ResolversObject<{
  availId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  date?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  endTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  instructor?: Resolver<GQLResolversTypes['VehicleInstructorFields'], ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  vehicleId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  vehicleName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVehicleAvailabilityResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['VehicleAvailability'] = GQLResolversParentTypes['VehicleAvailability']> = ResolversObject<{
  endTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  instructorName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  vehicleId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  vehicleName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVehicleEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['VehicleEdge'] = GQLResolversParentTypes['VehicleEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Vehicle'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVehicleInstructorFieldsResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['VehicleInstructorFields'] = GQLResolversParentTypes['VehicleInstructorFields']> = ResolversObject<{
  displayName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  timeZone?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVehicleMapAvailabilityResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['VehicleMapAvailability'] = GQLResolversParentTypes['VehicleMapAvailability']> = ResolversObject<{
  availId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  endTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  instructor?: Resolver<GQLResolversTypes['InstructorOnAvail'], ParentType, ContextType>;
  startTime?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  vehicleId?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  vehicleName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVehiclesPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['VehiclesPaginatedList'] = GQLResolversParentTypes['VehiclesPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['VehicleEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVoucherResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['Voucher'] = GQLResolversParentTypes['Voucher']> = ResolversObject<{
  amountDollars?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  amountHours?: Resolver<Maybe<GQLResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  isRedeemed?: Resolver<GQLResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  timestamp?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  zipcode?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVoucherEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['VoucherEdge'] = GQLResolversParentTypes['VoucherEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['Voucher'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVoucherSourceResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['VoucherSource'] = GQLResolversParentTypes['VoucherSource']> = ResolversObject<{
  key?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLVouchersPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['VouchersPaginatedList'] = GQLResolversParentTypes['VouchersPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['VoucherEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLWaitlistCommentResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['WaitlistComment'] = GQLResolversParentTypes['WaitlistComment']> = ResolversObject<{
  author?: Resolver<GQLResolversTypes['Person'], ParentType, ContextType>;
  createdAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  text?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLWaitlistEdgeResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['WaitlistEdge'] = GQLResolversParentTypes['WaitlistEdge']> = ResolversObject<{
  cursor?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  node?: Resolver<GQLResolversTypes['WaitlistStudent'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLWaitlistPaginatedListResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['WaitlistPaginatedList'] = GQLResolversParentTypes['WaitlistPaginatedList']> = ResolversObject<{
  edges?: Resolver<Array<GQLResolversTypes['WaitlistEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<GQLResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLWaitlistStudentResolvers<ContextType = any, ParentType extends GQLResolversParentTypes['WaitlistStudent'] = GQLResolversParentTypes['WaitlistStudent']> = ResolversObject<{
  availability?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  bookingLocation?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  comments?: Resolver<Array<GQLResolversTypes['WaitlistComment']>, ParentType, ContextType>;
  commentsCount?: Resolver<GQLResolversTypes['Int'], ParentType, ContextType>;
  createdAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  expiresAt?: Resolver<GQLResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<GQLResolversTypes['ID'], ParentType, ContextType>;
  phone?: Resolver<Maybe<GQLResolversTypes['String']>, ParentType, ContextType>;
  source?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  studentEmail?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  studentId?: Resolver<Maybe<GQLResolversTypes['ID']>, ParentType, ContextType>;
  studentName?: Resolver<GQLResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type GQLResolvers<ContextType = any> = ResolversObject<{
  AddedPurchaseItem?: GQLAddedPurchaseItemResolvers<ContextType>;
  AddedPurchasePackage?: GQLAddedPurchasePackageResolvers<ContextType>;
  AddedPurchaseProduct?: GQLAddedPurchaseProductResolvers<ContextType>;
  Address?: GQLAddressResolvers<ContextType>;
  AnvilPdfIds?: GQLAnvilPdfIdsResolvers<ContextType>;
  AuditLog?: GQLAuditLogResolvers<ContextType>;
  AuditLogCollection?: GQLAuditLogCollectionResolvers<ContextType>;
  AuditLogEdge?: GQLAuditLogEdgeResolvers<ContextType>;
  AuditLogsPaginatedList?: GQLAuditLogsPaginatedListResolvers<ContextType>;
  Availability?: GQLAvailabilityResolvers<ContextType>;
  AvailabilityAddFailure?: GQLAvailabilityAddFailureResolvers<ContextType>;
  AvailabilitySlot?: GQLAvailabilitySlotResolvers<ContextType>;
  AvailabilitySlotPerDay?: GQLAvailabilitySlotPerDayResolvers<ContextType>;
  AvailabilityUpdateFailure?: GQLAvailabilityUpdateFailureResolvers<ContextType>;
  BalancedQuantity?: GQLBalancedQuantityResolvers<ContextType>;
  BaseLedgerLog?: GQLBaseLedgerLogResolvers<ContextType>;
  BatchAddAvailsResult?: GQLBatchAddAvailsResultResolvers<ContextType>;
  BatchDeleteAvailsResult?: GQLBatchDeleteAvailsResultResolvers<ContextType>;
  BatchEditAvailsResult?: GQLBatchEditAvailsResultResolvers<ContextType>;
  BookingAlert?: GQLBookingAlertResolvers<ContextType>;
  BookingAlertEdge?: GQLBookingAlertEdgeResolvers<ContextType>;
  BookingAlertsPaginatedList?: GQLBookingAlertsPaginatedListResolvers<ContextType>;
  Cart?: GQLCartResolvers<ContextType>;
  Center?: GQLCenterResolvers<ContextType>;
  Certificate?: GQLCertificateResolvers<ContextType>;
  CertificateEdge?: GQLCertificateEdgeResolvers<ContextType>;
  CertificateType?: GQLCertificateTypeResolvers<ContextType>;
  CertificatesPaginatedList?: GQLCertificatesPaginatedListResolvers<ContextType>;
  ChargeLedgerLog?: GQLChargeLedgerLogResolvers<ContextType>;
  Cohort?: GQLCohortResolvers<ContextType>;
  CohortSession?: GQLCohortSessionResolvers<ContextType>;
  Coupon?: GQLCouponResolvers<ContextType>;
  CouponEdge?: GQLCouponEdgeResolvers<ContextType>;
  CouponsPaginatedList?: GQLCouponsPaginatedListResolvers<ContextType>;
  CourseCoupon?: GQLCourseCouponResolvers<ContextType>;
  CourseLecture?: GQLCourseLectureResolvers<ContextType>;
  CourseLectureContent?: GQLCourseLectureContentResolvers<ContextType>;
  CoursePrepTest?: GQLCoursePrepTestResolvers<ContextType>;
  CoursePrepTestChapter?: GQLCoursePrepTestChapterResolvers<ContextType>;
  CoursePrepTestChapterScores?: GQLCoursePrepTestChapterScoresResolvers<ContextType>;
  CourseTest?: GQLCourseTestResolvers<ContextType>;
  CourseTestLecture?: GQLCourseTestLectureResolvers<ContextType>;
  CourseTopic?: GQLCourseTopicResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DrivingSkill?: GQLDrivingSkillResolvers<ContextType>;
  EligibleStudent?: GQLEligibleStudentResolvers<ContextType>;
  ExistingPurchaseItemUpdate?: GQLExistingPurchaseItemUpdateResolvers<ContextType>;
  Expenditure?: GQLExpenditureResolvers<ContextType>;
  Expiration?: GQLExpirationResolvers<ContextType>;
  FirebaseCourseSessionTime?: GQLFirebaseCourseSessionTimeResolvers<ContextType>;
  FirebaseLessonReview?: GQLFirebaseLessonReviewResolvers<ContextType>;
  GeoJsonFeature?: GQLGeoJsonFeatureResolvers<ContextType>;
  GeoJsonGeometry?: GQLGeoJsonGeometryResolvers<ContextType>;
  GeoJsonProperties?: GQLGeoJsonPropertiesResolvers<ContextType>;
  IdToken?: GQLIdTokenResolvers<ContextType>;
  ImpersonateUserResponse?: GQLImpersonateUserResponseResolvers<ContextType>;
  Instructor?: GQLInstructorResolvers<ContextType>;
  InstructorAdminInfo?: GQLInstructorAdminInfoResolvers<ContextType>;
  InstructorAlert?: GQLInstructorAlertResolvers<ContextType>;
  InstructorAlertEdge?: GQLInstructorAlertEdgeResolvers<ContextType>;
  InstructorAlertPaginatedList?: GQLInstructorAlertPaginatedListResolvers<ContextType>;
  InstructorBadge?: GQLInstructorBadgeResolvers<ContextType>;
  InstructorEdge?: GQLInstructorEdgeResolvers<ContextType>;
  InstructorOnAvail?: GQLInstructorOnAvailResolvers<ContextType>;
  InstructorPrivateInfo?: GQLInstructorPrivateInfoResolvers<ContextType>;
  InstructorSchedule?: GQLInstructorScheduleResolvers<ContextType>;
  InstructorTimeLog?: GQLInstructorTimeLogResolvers<ContextType>;
  InstructorTimeLogEdge?: GQLInstructorTimeLogEdgeResolvers<ContextType>;
  InstructorTimeLogPaginatedList?: GQLInstructorTimeLogPaginatedListResolvers<ContextType>;
  InstructorWithSuperRegion?: GQLInstructorWithSuperRegionResolvers<ContextType>;
  InstructorZone?: GQLInstructorZoneResolvers<ContextType>;
  InstructorsCoverage?: GQLInstructorsCoverageResolvers<ContextType>;
  InstructorsPaginatedList?: GQLInstructorsPaginatedListResolvers<ContextType>;
  ItemToExpire?: GQLItemToExpireResolvers<ContextType>;
  ItemToRefund?: GQLItemToRefundResolvers<ContextType>;
  ItemToTransfer?: GQLItemToTransferResolvers<ContextType>;
  ItemToTransferAndExpire?: GQLItemToTransferAndExpireResolvers<ContextType>;
  JSONObject?: GraphQLScalarType;
  LastBookedInstructor?: GQLLastBookedInstructorResolvers<ContextType>;
  LatLng?: GQLLatLngResolvers<ContextType>;
  LectureBlockColumn?: GQLLectureBlockColumnResolvers<ContextType>;
  LectureBlockTextColumn?: GQLLectureBlockTextColumnResolvers<ContextType>;
  LectureColumnImage?: GQLLectureColumnImageResolvers<ContextType>;
  LectureContentBlock?: GQLLectureContentBlockResolvers<ContextType>;
  LectureContentImage?: GQLLectureContentImageResolvers<ContextType>;
  LectureContentPart?: GQLLectureContentPartResolvers<ContextType>;
  LectureContentVideo?: GQLLectureContentVideoResolvers<ContextType>;
  LecturePart?: GQLLecturePartResolvers<ContextType>;
  LectureQuestionChoice?: GQLLectureQuestionChoiceResolvers<ContextType>;
  LectureQuizPart?: GQLLectureQuizPartResolvers<ContextType>;
  LectureQuizQuestion?: GQLLectureQuizQuestionResolvers<ContextType>;
  LectureQuizQuestionPrepTestScore?: GQLLectureQuizQuestionPrepTestScoreResolvers<ContextType>;
  LectureReport?: GQLLectureReportResolvers<ContextType>;
  LectureSessionTime?: GQLLectureSessionTimeResolvers<ContextType>;
  LectureSingleImageBlock?: GQLLectureSingleImageBlockResolvers<ContextType>;
  LectureSingleVideoBlock?: GQLLectureSingleVideoBlockResolvers<ContextType>;
  LectureTimeDaily?: GQLLectureTimeDailyResolvers<ContextType>;
  LectureTitleBlock?: GQLLectureTitleBlockResolvers<ContextType>;
  LectureTwoColumnBlock?: GQLLectureTwoColumnBlockResolvers<ContextType>;
  LedgerLog?: GQLLedgerLogResolvers<ContextType>;
  LedgerLogCancelLesson?: GQLLedgerLogCancelLessonResolvers<ContextType>;
  LedgerLogCharge?: GQLLedgerLogChargeResolvers<ContextType>;
  LedgerLogEditPurchase?: GQLLedgerLogEditPurchaseResolvers<ContextType>;
  LedgerLogExpireProducts?: GQLLedgerLogExpireProductsResolvers<ContextType>;
  LedgerLogRefund?: GQLLedgerLogRefundResolvers<ContextType>;
  LedgerLogRemoveStudentFromLesson?: GQLLedgerLogRemoveStudentFromLessonResolvers<ContextType>;
  LedgerLogScheduleLesson?: GQLLedgerLogScheduleLessonResolvers<ContextType>;
  LedgerLogScheduleMultipleLessons?: GQLLedgerLogScheduleMultipleLessonsResolvers<ContextType>;
  LedgerLogTransferExpiration?: GQLLedgerLogTransferExpirationResolvers<ContextType>;
  LedgerLogTransferLesson?: GQLLedgerLogTransferLessonResolvers<ContextType>;
  LedgerLogTransferPurchase?: GQLLedgerLogTransferPurchaseResolvers<ContextType>;
  LedgerLogUpdateLesson?: GQLLedgerLogUpdateLessonResolvers<ContextType>;
  LedgerLogVoidExpiration?: GQLLedgerLogVoidExpirationResolvers<ContextType>;
  LedgerLogVoidPurchase?: GQLLedgerLogVoidPurchaseResolvers<ContextType>;
  LedgerLogVoidRefund?: GQLLedgerLogVoidRefundResolvers<ContextType>;
  LedgerLogsEdge?: GQLLedgerLogsEdgeResolvers<ContextType>;
  LedgerLogsPaginatedList?: GQLLedgerLogsPaginatedListResolvers<ContextType>;
  LegacyLogSummary?: GQLLegacyLogSummaryResolvers<ContextType>;
  Lesson?: GQLLessonResolvers<ContextType>;
  LessonCoupon?: GQLLessonCouponResolvers<ContextType>;
  LessonDrivingSkillRating?: GQLLessonDrivingSkillRatingResolvers<ContextType>;
  LessonDrivingSkillsReport?: GQLLessonDrivingSkillsReportResolvers<ContextType>;
  LessonDrivingSkillsReportEdge?: GQLLessonDrivingSkillsReportEdgeResolvers<ContextType>;
  LessonDrivingSkillsReportPaginatedList?: GQLLessonDrivingSkillsReportPaginatedListResolvers<ContextType>;
  LessonEdge?: GQLLessonEdgeResolvers<ContextType>;
  LessonPackage?: GQLLessonPackageResolvers<ContextType>;
  LessonReview?: GQLLessonReviewResolvers<ContextType>;
  LessonReviewEdge?: GQLLessonReviewEdgeResolvers<ContextType>;
  LessonStudentDetails?: GQLLessonStudentDetailsResolvers<ContextType>;
  LessonType?: GQLLessonTypeResolvers<ContextType>;
  LessonTypeEdge?: GQLLessonTypeEdgeResolvers<ContextType>;
  LessonTypeFeature?: GQLLessonTypeFeatureResolvers<ContextType>;
  LessonTypesPaginatedList?: GQLLessonTypesPaginatedListResolvers<ContextType>;
  LessonsPaginatedList?: GQLLessonsPaginatedListResolvers<ContextType>;
  LessonsReviewsPaginatedList?: GQLLessonsReviewsPaginatedListResolvers<ContextType>;
  Location?: GQLLocationResolvers<ContextType>;
  LogChange?: GQLLogChangeResolvers<ContextType>;
  LogChangeBase?: GQLLogChangeBaseResolvers<ContextType>;
  LogDateTimeChange?: GQLLogDateTimeChangeResolvers<ContextType>;
  LogFloatChange?: GQLLogFloatChangeResolvers<ContextType>;
  LogIntChange?: GQLLogIntChangeResolvers<ContextType>;
  LogStringChange?: GQLLogStringChangeResolvers<ContextType>;
  Mutation?: GQLMutationResolvers<ContextType>;
  Myself?: GQLMyselfResolvers<ContextType>;
  Note?: GQLNoteResolvers<ContextType>;
  NoteEdge?: GQLNoteEdgeResolvers<ContextType>;
  NotesPaginatedList?: GQLNotesPaginatedListResolvers<ContextType>;
  OnlineCourseContent?: GQLOnlineCourseContentResolvers<ContextType>;
  PackagePrice?: GQLPackagePriceResolvers<ContextType>;
  PackageWithPrices?: GQLPackageWithPricesResolvers<ContextType>;
  PageInfo?: GQLPageInfoResolvers<ContextType>;
  Person?: GQLPersonResolvers<ContextType>;
  PersonEdge?: GQLPersonEdgeResolvers<ContextType>;
  PersonPaginatedList?: GQLPersonPaginatedListResolvers<ContextType>;
  PriceScheme?: GQLPriceSchemeResolvers<ContextType>;
  PriceSchemeEdge?: GQLPriceSchemeEdgeResolvers<ContextType>;
  PriceSchemeFragment?: GQLPriceSchemeFragmentResolvers<ContextType>;
  PriceSchemeFragmentEdge?: GQLPriceSchemeFragmentEdgeResolvers<ContextType>;
  PriceSchemeFragmentPaginatedList?: GQLPriceSchemeFragmentPaginatedListResolvers<ContextType>;
  PriceSchemeInfo?: GQLPriceSchemeInfoResolvers<ContextType>;
  PriceSchemePaginatedList?: GQLPriceSchemePaginatedListResolvers<ContextType>;
  PriceSchemeProductLink?: GQLPriceSchemeProductLinkResolvers<ContextType>;
  Product?: GQLProductResolvers<ContextType>;
  ProductBalance?: GQLProductBalanceResolvers<ContextType>;
  ProductEdge?: GQLProductEdgeResolvers<ContextType>;
  ProductLink?: GQLProductLinkResolvers<ContextType>;
  ProductPrice?: GQLProductPriceResolvers<ContextType>;
  ProductPriceAlgo?: GQLProductPriceAlgoResolvers<ContextType>;
  ProductPriceAlgoBase?: GQLProductPriceAlgoBaseResolvers<ContextType>;
  ProductPriceAlgoDollarDiscount?: GQLProductPriceAlgoDollarDiscountResolvers<ContextType>;
  ProductPriceAlgoDollarThenPercentDiscount?: GQLProductPriceAlgoDollarThenPercentDiscountResolvers<ContextType>;
  ProductPriceAlgoFixed?: GQLProductPriceAlgoFixedResolvers<ContextType>;
  ProductPriceAlgoPercentDiscount?: GQLProductPriceAlgoPercentDiscountResolvers<ContextType>;
  ProductPriceAlgoPercentThenDollarDiscount?: GQLProductPriceAlgoPercentThenDollarDiscountResolvers<ContextType>;
  ProductPriceRounding?: GQLProductPriceRoundingResolvers<ContextType>;
  ProductQuantity?: GQLProductQuantityResolvers<ContextType>;
  ProductsPaginatedList?: GQLProductsPaginatedListResolvers<ContextType>;
  Purchase?: GQLPurchaseResolvers<ContextType>;
  PurchaseItem?: GQLPurchaseItemResolvers<ContextType>;
  PurchaseItemUpdate?: GQLPurchaseItemUpdateResolvers<ContextType>;
  PurchasePackageItem?: GQLPurchasePackageItemResolvers<ContextType>;
  PurchaseProductItem?: GQLPurchaseProductItemResolvers<ContextType>;
  QuantityByIndicator?: GQLQuantityByIndicatorResolvers<ContextType>;
  Query?: GQLQueryResolvers<ContextType>;
  RedeemVoucherResult?: GQLRedeemVoucherResultResolvers<ContextType>;
  ReferenceItem?: GQLReferenceItemResolvers<ContextType>;
  Refund?: GQLRefundResolvers<ContextType>;
  Region?: GQLRegionResolvers<ContextType>;
  RegionEdge?: GQLRegionEdgeResolvers<ContextType>;
  RegionPaginatedList?: GQLRegionPaginatedListResolvers<ContextType>;
  RegionScheme?: GQLRegionSchemeResolvers<ContextType>;
  RegionSchemeEdge?: GQLRegionSchemeEdgeResolvers<ContextType>;
  RegionSchemePaginatedList?: GQLRegionSchemePaginatedListResolvers<ContextType>;
  RegionZones?: GQLRegionZonesResolvers<ContextType>;
  ScheduleDayTimes?: GQLScheduleDayTimesResolvers<ContextType>;
  ScholarshipApplicationResponse?: GQLScholarshipApplicationResponseResolvers<ContextType>;
  SharedLesson?: GQLSharedLessonResolvers<ContextType>;
  StateConfig?: GQLStateConfigResolvers<ContextType>;
  StateConfigEdge?: GQLStateConfigEdgeResolvers<ContextType>;
  StateConfigPaginatedList?: GQLStateConfigPaginatedListResolvers<ContextType>;
  StateMeetingPoint?: GQLStateMeetingPointResolvers<ContextType>;
  StateMeetingPointEdge?: GQLStateMeetingPointEdgeResolvers<ContextType>;
  StateMeetingPointPaginatedList?: GQLStateMeetingPointPaginatedListResolvers<ContextType>;
  Student?: GQLStudentResolvers<ContextType>;
  StudentBalance?: GQLStudentBalanceResolvers<ContextType>;
  StudentCourseMigration?: GQLStudentCourseMigrationResolvers<ContextType>;
  StudentEdge?: GQLStudentEdgeResolvers<ContextType>;
  StudentId?: GQLStudentIdResolvers<ContextType>;
  StudentIdProductId?: GQLStudentIdProductIdResolvers<ContextType>;
  StudentLegalAndPermissions?: GQLStudentLegalAndPermissionsResolvers<ContextType>;
  StudentObject?: GQLStudentObjectResolvers<ContextType>;
  StudentOnlineCourse?: GQLStudentOnlineCourseResolvers<ContextType>;
  StudentOnlineCourseEdge?: GQLStudentOnlineCourseEdgeResolvers<ContextType>;
  StudentOnlineCourseLecture?: GQLStudentOnlineCourseLectureResolvers<ContextType>;
  StudentOnlineCourseReport?: GQLStudentOnlineCourseReportResolvers<ContextType>;
  StudentOnlineCourseTest?: GQLStudentOnlineCourseTestResolvers<ContextType>;
  StudentOnlineCourseTimeProgress?: GQLStudentOnlineCourseTimeProgressResolvers<ContextType>;
  StudentOnlineCoursesPaginatedList?: GQLStudentOnlineCoursesPaginatedListResolvers<ContextType>;
  StudentSessionAttendance?: GQLStudentSessionAttendanceResolvers<ContextType>;
  StudentsPaginatedList?: GQLStudentsPaginatedListResolvers<ContextType>;
  SuperRegion?: GQLSuperRegionResolvers<ContextType>;
  SuperRegionEdge?: GQLSuperRegionEdgeResolvers<ContextType>;
  SuperRegionPaginatedList?: GQLSuperRegionPaginatedListResolvers<ContextType>;
  SyncCheck?: GQLSyncCheckResolvers<ContextType>;
  SyncCheckEdge?: GQLSyncCheckEdgeResolvers<ContextType>;
  SyncCheckError?: GQLSyncCheckErrorResolvers<ContextType>;
  SyncChecksPaginatedList?: GQLSyncChecksPaginatedListResolvers<ContextType>;
  SyncConflict?: GQLSyncConflictResolvers<ContextType>;
  SyncConflictDayBreakdown?: GQLSyncConflictDayBreakdownResolvers<ContextType>;
  SyncConflictDetails?: GQLSyncConflictDetailsResolvers<ContextType>;
  SyncConflictEdge?: GQLSyncConflictEdgeResolvers<ContextType>;
  SyncConflictsPaginatedList?: GQLSyncConflictsPaginatedListResolvers<ContextType>;
  SyncStudentExpiredMinutesConflict?: GQLSyncStudentExpiredMinutesConflictResolvers<ContextType>;
  SyncStudentExpiredMinutesEntityValue?: GQLSyncStudentExpiredMinutesEntityValueResolvers<ContextType>;
  SyncStudentFundsBalanceConflict?: GQLSyncStudentFundsBalanceConflictResolvers<ContextType>;
  SyncStudentFundsBalanceConflictEntityValue?: GQLSyncStudentFundsBalanceConflictEntityValueResolvers<ContextType>;
  SyncStudentProductBalanceConflict?: GQLSyncStudentProductBalanceConflictResolvers<ContextType>;
  SyncStudentProductBalanceConflictEntityValue?: GQLSyncStudentProductBalanceConflictEntityValueResolvers<ContextType>;
  TransferAccountCheckResult?: GQLTransferAccountCheckResultResolvers<ContextType>;
  TransientSlot?: GQLTransientSlotResolvers<ContextType>;
  USState?: GQLUsStateResolvers<ContextType>;
  UpdatedBy?: GQLUpdatedByResolvers<ContextType>;
  UserLog?: GQLUserLogResolvers<ContextType>;
  UserLogEdge?: GQLUserLogEdgeResolvers<ContextType>;
  UserLogsPaginatedList?: GQLUserLogsPaginatedListResolvers<ContextType>;
  Vehicle?: GQLVehicleResolvers<ContextType>;
  VehicleAvail?: GQLVehicleAvailResolvers<ContextType>;
  VehicleAvailability?: GQLVehicleAvailabilityResolvers<ContextType>;
  VehicleEdge?: GQLVehicleEdgeResolvers<ContextType>;
  VehicleInstructorFields?: GQLVehicleInstructorFieldsResolvers<ContextType>;
  VehicleMapAvailability?: GQLVehicleMapAvailabilityResolvers<ContextType>;
  VehiclesPaginatedList?: GQLVehiclesPaginatedListResolvers<ContextType>;
  Voucher?: GQLVoucherResolvers<ContextType>;
  VoucherEdge?: GQLVoucherEdgeResolvers<ContextType>;
  VoucherSource?: GQLVoucherSourceResolvers<ContextType>;
  VouchersPaginatedList?: GQLVouchersPaginatedListResolvers<ContextType>;
  WaitlistComment?: GQLWaitlistCommentResolvers<ContextType>;
  WaitlistEdge?: GQLWaitlistEdgeResolvers<ContextType>;
  WaitlistPaginatedList?: GQLWaitlistPaginatedListResolvers<ContextType>;
  WaitlistStudent?: GQLWaitlistStudentResolvers<ContextType>;
}>;

